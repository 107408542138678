import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import * as validate from "@ownportal/customer-validations";
import moment from "moment";
import FormField from "../common/form-field/formField";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import Logger from "../../../logger";
import {
  handleOnChangeDay,
  handleOnChangeMonth,
  handleOnKeyPressYear,
  handleSpecialKeysDate,
  maskSSN,
  restrictKeyPressHandler,
  isValidEmail,
  isValidCanadaEmail,
} from "../../../utilities";
import styles from "./verifyCustomer.module.scss";
import BannerNotification from "../common/banner/bannerNotification";
import { SessionContext } from "../../../context/sessionContext";
import Link from "../common/link/link";
import verifyCustomerRegistration from "../../services/verifyCustomerRegistration";

interface AccountInfo {
  email: string;
  ssn: string;
  accountNumber: string;
  partyId: number;
}

interface VerifyCustomerProps {
  setAccountInfo?: (value: AccountInfo) => void;
  setAlreadyRegistered?: (value: boolean) => void;
  setIsContinueEnabled?: (value: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const VerifyCustomer = forwardRef<any, VerifyCustomerProps>(
  (
    {
      setAccountInfo = () => {},
      setAlreadyRegistered = () => {},
      setIsContinueEnabled = () => {},
    },
    ref,
  ) => {
    const { sessionDetails } = useContext(SessionContext);
    const { register, errors, clearErrors, trigger } = useForm({});
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [ssn, setSsn] = useState("");
    const [maskedSSN, setMaskedSSn] = useState("");
    const [accountNumber, setAccNumber] = useState("");
    const [vin, setVin] = useState("");
    const [apiError, setApiError] = useState(false);
    const [fieldError, setError] = useState("");
    const [deleteSSN, setDeleteSSN] = useState(false);
    const isAudi = sessionDetails?.brand?.toUpperCase() === "AUDI";
    const isCanada = sessionDetails?.country?.toUpperCase() === "CANADA";
    const isVW = sessionDetails?.brand?.toUpperCase() === "VW";
    const isVF = isCanada && isVW;
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [year, setYear] = useState("");
    const [errorDateOfBirth, setErrorDateOfBirth] = useState("");
    const [noMatchError, setNoMatchError] = useState(false);
    const [shouldGoToNext, setShouldGoToNext] = useState({
      " day ": false,
      " month ": false,
      " year ": false,
    });
    const navigate = useNavigate();
    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              `Exited Registration Flow in Step 2 by clicking back button on the browser tab`,
              "Location ==> /register",
              sessionDetails,
            );
          }
        },
        false,
      );

      window.addEventListener("beforeunload", () => {
        Logger.info(
          "Exited Registration Flow in Step 2 by closing the browser tab",
          "Location ==> /register",
          sessionDetails,
        );
      });

      // When the user change the language, triggers the existing error.
      for (const error in errors) {
        trigger(error);
      }

      if (errorDateOfBirth) {
        isValidDateOfBirth();
      }
    }, [sessionDetails]);

    useEffect(() => {
      const isEmptyErrors = isEmpty(errors);
      if (isCanada) {
        setIsContinueEnabled(
          email &&
            confirmEmail &&
            vin &&
            accountNumber &&
            day &&
            month &&
            year &&
            isEmptyErrors &&
            !noMatchError &&
            errorDateOfBirth === "",
        );
      } else {
        setIsContinueEnabled(
          email &&
            confirmEmail &&
            ssn &&
            accountNumber &&
            isEmptyErrors &&
            !noMatchError,
        );
      }
    }, [
      email,
      confirmEmail,
      ssn,
      vin,
      accountNumber,
      setIsContinueEnabled,
      isCanada,
      isVW,
      day,
      month,
      year,
      errorDateOfBirth,
      errors,
      noMatchError,
    ]);

    async function onSubmit() {
      setNoMatchError(false);
      const dob = `${month}/${day}/${year}`;
      const body = {
        ssn,
        accountNumber,
        dob,
        vin,
        sessionDetails,
      };

      const { data, error } = await verifyCustomerRegistration(body);
      if (data?.inMaintenance) {
        navigate("/login");
      }
      if (data?.isSuccess) {
        if (data?.partyId) {
          const partyId = data?.partyId;
          if (data?.isPartyIdRegistered) {
            Logger.info(
              "Verify Customer Registration - PartyId Registered",
              "Location ==> /register",
              sessionDetails,
              accountNumber,
              partyId,
            );
            setAlreadyRegistered(true);
            return false;
          }
          Logger.info(
            "Verify Customer Registration - PartyId Not Registered",
            "Location ==> /register",
            sessionDetails,
            accountNumber,
            partyId,
          );
          setAccountInfo({ email, ssn, accountNumber, partyId });
          return true;
        }
        Logger.info(
          "Verify Customer Registration - Customer Not Found",
          "Location ==> /register",
          sessionDetails,
          accountNumber,
        );
        setError(i18n.t(rk.ERROR_INVALID_INFO));
        setNoMatchError(true);
        return false;
      }
      if (data?.errors) {
        setApiError(true);
        Logger.error(
          "VerifyCustomerRegistration API Error",
          "Location ==> /register",
          data?.errors,
          sessionDetails,
          accountNumber,
        );
      }
      if (error || data?.error) {
        setApiError(true);
        Logger.error(
          "VerifyCustomerRegistration API Error",
          "Location ==> /register",
          error || data?.error || "",
          sessionDetails,
          accountNumber,
        );
        return false;
      }
      return false;
    }
    const isValidDateOfBirth = () => {
      setErrorDateOfBirth("");
      if (!day || !month || !year) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_EMPTY_DOB));
        setIsContinueEnabled(false);

        return;
      }

      if (day.length !== 2 || month.length !== 2 || year.length !== 4) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
        setIsContinueEnabled(false);
        Logger.info(
          `User entered invalid DoB ==> ${month}/${day}/${year}`,
          "Location ==> /register",
          sessionDetails,
          "",
          null,
          "",
        );

        return;
      }

      if (
        new Date(`${month}/${day}/${year}`) > new Date() ||
        !moment(`${year}-${month}-${day}`, "YYYY-MM-DD").isValid()
      ) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
        setIsContinueEnabled(false);
        Logger.info(
          `User entered invalid DoB ==> ${month}/${day}/${year}`,
          "Location ==> /register",
          sessionDetails,
          "",
          null,
          "",
        );
      }
    };
    const isValidDate = () => {
      const date = `${month}/${day}/${year}`;
      const parsedDate = new Date(date);
      return (
        parsedDate <= new Date() &&
        parsedDate.getMonth() === parseInt(month, 10) - 1
      );
    };

    async function verifyUserAction() {
      if (isCanada) {
        if (!day || !month || !year) {
          setErrorDateOfBirth(i18n.t(rk.ERROR_EMPTY_DOB));
          setIsContinueEnabled(false);
          return false;
        }
        if (!isValidDate()) {
          setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
          setIsContinueEnabled(false);
          return false;
        }
      }
      setApiError(false);
      setError("");
      // Trigger input validations before submit call
      await trigger();
      if (!isEmpty(errors)) {
        setIsContinueEnabled(false);
        return false;
      }
      const isSuccess = await onSubmit();

      return isSuccess;
    }

    useImperativeHandle(ref, () => ({ verifyUserAction }));

    const restrictEmailKeyPress = restrictKeyPressHandler(/^[^ ]*$/, "");

    const handleSSNChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      const newValueFormat = newValue.split("-").join("");
      const lastChar = newValueFormat[newValueFormat.length - 1];

      if (!deleteSSN) {
        if (newValueFormat.length === 1 && !/[0-9]/.test(newValueFormat)) {
          return;
        }
        if (newValueFormat.length > 1 && !/[0-9]/.test(lastChar)) {
          return;
        }
      }

      const ssnValue = maskSSN(newValue, ssn, maskedSSN);
      if (ssnValue) {
        setSsn(ssnValue.actualValue);
        setMaskedSSn(ssnValue.maskedValue);
      }
    };

    const restrictAccountKeyPress = restrictKeyPressHandler(/^[0-9]*$/i, "");
    const restrictVINKeyPress = restrictKeyPressHandler(/^[0-9]*$/i, 6);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace" || e.key === "Delete") {
        setDeleteSSN(true);
        return;
      }
      setDeleteSSN(false);
    };

    return (
      <div
        className={
          styles[isAudi ? "audi-acct-info-container" : "acct-info-container"]
        }
      >
        {isAudi && (
          <div>
            <p className={styles["audi-login-link"]}>
              <span className={styles["audi-login-link-text"]}>
                {i18n.t(rk.TEXT_ALREADY_HAVE_AN_AUDI_ACCOUNT)}
              </span>
              <Link
                ariaLabel="Login link"
                value={i18n.t(rk.TITLE_LOGIN)}
                icon="c-icon--[semantic-forward]"
                onClick={() => {
                  Logger.info(
                    "Clicked login link in registration flow",
                    "Location ==> /register",
                    sessionDetails,
                  );
                  navigate("/login");
                }}
                testid="login-link"
              />
            </p>
          </div>
        )}
        {isVF ? (
          <>
            <p className={styles["sub-heading-vf"]}>
              {i18n.t(rk.LABEL_VEHICLE_INFO_VF)}
            </p>
            <div className={styles["sub-title"]}>
              <p className="u-mb-xsmall">{i18n.t(rk.TEXT_VEHICLE_INFO_VF)}</p>
              <p className={styles["vf-login-link"]}>
                <span className={styles["vf-login-link-text"]}>
                  {i18n.t(rk.TEXT_ALREADY_HAVE_AN_ACCOUNT)}
                </span>
                <Link
                  ariaLabel="Login link"
                  value={i18n.t(rk.TITLE_LOGIN)}
                  onClick={() => {
                    Logger.info(
                      "Clicked login link in registration flow",
                      "Location ==> /register",
                      sessionDetails,
                    );
                    navigate("/login");
                  }}
                  testid="login-link"
                />
              </p>
            </div>
          </>
        ) : (
          <>
            <p className={styles["sub-heading"]}>
              {i18n.t(rk.LABEL_VEHICLE_INFO)}
            </p>
            <p className={styles["sub-title"]}>
              {isAudi
                ? i18n.t(rk.TEXT_VEHICLE_INFO_AUDI)
                : i18n.t(rk.TEXT_VEHICLE_INFO)}
            </p>
          </>
        )}

        <p
          className={
            isVF
              ? `u-mb-xlarge ${styles["fields-required-text"]}`
              : styles["fields-required-text"]
          }
        >
          {i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}
        </p>
        {apiError && (
          <div className={styles.bannerNotification}>
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.ERROR_API_SERVICE_DOWN)}
            </BannerNotification>
          </div>
        )}
        <div className={isVF ? "" : styles["account-verify-form-fields"]}>
          <FormField
            id="accountNumber"
            label={
              isVF
                ? i18n.t(rk.LABEL_ACCOUNT_NUMBER_VF)
                : i18n.t(rk.LABEL_ACCOUNT_NUMBER)
            }
            type="text"
            testid="test-account-number-field"
            onChange={(e) => {
              setAccNumber(e.target.value);
              clearErrors("accountNumber");
              setApiError(false);
              setError("");
              if (noMatchError) {
                setNoMatchError(false);
              }
            }}
            autoCompleteOff
            value={accountNumber}
            hint={
              isVF
                ? i18n.t(rk.TEXT_ACCOUNT_NUM_HINT_VF)
                : i18n.t(rk.TEXT_ACCOUNT_NUM_HINT)
            }
            wrapperClassName={isVF ? "vf-wrapper" : ""}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_ACCOUNT_NUMBER),
              validate: {
                validateAccountNumber: (value) =>
                  validate.isValidAccountNumber(value) ||
                  i18n.t(rk.ERROR_INVALID_ACCNUMBER),
              },
            })}
            onBlur={async () => {
              await trigger("accountNumber");
              await setIsContinueEnabled(!errors.accountNumber?.message);
              if (errors.accountNumber?.message) {
                Logger.info(
                  `User entered invalid account number ==> ${accountNumber}`,
                  "Location ==> /register",
                  sessionDetails,
                  "",
                  null,
                  "",
                );
              }
            }}
            onKeyPress={restrictAccountKeyPress}
            fieldErrors={errors.accountNumber?.message || fieldError}
          />
          {isCanada ? (
            <div
              className={
                styles[
                  `${(errorDateOfBirth || fieldError) && "is-error-date-field"}`
                ]
              }
            >
              <div>
                <label
                  id={isAudi ? styles["date-of-birth-label"] : ""}
                  className="c-form-field__label"
                  htmlFor="month-input"
                >
                  {isVF ? i18n.t(rk.LABEL_DOB_VF) : i18n.t(rk.LABEL_DOB_VF)}
                </label>
              </div>
              <div id={styles["date-field-wrapper"]}>
                <input
                  type="text"
                  id="month-input"
                  aria-label="month-input"
                  data-testid="test-month-dob"
                  onKeyUp={(e) => {
                    handleSpecialKeysDate(
                      e,
                      "month",
                      month,
                      day,
                      year,
                      shouldGoToNext,
                      setShouldGoToNext,
                    );
                  }}
                  value={month}
                  onChange={(e) => {
                    handleOnChangeMonth(e, month, setMonth);
                    setErrorDateOfBirth("");
                    setError("");
                    if (noMatchError) {
                      setNoMatchError(false);
                    }
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  maxLength={2}
                  autoComplete="off"
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>/</label>
                <input
                  type="text"
                  id="day-input"
                  aria-label="day-input"
                  data-testid="test-day-dob"
                  onKeyUp={(e) => {
                    handleSpecialKeysDate(
                      e,
                      "day",
                      month,
                      day,
                      year,
                      shouldGoToNext,
                      setShouldGoToNext,
                    );
                  }}
                  value={day}
                  onChange={(e) => {
                    handleOnChangeDay(e, day, setDay);
                    setErrorDateOfBirth("");
                    setError("");
                    if (noMatchError) {
                      setNoMatchError(false);
                    }
                  }}
                  maxLength={2}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  autoComplete="off"
                />
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>/</label>
                <input
                  type="text"
                  id="year-input"
                  aria-label="year-input"
                  data-testid="test-year-dob"
                  className={styles["year-input"]}
                  onKeyUp={(e) => {
                    handleSpecialKeysDate(
                      e,
                      "year",
                      month,
                      day,
                      year,
                      shouldGoToNext,
                      setShouldGoToNext,
                    );
                  }}
                  onKeyPress={(e) => handleOnKeyPressYear(e)}
                  value={year}
                  onChange={(e) => {
                    setErrorDateOfBirth("");
                    setError("");
                    setYear(e.target.value);
                    if (noMatchError) {
                      setNoMatchError(false);
                    }
                  }}
                  onBlur={isValidDateOfBirth}
                  maxLength={4}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  autoComplete="off"
                />
              </div>
              {(errorDateOfBirth || fieldError) && (
                <p className="c-error-message u-text-fs-2">
                  {errorDateOfBirth || fieldError}
                </p>
              )}
              <p
                className={`c-form-field__hint ${styles["c-form-field__hint"]} ${isVF && "u-mb-xlarge"}`}
              >
                {i18n.t(rk.TEXT_DOB_HINT)}
              </p>
            </div>
          ) : (
            <FormField
              id="ssn"
              label={i18n.t(rk.LABEL_SSN)}
              type="text"
              testid="test-ssn-field"
              onChange={(e) => {
                handleSSNChange(e);
                clearErrors("ssn");
                setApiError(false);
                setError("");
                if (noMatchError) {
                  setNoMatchError(false);
                }
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              autoCompleteOff
              onKeyDown={handleKeyDown}
              onBlur={async () => {
                await trigger("ssn");
                await setIsContinueEnabled(!errors.ssn?.message);
                if (errors.ssn?.message) {
                  Logger.info(
                    "User entered invalid ssn",
                    "Location ==> /register",
                    sessionDetails,
                    "",
                    null,
                    "",
                  );
                }
              }}
              value={maskedSSN}
              hint={i18n.t(rk.TEXT_SSN_HINT)}
              fieldRef={register({
                required: i18n.t(rk.ERROR_EMPTY_SSN),
                validate: {
                  validateSSN: () =>
                    validate.isValidSSNOrTIN(ssn) ||
                    i18n.t(rk.ERROR_SSN_FORMAT),
                },
              })}
              fieldErrors={errors.ssn?.message || fieldError}
            />
          )}
          {isCanada && (
            <FormField
              id="vin"
              label={isVF ? i18n.t(rk.LABEL_VIN_VF) : i18n.t(rk.LABEL_VIN)}
              type="text"
              testid="test-vin-field"
              wrapperClassName={isVF ? "vf-wrapper" : ""}
              onChange={(e) => {
                setVin(e.target.value);
                clearErrors("vin");
                setApiError(false);
                setError("");
                if (noMatchError) {
                  setNoMatchError(false);
                }
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              autoCompleteOff
              value={vin}
              hint={
                isVF ? i18n.t(rk.TEXT_VIN_HINT_VF) : i18n.t(rk.TEXT_VIN_HINT)
              }
              onBlur={async () => {
                await trigger("vin");
                await setIsContinueEnabled(!errors.vin?.message);
                if (errors.vin?.message) {
                  Logger.info(
                    `User entered invalid VIN ==> ${vin}`,
                    "Location ==> /register",
                    sessionDetails,
                    "",
                    null,
                    "",
                  );
                }
              }}
              fieldRef={register({
                required: i18n.t(rk.ERROR_EMPTY_VIN),
                maxLength: { value: 6, message: i18n.t(rk.ERROR_INVALID_VIN) },
                minLength: { value: 6, message: i18n.t(rk.ERROR_INVALID_VIN) },
              })}
              onKeyPress={restrictVINKeyPress}
              fieldErrors={errors.vin?.message || fieldError}
            />
          )}
          <FormField
            id="email"
            label={
              isVF
                ? i18n.t(rk.LABEL_EMAIL_ADDRESS_VF)
                : i18n.t(rk.LABEL_EMAIL_ADDRESS)
            }
            type="text"
            testid="test-email-field"
            wrapperClassName={isVF ? "vf-wrapper" : ""}
            onChange={(e) => {
              setEmail(e.target.value);
              clearErrors("email");
              clearErrors("confirmEmail");
              setApiError(false);
              setError("");
              if (noMatchError) {
                setNoMatchError(false);
              }
            }}
            onKeyPress={restrictEmailKeyPress}
            onBlur={async () => {
              await trigger("email");
              await trigger("confirmEmail");
              await setIsContinueEnabled(
                !errors.email?.message && !errors.confirmEmail?.message,
              );
              if (errors.email?.message) {
                Logger.info(
                  `User entered invalid new email ==> ${email}`,
                  "Location ==> /register",
                  sessionDetails,
                  "",
                  null,
                  "",
                );
              }
            }}
            value={email}
            hint={i18n.t(rk.TEXT_EMAIL_HINT)}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_EMAIL),
              validate: {
                validateEmail: (value) =>
                  (isCanada
                    ? isValidCanadaEmail(value)
                    : isValidEmail(value)) || i18n.t(rk.ERROR_EMAIL_FORMAT),
              },
            })}
            fieldErrors={errors.email?.message || fieldError}
          />
          <FormField
            id="confirmEmail"
            label={
              isVF
                ? i18n.t(rk.LABEL_CONFIRM_EMAIL_ADDRESS_VF)
                : i18n.t(rk.LABEL_CONFIRM_EMAIL_ADDRESS)
            }
            type="text"
            wrapperClassName={isVF ? "vf-wrapper" : ""}
            testid="test-confirm-email-field"
            hint={isVF ? i18n.t(rk.TEXT_EMAIL_HINT) : ""}
            onChange={(e) => {
              setConfirmEmail(e.target.value);
              clearErrors("confirmEmail");
              setApiError(false);
              setError("");
              if (noMatchError) {
                setNoMatchError(false);
              }
            }}
            onKeyPress={restrictEmailKeyPress}
            onBlur={async () => {
              await trigger("confirmEmail");
              await setIsContinueEnabled(
                !errors.email?.message && !errors.confirmEmail?.message,
              );
              if (errors.confirmEmail?.message) {
                Logger.info(
                  `User entered invalid confirm email ==> ${confirmEmail}`,
                  "Location ==> /register",
                  sessionDetails,
                  "",
                  null,
                  "",
                );
              }
            }}
            value={confirmEmail}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_EMAIL),
              validate: {
                validateEmail: (value) =>
                  (isCanada
                    ? isValidCanadaEmail(value)
                    : isValidEmail(value)) || i18n.t(rk.ERROR_EMAIL_FORMAT),
                confirmEmailMatch: (value) =>
                  value === email || i18n.t(rk.ERROR_EMAIL_MATCH),
              },
            })}
            fieldErrors={errors.confirmEmail?.message || fieldError}
          />
        </div>
      </div>
    );
  },
);

export default VerifyCustomer;
