import React from "react";
import { string } from "prop-types";
import { QRCodeSVG } from "qrcode.react";
import styles from "./authenticatorAppSetupSteps.module.scss";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";

interface AuthenticatorAppSetupStepsProps {
  secret: string;
  secretURI: string;
  olClassName: string;
}
const AuthenticatorAppSetupSteps = ({
  secret,
  secretURI,
  olClassName,
}: AuthenticatorAppSetupStepsProps) => {
  return (
    <ol className={`${styles.authenticatorAppSetupSteps} ${olClassName}`}>
      <li>{i18n.t(rk.TEXT_STEP_1_ADD_ACCOUNT_AND_ENTER_CODE)}</li>
      <li>{i18n.t(rk.TEXT_STEP_2_ADD_ACCOUNT_AND_ENTER_CODE)}</li>
      <li>
        {i18n.t(rk.TEXT_STEP_3_ADD_ACCOUNT_AND_ENTER_CODE)}
        <span
          data-text={i18n.t(rk.TEXT_ACCOUNT_NAME_ADD_ACCOUNT_AND_ENTER_CODE)}
        >
          {` ${i18n.t(rk.TEXT_VW_ACCOUNT_NAME_ADD_ACCOUNT_AND_ENTER_CODE)}`}
        </span>
        <span
          data-text={i18n.t(rk.TEXT_SECRET_KEY_ADD_ACCOUNT_AND_ENTER_CODE)}
        >{` ${secret}`}</span>
        {secretURI && <QRCodeSVG value={secretURI} data-testid="qr-code" />}
      </li>
      <li>{i18n.t(rk.TEXT_STEP_4_ADD_ACCOUNT_AND_ENTER_CODE)}</li>
    </ol>
  );
};

AuthenticatorAppSetupSteps.propTypes = {
  secret: string,
  secretURI: string,
  olClassName: string,
};

AuthenticatorAppSetupSteps.defaultProps = {
  secret: "",
  secretURI: "",
  olClassName: "",
};

export default AuthenticatorAppSetupSteps;
