import React, { useContext } from "react";
import { string, bool, func } from "prop-types";
import _ from "lodash";
import { SessionContext } from "../../../../context/sessionContext";

interface FormFieldProps {
  type: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id: string;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean | string;
  value: string;
  fieldErrors?: string[];
  placeholder?: string;
  label?: string;
  fieldRef?: React.RefObject<HTMLInputElement>;
  wrapperClassName?: string | boolean;
  isSuccess?: boolean;
  testid?: string;
  hint?: string;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onCopy?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
  autoCompleteOff?: boolean;
  labelClassName?: string;
}

const FormField = (props: FormFieldProps) => {
  const {
    type,
    onChange,
    id,
    onFocus,
    onBlur,
    disabled,
    value,
    fieldErrors,
    placeholder,
    label,
    fieldRef,
    wrapperClassName,
    isSuccess,
    testid,
    hint,
    onKeyPress,
    onKeyDown,
    onCopy,
    onPaste,
    autoCompleteOff,
    labelClassName,
  } = props;
  const isDisabled = disabled ? "disabled" : "";
  const isAutoCompleteOff = autoCompleteOff ? "off" : "on";
  const { sessionDetails } = useContext(SessionContext);
  const brand = sessionDetails?.brand?.toUpperCase();
  const country = sessionDetails?.country?.toUpperCase();
  const canada = brand === "VW" && country === "CANADA";

  return (
    <div
      className={`o-fieldset__row ${wrapperClassName} form-field `}
      aria-label={id}
    >
      <div className="c-form-field is-active">
        <label
          htmlFor={id}
          className={`c-form-field__label ${!_.isEmpty(fieldErrors) && "is-error"}`}
        >
          {label}
        </label>
        <div className="c-form-field__box">
          <div className="c-input  ">
            <input
              className={`c-input__input ${isSuccess && "is-success"} ${labelClassName}`}
              id={id}
              type={type || "text"}
              ref={fieldRef}
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChange}
              name={id}
              value={value}
              placeholder={placeholder}
              // @ts-expect-error disabled is a string
              disabled={isDisabled}
              data-testid={testid}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyDown}
              onCopy={onCopy}
              onPaste={onPaste}
              autoComplete={isAutoCompleteOff}
            />
            {isSuccess && (
              <i
                className="c-input__state-icon"
                aria-hidden="true"
                role="img"
              />
            )}
          </div>
          {!_.isEmpty(fieldErrors) && (
            <p className="c-error-message u-text-fs-2">{fieldErrors}</p>
          )}
        </div>
        {hint && <p className="c-form-field__hint">{hint}</p>}
      </div>
      <style>{`
        .c-input {
          outline: none !important;
        }
        .c-form-field__label {
          ${brand === "AUDI" && "color: #666666 !important;"}
        }
        .c-error-message {
          width: fit-content;
        }
        .c-form-field__hint {
          font-size: ${brand === "AUDI" ? "13px" : "14px"} !important;
          line-height: 24px;
          margin: 0;
          color: ${brand === "AUDI" ? "#666666" : "rgb(106, 118, 125)"};
        }
        .is-error {
          ${brand === "AUDI" && "color: #bb0a30 !important;"}
        }
        #username:focus {
          ${canada && "border-color: #6a767d;"}
          ${canada && "border-color: #6a767d;"}
        }
        #password:focus {
          ${canada && "border-color: #6a767d;"}
        }
        .vf-wrapper {
          margin-bottom: 56px !important;
        }
      `}</style>
    </div>
  );
};

FormField.propTypes = {
  type: string,
  onChange: func,
  id: string,
  onFocus: func,
  onBlur: func,
  disabled: bool,
  value: string,
  placeholder: string,
  fieldRef: func,
  label: string,
  wrapperClassName: string,
  isSuccess: bool,
  testid: string,
  fieldErrors: string,
  hint: string,
  onKeyPress: func,
  onKeyDown: func,
  onCopy: func,
  onPaste: func,
  autoCompleteOff: bool,
  labelClassName: string,
};

FormField.defaultProps = {
  type: "",
  onChange: () => {},
  id: "",
  onFocus: () => {},
  onBlur: () => {},
  disabled: false,
  value: "",
  fieldErrors: "",
  placeholder: "",
  fieldRef: () => {},
  label: "",
  isSuccess: false,
  wrapperClassName: "",
  testid: "",
  hint: "",
  onKeyPress: () => {},
  onKeyDown: () => {},
  onCopy: () => {},
  onPaste: () => {},
  autoCompleteOff: false,
  labelClassName: "",
};

export default FormField;
