/* eslint-disable consistent-return */
/* eslint-disable radix */
import axios from "axios";
import i18n from "./localization/i18n";
import * as rk from "./localization/resourceKeys";

export const restrictKeyPressHandler = (regex: RegExp, length?: number) => {
  return (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    const stringCode = String.fromCharCode(charCode);
    const resultantValue = e.target.value + stringCode;

    if (!regex.test(resultantValue)) {
      return e.preventDefault();
    }
    if (length && e.target.value.length === length) {
      return e.preventDefault();
    }

    return true;
  };
};

export const transformDisplay = (val: string) => {
  // Strip all non numbers
  let displayVal = val.replace(/[^0-9|\\X]/g, "");
  displayVal = displayVal.substr(0, 9);

  // Inject dashes
  if (displayVal.length >= 4) {
    displayVal = `${displayVal.slice(0, 3)}-${displayVal.slice(3)}`;
  }

  if (displayVal.length >= 7) {
    displayVal = `${displayVal.slice(0, 6)}-${displayVal.slice(6)}`;
  }

  // Replace all numbers with 'X'
  if (displayVal.length <= 7) {
    displayVal = displayVal.replace(/[0-9]/g, "X");
  }
  return displayVal;
};

export const maskSSN = (newValue: string, ssn: string, maskedSSN: string) => {
  // isSelectllField flag introduced to cover the scenario where user would select all the ssn and start typing
  const isSelectAllField =
    newValue.length === 1 && maskedSSN.length !== 0 && maskedSSN.length <= 11;
  const prevValue = isSelectAllField ? "" : maskedSSN;
  let actual = isSelectAllField ? "" : ssn;
  if (newValue.length > 11) {
    return false;
  }
  // typing forward
  if (newValue.length > prevValue.length) {
    const newChar = newValue.split("").pop();
    actual = `${actual}${newChar}`;
  }
  // backspacing / deleting
  else {
    const charsRemovedCount = prevValue.length - newValue.length;
    actual = actual.substr(0, actual.length - charsRemovedCount);
  }

  let result = newValue;
  if (newValue.length < 7) {
    result = result.replace(/\d/g, "X");
  }
  return { maskedValue: transformDisplay(result), actualValue: actual };
};

export const getChallenegeQuestionOptions = (brand: string) => {
  if (brand === "AUDI") {
    return [
      `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION1_AUDI)}`,
      `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION2_AUDI)}`,
      `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION3_AUDI)}`,
      `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION4_AUDI)}`,
      `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION5_AUDI)}`,
      `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION6_AUDI)}`,
      `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION7_AUDI)}`,
      `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION8_AUDI)}`,
      `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION9_AUDI)}`,
    ];
  }
  return [
    `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION1)}`,
    `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION2)}`,
    `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION3)}`,
    `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION4)}`,
    `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION5)}`,
    `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION6)}`,
    `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION7)}`,
    `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION8)}`,
    `${i18n.t(rk.TEXT_SECURITY_QUESTION_OPTION9)}`,
  ];
};

interface ReqContext {
  country?: string;
  correlationId?: string;
  Brand?: string;
  Source?: string;
  SourceApp?: string;
  ProcessName?: string;
  WidgetName?: string;
}

export const buildCommonPayloadRequest = (reqContext: ReqContext) => {
  const payload = {
    Country: `${reqContext?.country}` || "",
    CorrelationId: `${reqContext?.correlationId}` || "",
    Brand: `${reqContext?.Brand}` || "",
    Source: `${reqContext?.Source}` || "",
    SourceApp: `${reqContext?.SourceApp}` || "",
    ProcessName: `${reqContext?.ProcessName}` || "",
    ParentFunctionName: `${reqContext?.ProcessName}` || "",
    WidgetName: `${reqContext?.WidgetName}` || "",
  };
  return payload;
};

/* Mask email a*****a@email.com */
export const maskEmail = (email: string) => {
  const [name, domain] = email.split("@");
  const { length: len } = name;
  const maskedName = `${name[0]}*****${name[len - 1]}`;
  const maskedEmail = `${maskedName}@${domain}`;
  return maskedEmail;
};

export default async function getMaintenanceMode() {
  const host = window.location.origin;
  return axios
    .get(`${host}/public/maintenance.json`)
    .then((response) => {
      return response && response?.data
        ? response.data
        : { inMaintenanceMode: "N" };
    })
    .then((res) => {
      if (res.inMaintenanceMode === "Y") return true;
      return false;
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
}

export const handleSpecialKeysDate = (
  event: KeyboardEvent,
  field: string,
  month: string,
  day: string,
  year: string,
  shouldGoToNext: boolean,
  setShouldGoToNext: (value: boolean) => void,
) => {
  const caretPos = document.getElementById(`${field}-input`).selectionStart;
  let shouldGoTemp = shouldGoToNext;
  if (event.key === "Backspace" || event.key === "Delete") {
    if (field === "day") {
      if (day === "") {
        document.getElementById("month-input").focus();
      } else if (day === "0") {
        document.getElementById("day-input").focus();
      }
    } else if (field === "year") {
      if (year === "") document.getElementById("day-input").focus();
    }
  } else if (event.key === "ArrowLeft") {
    if (field === "day" && caretPos === 0) {
      if (shouldGoToNext[field]) {
        document.getElementById("month-input").focus();
        shouldGoTemp = shouldGoToNext;
        shouldGoTemp[field] = false;
        setShouldGoToNext(shouldGoTemp);
      } else {
        shouldGoTemp = shouldGoToNext;
        shouldGoTemp[field] = true;
        setShouldGoToNext(shouldGoTemp);
      }
    } else if (field === "year" && caretPos === 0) {
      if (shouldGoToNext[field]) {
        document.getElementById("day-input").focus();
        shouldGoTemp = shouldGoToNext;
        shouldGoTemp[field] = false;
        setShouldGoToNext(shouldGoTemp);
      } else {
        shouldGoTemp = shouldGoToNext;
        shouldGoTemp[field] = true;
        setShouldGoToNext(shouldGoTemp);
      }
    } else {
      shouldGoTemp[field] = false;
      setShouldGoToNext(shouldGoTemp);
    }
  } else if (event.key === "ArrowRight") {
    if (field === "month" && caretPos === 2) {
      if (shouldGoToNext[field]) {
        document.getElementById("day-input").focus();
        shouldGoTemp = shouldGoToNext;
        shouldGoTemp[field] = false;
        setShouldGoToNext(shouldGoTemp);
      } else {
        shouldGoTemp = shouldGoToNext;
        shouldGoTemp[field] = true;
        setShouldGoToNext(shouldGoTemp);
      }
    } else if (field === "day" && caretPos === 2) {
      if (shouldGoToNext[field]) {
        document.getElementById("year-input").focus();
        shouldGoTemp = shouldGoToNext;
        shouldGoTemp[field] = false;
        setShouldGoToNext(shouldGoTemp);
      } else {
        shouldGoTemp = shouldGoToNext;
        shouldGoTemp[field] = true;
        setShouldGoToNext(shouldGoTemp);
      }
    } else {
      shouldGoTemp[field] = false;
      setShouldGoToNext(shouldGoTemp);
    }
  }
};

export const handleOnChangeMonth = (event, month, setMonth) => {
  const { value } = event.target;
  const valueNumber = parseInt(value);

  if (valueNumber > 12) return;
  let newMonth;
  if (valueNumber > 1 && valueNumber < 10 && value.length < 2) {
    newMonth = `0${value}`;
    document.getElementById("day-input").focus();
  } else {
    newMonth = value;
  }
  setMonth(newMonth);

  if (value.length === 2) document.getElementById("day-input").focus();
};

export const handleOnChangeDay = (event, day, setDay) => {
  const caretPos = document.getElementById(`day-input`).selectionStart;
  const { value } = event.target;
  const valueNumber = parseInt(value);

  if (valueNumber > 31) return;
  let newDay;
  if (valueNumber > 3 && valueNumber < 10) {
    newDay = `0${value}`;
    document.getElementById("year-input").focus();
  } else {
    newDay = value;
  }

  setDay(newDay);

  if (!value) event.preventDefault();

  if (value.length === 2 && caretPos === 2) {
    document.getElementById("year-input").focus();
  }
};

export const handleOnKeyPressYear = (event) => {
  const { key } = event;
  if (!key.match(/^[0-9]*$/i)) return event.preventDefault();
};

export const isValidEmail = (email: string) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return emailRegex.test(email);
};

export const isValidCanadaEmail = (email: string) => {
  const emailRegex =
    /^[a-zA-Z0-9.'&,ÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖàáâäçèéêëìíîïòóôöùúûüÙÚÛÜñÑ_%+-]+@[a-zA-Z0-9'&,ÀÁÂÄÇÈÉÊËÌÍÎÏÒÓÔÖàáâäçèéêëìíîïòóôöùúûüÙÚÛÜñÑ.-]+\.[a-zA-Z]{2,}$/;

  return emailRegex.test(email);
};
