import React, { useContext } from "react";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import { SessionContext } from "../../../context/sessionContext";

const TermsAndConditions = () => {
  const { sessionDetails } = useContext(SessionContext);
  const brand = sessionDetails?.brand?.toUpperCase();
  const country = sessionDetails?.country?.toUpperCase();
  const isVF = brand === "VW" && country === "CANADA";

  const getAFSLink = () => (
    <a href={i18n.t(rk.TEXT_TERMS_HREF_URL)} target="_blank" rel="noreferrer">
      {i18n.t(rk.TEXT_TERMS_HREF_TITLE)}
    </a>
  );

  return (
    <>
      {isVF ? (
        <>
          <p className="title">{i18n.t(rk.TITLE_TERMS_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_1_VF)}</p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_LAWS_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_2_VF)}</p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_ACCESS_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_3_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_4_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_5_VF)}</p>
          {i18n.language === "frCA" && <p>{i18n.t(rk.TEXT_TERMS_FRENCH_VF)}</p>}
          <p>{i18n.t(rk.TEXT_TERMS_6_VF)}</p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_PRIVACY_VF)}</p>
          <p className="subtitle">{i18n.t(rk.SUBTITLE_TERMS_PROTECT_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_7_VF)}</p>
          <p className="subtitle">{i18n.t(rk.SUBTITLE_TERMS_INFORMATION_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_8_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_9_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_10_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_11_VF)}</p>
          <p className="subtitle">
            {i18n.t(rk.SUBTITLE_TERMS_CONFIDENTIALITY_VF)}
          </p>
          <p>{i18n.t(rk.TEXT_TERMS_12_VF)}</p>
          <p className="subtitle">{i18n.t(rk.SUBTITLE_TERMS_COMMITMENT_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_13_VF)}</p>
          <p className="subtitle">{i18n.t(rk.SUBTITLE_TERMS_CHILDREN_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_14_VF)}</p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_SERVICES_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_15_VF)}</p>
          <ul>
            <li>{i18n.t(rk.LIST_TERMS_1_VF)}</li>
            <li>{i18n.t(rk.LIST_TERMS_2_VF)}</li>
            <li>{i18n.t(rk.LIST_TERMS_3_VF)}</li>
            <li>{i18n.t(rk.LIST_TERMS_4_VF)}</li>
            <li>{i18n.t(rk.LIST_TERMS_5_VF)}</li>
          </ul>
          <p>{i18n.t(rk.TEXT_TERMS_16_VF)}</p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_RESOLUTION_VF)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_17_VF)}</p>
        </>
      ) : (
        <>
          <p className="title">{i18n.t(rk.TITLE_TERMS)}</p>
          <p>
            <span>{i18n.t(rk.TEXT_TERMS_1_1)}</span>
            {getAFSLink()}
            <span>{i18n.t(rk.TEXT_TERMS_1_2)}</span>
            {getAFSLink()}
            <span>{i18n.t(rk.TEXT_TERMS_1_3)}</span>
          </p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_LAWS)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_2)}</p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_ACCESS)}</p>
          <p>
            <span>{i18n.t(rk.TEXT_TERMS_3_1)}</span>
            {getAFSLink()}
            <span>{i18n.t(rk.TEXT_TERMS_3_2)}</span>
          </p>
          <p>{i18n.t(rk.TEXT_TERMS_4)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_5)}</p>
          {i18n.language === "frCA" && <p>{i18n.t(rk.TEXT_TERMS_FRENCH)}</p>}
          <p>{i18n.t(rk.TEXT_TERMS_6)}</p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_PRIVACY)}</p>
          <p className="subtitle">{i18n.t(rk.SUBTITLE_TERMS_PROTECT)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_7)}</p>
          <p className="subtitle">{i18n.t(rk.SUBTITLE_TERMS_INFORMATION)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_8)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_9)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_10)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_11)}</p>
          <p className="subtitle">
            {i18n.t(rk.SUBTITLE_TERMS_CONFIDENTIALITY)}
          </p>
          <p>{i18n.t(rk.TEXT_TERMS_12)}</p>
          <p className="subtitle">{i18n.t(rk.SUBTITLE_TERMS_COMMITMENT)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_13)}</p>
          <p className="subtitle">{i18n.t(rk.SUBTITLE_TERMS_CHILDREN)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_14)}</p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_SERVICES)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_15)}</p>
          <ul>
            <li>{i18n.t(rk.LIST_TERMS_1)}</li>
            <li>{i18n.t(rk.LIST_TERMS_2)}</li>
            <li>{i18n.t(rk.LIST_TERMS_3)}</li>
            <li>{i18n.t(rk.LIST_TERMS_4)}</li>
            <li>{i18n.t(rk.LIST_TERMS_5)}</li>
          </ul>
          <p>{i18n.t(rk.TEXT_TERMS_16)}</p>
          <p className="title">{i18n.t(rk.TITLE_TERMS_RESOLUTION)}</p>
          <p>{i18n.t(rk.TEXT_TERMS_17)}</p>
        </>
      )}

      <style>{`
      .title {
        text-decoration: underline;
        font-weight: bold;
      }
      .subtitle {
        font-weight: bold;
      }
      p, li {
        font-size: 14px;
      }
      `}</style>
    </>
  );
};

export default TermsAndConditions;
