/* eslint-disable */
import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes, { bool, string } from "prop-types";
import Layout from "../common/layout/layout";
import Logger from "../../../logger";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import StepFlow from "../common/stepflow/stepFlow";
import ConfirmIdentity from "./confirmIdentity";
import EnterMFACode from "./enterMFACode";
import UpdateEmailStepFlow from "./updateEmailStepFlow";
import Loader from "../common/loader/loader";
import { SessionContext } from "../../../context/sessionContext";
import {
  AUTH_ENTER_YOUR_CODE_2FA_FLOW_CHANGE_VERIFICATION_EVENT,
  BACK_EMAIL_FLOW_EVENT,
  START_UPDATE_EMAIL_FLOW_EVENT,
  VERIFY_2FA_FLOW_CANCEL_EVENT,
} from "../../../constants/BusinessSplunkEvent";
import Link from "../common/link/link";
import confirmIdentityStyles from "./confirmIdentity.module.scss";
import UpdateEmailVerify from "./updateEmailFlow/updateEmailVerify";
import { generateAuthenticatorSecret } from "../../services/generateAuthenticatorSecret";

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface generateSecretProps {
  sessionDetails: SessionDetails;
  partyId: number;
  loginAuthenticatorCode: string;
}

interface AccountVerificationStepFlowProps {
  setStep: (value: any) => void;
  currentStep: number;
  currentStepIsCancel: boolean;
  setUserAuthInfo: (value: any) => void;
  email: string;
  partyId: number;
  userFingerprint: string;
  userAuthInfo: any;
}

const MFA_STEPS = /** @type {const} */ {
  CONFIRM_IDENTITY: 1,
  ENTER_YOUR_CODE: 2,
  VERIFY_IDENTITY: 3,
  IDLE: 4,
};

const generateSecret = async (
  { sessionDetails, partyId, loginAuthenticatorCode }: generateSecretProps,
  onSuccessCallback: (value: any) => void,
  navigate: (value: any) => void
) => {
  const body = {
    partyId,
    sessionDetails,
    loginAuthenticatorCode,
  };

  return generateAuthenticatorSecret(body)
    .then(({ data, error }) => {
      if (error) {
        Logger.error(
          "GenerateCustomerAuthenticatorSecret API Error",
          "Location ==> /login [accountVerificationStepFlow]",
          error,
          sessionDetails,
          "",
          partyId
        );
        return false;
      }

      if (data?.inMaintenance) {
        navigate(`/login`);
      }

      if (data?.isSuccess) {
        if (data?.secret && data?.secretURI)
          onSuccessCallback((prevState: any) => ({
            ...prevState,
            secret: data?.secret,
            secretURI: data.secretURI,
            doesSecretExist: false, // it changes to false once new secret is generated
          }));
        Logger.info(
          "Secret generation successful",
          "Location ==> /login [accountVerificationStepFlow]",
          sessionDetails,
          "",
          partyId
        );
        return true;
      }

      if (data?.errors) {
        Logger.error(
          "GenerateCustomerAuthenticatorSecret API Error",
          "Location ==> /login [accountVerificationStepFlow]",
          data?.errors,
          sessionDetails,
          "",
          partyId
        );
        return false;
      }

      return false;
    })
    .catch((err) => {
      Logger.error(
        "GenerateCustomerAuthenticatorSecret Error catched",
        "Location ==> /login [accountVerificationStepFlow]",
        err,
        sessionDetails,
        "",
        partyId
      );
      return false;
    });
};

const AccountVerificationStepFlow: React.FC = ({
  setStep,
  currentStep,
  currentStepIsCancel,
  email,
  partyId,
  userFingerprint,
  userAuthInfo,
  setUserAuthInfo,
}: AccountVerificationStepFlowProps) => {
  const { sessionDetails } = useContext(SessionContext);
  const ref = useRef(null);
  const navigate = useNavigate();
  const initialState = {
    name: "AccountVerificationFlow",
    currentStep: currentStep || MFA_STEPS.CONFIRM_IDENTITY,
    previousStep: -1,
    currentStepIsCancel: currentStepIsCancel || true,
    maxSteps: 2,
    submitPending: false,
  };
  const [state, setState] = useState(initialState);
  const [subFlow, setSubFlow] = useState("");
  const [enableVerify, setEnableVerify] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [newStep, setNewStep] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [isAccountLocked, setIsAccountLocked] = useState(false);
  const [continueEnabled, setContinueEnabled] = useState(false);
  const [cancelEnabled, setCancelEnabled] = useState(true);
  const isEmailAndAuthEnabled =
    process.env.REACT_APP_ENABLE_EMAIL_AND_AUTH === "true" &&
    sessionDetails?.country?.toLowerCase() === "usa" &&
    sessionDetails?.brand?.toLowerCase() === "vw";

  useEffect(() => {
    setState(() => ({
      ...state,
      currentStep: newStep || 1,
      previousStep: (newStep ?? 0) - 1 || -1,
    }));
  }, [newStep]);

  const isVF =
    sessionDetails?.brand?.toLowerCase() === "vw" &&
    sessionDetails?.country?.toLowerCase() === "canada";
  const country = sessionDetails?.country?.toLowerCase();
  const brand = sessionDetails?.brand?.toLowerCase();

  useEffect(() => {
    document.getElementsByClassName("page-content")[0]?.scrollTo(0, 0);
  }, [state.currentStep]);

  const renderImage = () => {
    if (brand === "audi" && country === "canada") {
      return "./images/audi_canada/mfa.jpg";
    } else if (brand === "vw" && country === "usa") {
      return "./images/vw_usa/mfa.jpg";
    } else if (brand === "vw" && country === "canada") {
      return "./images/vw_canada/mfa.png";
    }
  };

  const cancelAndReturnToLogin = (step: number) => {
    Logger.info(
      `Exited MFA Flow in Step ${step}`,
      "Location ==> /login",
      sessionDetails
    );
    if (isEmailAndAuthEnabled) {
      Logger.info(
        VERIFY_2FA_FLOW_CANCEL_EVENT,
        "Location ==> /login",
        sessionDetails
      );
    }
    setStep(i18n.t(rk.LOGIN_FLOW));
  };

  const renderChangeVerificationMethodLink = () => {
    if (!isEmailAndAuthEnabled) return null;
    return (
      <div
        className={`${confirmIdentityStyles["email-link-no-bold"]} ${isAccountLocked && confirmIdentityStyles["email-link-disabled"]}`}
      >
        <Link
          value={i18n.t(rk.LINK_CHANGE_VERIFICATION_METHOD)}
          onClick={() => {
            if (isAccountLocked) return;
            Logger.info(
              AUTH_ENTER_YOUR_CODE_2FA_FLOW_CHANGE_VERIFICATION_EVENT,
              "Location ==> EnterYourCode",
              sessionDetails
            );
            setState({
              ...state,
              currentStep: 1,
              previousStep: -1,
            });
          }}
          fontSize="16px"
          ariaLabel="Change verification method"
          testid="change-verification-method-link"
        />
      </div>
    );
  };

  let enterCodeNextLabel = null;
  if (!isEmailAndAuthEnabled) {
    enterCodeNextLabel = i18n.t(rk.BUTTON_CANCEL);
  }
  if (isAccountLocked) {
    enterCodeNextLabel = i18n.t(rk.BUTTON_CANCEL);
  }

  let enterCodePreviousLabel = i18n.t(rk.BUTTON_VERIFY);
  if (isEmailAndAuthEnabled && isAccountLocked) {
    enterCodePreviousLabel = null;
  }

  return subFlow === i18n.t(rk.UPDATE_EMAIL_FLOW) ? (
    <UpdateEmailStepFlow
      setNewEmail={setNewEmail}
      setSubFlow={setSubFlow}
      setNewStep={setNewStep}
    />
  ) : (
    <div className="intro-container">
      <Layout
        imgSource={renderImage()}
        customBackgroundStyles={
          isVF ? undefined : "background-position: 90% 0%"
        }
      >
        <StepFlow
          step={MFA_STEPS.CONFIRM_IDENTITY}
          currentStep={state.currentStep}
          title={isVF ? "" : <h2>{i18n.t(rk.TITLE_ACCOUNT_VERIFICATION)}</h2>}
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          canTakePreviousAction={async () => {
            setState({ ...state, submitPending: true });
            const canTakeAction = await ref.current.verifyUserAction();
            setState({ ...state, submitPending: false });
            return canTakeAction;
          }}
          previousAction={() => {
            setState({
              ...state,
              currentStep: MFA_STEPS.ENTER_YOUR_CODE,
              previousStep: MFA_STEPS.CONFIRM_IDENTITY,
            });

            Logger.info(
              "Clicked Continue in MFA Flow in Step 1",
              "Location ==> /login",
              sessionDetails
            );
          }}
          nextLabel={i18n.t(rk.BUTTON_CANCEL)}
          nextAction={() => {
            if (isEmailAndAuthEnabled) {
              Logger.info(
                VERIFY_2FA_FLOW_CANCEL_EVENT,
                "Location ==> ConfirmIdentity Screen",
                sessionDetails
              );
            }
            cancelAndReturnToLogin(MFA_STEPS.CONFIRM_IDENTITY);
          }}
          previousLabelDisabled={false}
        >
          <Loader isLoading={state.submitPending} />
          <ConfirmIdentity
            ref={ref}
            setSubFlow={setSubFlow}
            email={newEmail || email}
            partyId={partyId}
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            loginAuthenticatorCode={userAuthInfo.loginAuthenticatorCode}
            setUserAuthInfo={setUserAuthInfo}
            generateSecret={() =>
              generateSecret(
                {
                  sessionDetails,
                  partyId,
                  loginAuthenticatorCode: userAuthInfo.loginAuthenticatorCode,
                },
                setUserAuthInfo,
                navigate
              )
            }
          />
        </StepFlow>
        <StepFlow
          step={MFA_STEPS.ENTER_YOUR_CODE}
          currentStep={state.currentStep}
          title={<h2>{i18n.t(rk.TITLE_ACCOUNT_VERIFICATION)}</h2>}
          previousLabel={enterCodePreviousLabel}
          previousLabelDisabled={!enableVerify}
          previousAction={() => {
            Logger.info(
              "Clicked Continue in MFA Flow in Step 2",
              "Location ==> /login",
              sessionDetails
            );
          }}
          nextLabel={enterCodeNextLabel}
          nextAction={() => {
            cancelAndReturnToLogin(MFA_STEPS.ENTER_YOUR_CODE);
          }}
          canTakePreviousAction={async () => {
            const canTakeAction = await ref.current.verifyUserAction();
            return canTakeAction;
          }}
          renderChangeVerificationMethodLink={
            renderChangeVerificationMethodLink
          }
        >
          <EnterMFACode
            ref={ref}
            setSubFlow={setSubFlow}
            email={newEmail || email}
            partyId={partyId}
            setEnableVerify={setEnableVerify}
            userFingerprint={userFingerprint}
            selectedOption={selectedOption}
            secret={userAuthInfo.secret}
            secretURI={userAuthInfo.secretURI}
            enterYourCodeAndAuthFlow={userAuthInfo.doesSecretExist}
            setIsAccountLocked={setIsAccountLocked}
            loginAuthenticatorCode={userAuthInfo.loginAuthenticatorCode}
            setUserAuthInfo={setUserAuthInfo}
            addYourAccountAction={() => {
              setState((prevState) => ({
                ...prevState,
                currentStep: MFA_STEPS.VERIFY_IDENTITY,
                previousStep: MFA_STEPS.ENTER_YOUR_CODE,
              }));
            }}
          />
        </StepFlow>
        <StepFlow
          step={MFA_STEPS.VERIFY_IDENTITY}
          currentStep={state.currentStep}
          title={<h2>{i18n.t(rk.TITLE_CONFIRM_IDENTITY)}</h2>}
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          canTakePreviousAction={async () => {
            setState({ ...state, submitPending: true });
            const canTakeAction = await ref.current.verifyUserAction();

            if (!!canTakeAction === false) {
              setState({ ...state, submitPending: false });
              return false;
            }

            const isCodeGenerated = await generateSecret(
              {
                sessionDetails,
                partyId,
                loginAuthenticatorCode: userAuthInfo.loginAuthenticatorCode,
              },
              setUserAuthInfo
            );
            setState({ ...state, submitPending: false });

            return isCodeGenerated;
          }}
          previousAction={() => {
            setState({
              ...state,
              currentStep: MFA_STEPS.ENTER_YOUR_CODE,
              previousStep: MFA_STEPS.VERIFY_IDENTITY,
            });
            Logger.info(
              "Clicked Continue in Verify Identity",
              "Location ==> /login",
              sessionDetails
            );
            Logger.info(
              START_UPDATE_EMAIL_FLOW_EVENT,
              "Location => updateEmail Screen",
              sessionDetails
            );
          }}
          nextLabel={i18n.t(rk.BUTTON_CANCEL)}
          nextAction={() => {
            setState({
              ...state,
              currentStep: state.previousStep,
              previousStep: MFA_STEPS.VERIFY_IDENTITY,
            });
            Logger.info(
              BACK_EMAIL_FLOW_EVENT,
              "Location => updateEmail Screen",
              sessionDetails
            );
            Logger.info(
              "Exited Update Email Flow in Step 1",
              "Location ==> /login",
              sessionDetails
            );
          }}
          previousLabelDisabled={!continueEnabled}
          canTakeNextAction={() => cancelEnabled}
          nextLabelDisabled={!cancelEnabled}
        >
          <Loader isLoading={state.submitPending} />
          <UpdateEmailVerify
            ref={ref}
            setCancelEnabled={setCancelEnabled}
            setContinueEnabled={setContinueEnabled}
          />
        </StepFlow>
      </Layout>
    </div>
  );
};

AccountVerificationStepFlow.propTypes = {
  currentStep: PropTypes.number,
  currentStepIsCancel: PropTypes.bool,
  maintenance: PropTypes.shape({
    inMaintenanceMode: PropTypes.string,
  }),
  email: PropTypes.string,
  setStep: PropTypes.func,
  partyId: PropTypes.number,
  userFingerprint: PropTypes.string,
  userAuthInfo: PropTypes.shape({
    loginAuthenticatorCode: string,
    doesSecretExist: bool,
    secret: string,
    secretURI: string,
  }),
  setUserAuthInfo: PropTypes.func,
};

AccountVerificationStepFlow.defaultProps = {
  currentStep: undefined,
  currentStepIsCancel: undefined,
  maintenance: {
    inMaintenanceMode: "",
  },
  email: "",
  setStep: () => {},
  partyId: undefined,
  userFingerprint: "",
  userAuthInfo: {
    loginAuthenticatorCode: "",
    doesSecretExist: false,
    secret: "",
    secretURI: "",
  },
  setUserAuthInfo: () => {},
};

export default AccountVerificationStepFlow;
