import React, { useContext } from "react";
import i18n from "../../../../localization/i18n";
import * as rk from "../../../../localization/resourceKeys";
import { SessionContext } from "../../../../context/sessionContext";
import styles from "./logo.module.scss";

const Logo = () => {
  const { sessionDetails } = useContext(SessionContext);
  const brand = sessionDetails?.brand?.toUpperCase();
  const country = sessionDetails?.country?.toUpperCase();
  const language = sessionDetails?.language?.toUpperCase();
  const isVF = brand === "VW" && country === "CANADA";
  const isAudi = brand === "AUDI";
  const isVWFS = brand === "VW" && country === "USA";
  const isFrench = language === "FR-CA";

  return (
    <div className="logo_container" role="banner">
      {isAudi && (
        <div className={styles["logo-audi-container"]}>
          <div className={styles["audi-text-container"]}>
            <div className={styles["logo-audi-container"]}>
              {isFrench ? (
                <span className={`u-text-brand ${styles["text-brand-fr"]}`}>
                  {i18n.t(rk.TITLE_LOGO_AFS_2)}
                </span>
              ) : (
                <p
                  className={`logo_common u-text-alert ${styles["logo-audi"]}`}
                >
                  {i18n.t(rk.TITLE_LOGO_AFS_1)}
                </p>
              )}
            </div>
            {isFrench ? (
              <p className={`logo_common u-text-alert ${styles["logo-audi"]}`}>
                {i18n.t(rk.TITLE_LOGO_AFS_1)}
              </p>
            ) : (
              <span className={`u-text-brand ${styles["text-brand"]}`}>
                {i18n.t(rk.TITLE_LOGO_AFS_2)}
              </span>
            )}
          </div>
          <div className={styles["logo-img-audi"]}>
            <img
              src="/@vwfs-bronson/bronson-audi/dist/img/logo.svg"
              alt="AUDI LOGO"
              className={styles["c-logo__image-audi"]}
            />
          </div>
        </div>
      )}
      {isVF && (
        <div className={styles.logo_vf_wrapper}>
          <div className="logo_common logo_vf">
            <span>{i18n.t(rk.LOGO_VFS_1)}</span>
            <span>{i18n.t(rk.LOGO_VFS_2)}</span>
          </div>
          <div className="logo_common logo_vf_image">
            <img
              src="/@vwfs-bronson/bronson-vw6/dist/img/logo.svg"
              alt="VW LOGO"
              className="c-logo__image"
            />
          </div>
        </div>
      )}
      {isVWFS && (
        <div className={styles.logo_vwfs_wrapper}>
          <div className="logo_common logo_vwfs">
            {i18n.t(rk.LOGO_VFS_1)} {i18n.t(rk.LOGO_VFS_2)}
          </div>
          <div className="logo_common logo_vf_image">
            <img
              src="/@vwfs-bronson/bronson-vw6/dist/img/logo.svg"
              alt="VW LOGO"
              className="c-logo__image"
            />
          </div>
        </div>
      )}
      <style>{`
        .logo_img {
          height: 32px;
          width: 32px;
        }
        .logo_container {
          display: table;
        }
        .logo_common {
          display: table-cell;
          vertical-align: middle;
        }
        .logo-audi {
          font-weight: bold;
        }
        .logo_vw {
          padding-left: 16px;
          color: rgb(0, 30, 80);
          font-family: VWHeadWeb;
          font-weight: 700;
          font-size: 15px;
        }
        .logo_vf {
          padding-right: 10px;
          color: rgb(0, 30, 80);
          font-family: VWHeadWeb;
          font-weight: 700;
          font-size: 15px;
        }
        .logo_vwfs {
          padding-right: 10px;
          color: rgb(0, 30, 80);
          font-family: VWHeadWeb;
          font-weight: 700;
          font-size: 18px;
        }
        .logo_vf > span {
          display: block;
        }
        .logo_vf_image {
          height: 45px !important;
          width: 30px !important;
        }
        div.logo-vf span:last-child {
          margin-top: -5px;
        }
      `}</style>
    </div>
  );
};

export default Logo;
