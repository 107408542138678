/* eslint-disable import/prefer-default-export */
import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  ssn?: string;
  zipCode: string;
  dateOfBirth?: string;
  accountNumber?: string;
  sessionDetails: SessionDetails;
}

const gqlRequest = gql`
  query ForgotUserNameVerify($Request: VerifyForgotUserNameRequest!) {
    verifyForgotUserName(Request: $Request) {
      IsSuccess
      PartyId
      ChallengeQuestion
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

export const retrieveUsernameVerify = async (payload: Payload) => {
  const { zipCode, ssn, sessionDetails, dateOfBirth, accountNumber } = payload;
  const brand = sessionDetails?.brand?.toLowerCase();
  const country = sessionDetails?.country?.toLowerCase();
  const isAudi = brand === "audi";
  const isVWCanada = brand === "vw" && country === "canada";

  let identifier;
  if (isAudi || isVWCanada) {
    identifier = { DoB: dateOfBirth, AccountNumber: accountNumber };
  } else {
    identifier = { UniqueIdentifier: ssn };
  }
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request = {
      ZipCode: zipCode,
      ...identifier,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      SourceApp: sessionDetails.source,
      ParentFunctionName: "retrieve username",
      ProcessName: "Retrieve Username",
      WidgetName: sessionDetails.source,
    };

    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (response?.data?.data?.verifyForgotUserName?.IsSuccess) {
      return {
        data: {
          isSuccess: true,
          partyId: response?.data?.data?.verifyForgotUserName?.PartyId,
          challengeQuestion:
            response?.data?.data?.verifyForgotUserName?.ChallengeQuestion,
        },
      };
    }
    if (
      response?.data?.data?.verifyForgotUserName?.Errors ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: false,
          errors:
            response?.data?.data?.verifyForgotUserName.Errors?.[0] ||
            response?.data?.errors,
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { isSuccess: false, error: error.message || error.toString() };
  }
};
