// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wSFhbf0lx_fKaOiiy2oP{display:flex;line-height:24px;font-size:16px}.wSFhbf0lx_fKaOiiy2oP a{padding-bottom:0px !important}.aELdOKfaJ8JtPJsTXHdK{font-weight:600}.h55KbEjwzh8jTOBM0SPz{border:none;color:#000}`, "",{"version":3,"sources":["webpack://./src/components/common/language/languageToggle.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,gBAAA,CACA,cAAA,CACA,wBACE,6BAAA,CAKN,sBACI,eAAA,CAGJ,sBACI,WAAA,CACA,UAAA","sourcesContent":[".language-toggle {\n    display: flex;\n    line-height: 24px;\n    font-size: 16px;\n    a {\n      padding-bottom: 0px !important;\n    }\n    \n}\n\n.active {\n    font-weight: 600;\n}\n\n.inactive {\n    border: none;\n    color: #000;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"language-toggle": `wSFhbf0lx_fKaOiiy2oP`,
	"active": `aELdOKfaJ8JtPJsTXHdK`,
	"inactive": `h55KbEjwzh8jTOBM0SPz`
};
export default ___CSS_LOADER_EXPORT___;
