/* eslint-disable */
import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  useRef,
  useContext,
  useEffect,
  MutableRefObject,
} from "react";
import { useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { bool, func, number, string } from "prop-types";
import Logger from "../../../logger";
import Link from "../common/link/link";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import Loader from "../common/loader/loader";
import BannerNotification from "../common/banner/bannerNotification";
import styles from "./accountVerification.module.scss";
import confirmIdentityStyles from "./confirmIdentity.module.scss";
import enterMFACodeStyles from "./enterMFACode.module.scss";
import { maskEmail } from "../../../utilities";
import { SessionContext } from "../../../context/sessionContext";
import Checkbox from "../common/checkbox/checkbox";
import CountdownTimer from "../common/countdownTimer/countdownTimer";
import AuthenticatorAppSetupSteps from "./authenticatorAppSetupSteps";
import {
  AUTHENTICATOR_OPTION,
  EMAIL_OPTION,
} from "../../../constants/EmailAndAuth";
import {
  AUTHENTICATOR_ADD_ACCOUNT_SCREEN_EVENT,
  AUTHENTICATOR_ENTER_YOUR_CODE_SCREEN_EVENT,
  ENTER_YOUR_CODE_AUTHENTICATOR_2FA_CANCEL_FLOW,
  ADD_ACCOUNT_2FA_FLOW_CANCEL_EVENT,
  ENTER_YOUR_CODE_2FA_CANCEL_FLOW,
  ENTER_YOUR_CODE_2FA_FLOW,
  START_EMAIL_2FA_FLOW,
  FINISH_2FA_FLOW_EVENT,
  LAUNCH_PORTAL_EVENT,
  START_UPDATE_EMAIL_FLOW_EVENT,
  FINISH_AUTHENTICATOR_2FA_FLOW_EVENT,
  TWO_FA_AUTHENTICATOR_FLOW_ERROR_EVENT,
  INVALID_OR_EXPIRED_AUTH_SECURE_CODE,
  TWO_FA_FLOW_ERROR_EVENT,
  INVALID_OR_EXPIRED_SECURE_CODE,
  AUTHENTICATOR_UPDATE_SECRET_EVENT,
  TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_ONE,
  TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_TWO,
  TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_THREE,
  TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_FOUR,
  TWO_FA_EMAIL_ENTER_YOUR_CODE_ACCOUNT_LOCKED,
  TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_ONE,
  TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_TWO,
  TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_THREE,
  TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_FOUR,
  TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_ACCOUNT_LOCKED,
  TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_ONE,
  TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_TWO,
  TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_THREE,
  TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_FOUR,
  TWO_FA_ADD_ACCOUNT_ACCOUNT_LOCKED,
} from "../../../constants/BusinessSplunkEvent";
import { verifyCustomerAuthenticatorCode } from "../../services/verifyCustomerAuthenticatorCode";
import { generateMFA } from "../../services/generateMFA";
import { verifyMFA } from "../../services/verifyMFA";

interface ErrorCodeProps {
  isError: boolean;
  values: string;
  onEnterCodeChange: (value: any) => void;
  className: string;
  isEmailAndAuthEnabled: boolean;
  accountLocked: boolean;
  selectedOption: string;
}

interface RememberDeviceCheckBoxProps {
  onRememberDeviceChange: (value: any) => void;
  rememberDevice: boolean;
  className: string;
  disabled: boolean;
}

interface EnterMFACodeProps {
  setSubFlow: (value: any) => void;
  email: string;
  partyId: number;
  setEnableVerify: (value: any) => void;
  userFingerprint: string;
  selectedOption: string;
  secret: string;
  secretURI: string;
  addYourAccountAction: (value: any) => void;
  enterYourCodeAndAuthFlow: boolean;
}

const EnterCode = forwardRef(
  (
    {
      isError,
      values,
      onEnterCodeChange,
      className,
      isEmailAndAuthEnabled,
      accountLocked,
      selectedOption,
    }: ErrorCodeProps,
    ref: MutableRefObject<any>
  ) => {
    return (
      <>
        <div
          className={`${className} ${isError && isEmailAndAuthEnabled && enterMFACodeStyles["input-error"]} ${
            isEmailAndAuthEnabled &&
            accountLocked &&
            enterMFACodeStyles["input-disabled"]
          }`}
        >
          <ReactCodeInput
            autoFocus
            fields={6}
            values={values}
            onChange={onEnterCodeChange}
            disabled={accountLocked}
            ref={ref}
          />
        </div>
        {isError && (
          <p
            className="c-error-message"
            style={{ fontSize: "12px", marginLeft: "0px" }}
          >
            {isEmailAndAuthEnabled && selectedOption === AUTHENTICATOR_OPTION
              ? i18n.t(rk.ERROR_INVALID_CODE_EMAIL_AND_AUTHENTICATOR)
              : i18n.t(rk.ERROR_INVALID_MFA_CODE)}
          </p>
        )}
      </>
    );
  }
);

EnterCode.defaultProps = {
  isError: false,
  values: "",
  onEnterCodeChange: () => {},
  className: "",
  isEmailAndAuthEnabled: false,
  accountLocked: false,
  selectedOption: "",
};

EnterCode.propTypes = {
  isError: bool,
  values: string,
  onEnterCodeChange: func,
  className: string,
  isEmailAndAuthEnabled: bool,
  accountLocked: bool,
  selectedOption: string,
};

const RememberDeviceCheckBox = ({
  onRememberDeviceChange,
  rememberDevice,
  className,
  disabled,
}: RememberDeviceCheckBoxProps) => {
  return (
    <div className={className}>
      <div className="u-mv-large">
        <Checkbox
          disabled={disabled}
          label={i18n.t(rk.REMEMBER_DEVICE)}
          id="remeberDevice"
          onChange={onRememberDeviceChange}
          checked={rememberDevice}
          testid="remember-device-checkbox"
        />
      </div>
    </div>
  );
};

RememberDeviceCheckBox.defaultProps = {
  onRememberDeviceChange: () => {},
  rememberDevice: false,
  className: string,
  disabled: false,
};

RememberDeviceCheckBox.propTypes = {
  onRememberDeviceChange: func,
  rememberDevice: bool,
  className: "",
  disabled: bool,
};

const EnterMFACode = forwardRef(
  (
    {
      setSubFlow,
      email,
      partyId,
      setEnableVerify,
      userFingerprint,
      selectedOption,
      secret,
      secretURI,
      addYourAccountAction,
      enterYourCodeAndAuthFlow,
    }: EnterMFACodeProps,
    ref: MutableRefObject<any>
  ) => {
    const { sessionDetails } = useContext(SessionContext);
    const [inputCode, setInputCode] = useState("");
    const [isError, setIsError] = useState(false);
    const [badCount, setBadCount] = useState();
    const [apiError, setApiError] = useState(false);
    const [attemptError, setAttemptError] = useState(false);
    const [accountLocked, setAccountLocked] = useState(false);
    const [submitPending, setSubmitPending] = useState(false);
    const [rememberDevice, setRememberDevice] = useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [reset, setReset] = useState(false);
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
    const isEmailAndAuthEnabled =
      process.env.REACT_APP_ENABLE_EMAIL_AND_AUTH === "true" &&
      sessionDetails?.country?.toLowerCase() === "usa" &&
      sessionDetails?.brand?.toLowerCase() === "vw";
    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            if (selectedOption === EMAIL_OPTION) {
              Logger.info(
                "Exited MFA Flow in Step 2 by clicking back button on the browser tab",
                "Location ==> /register",
                sessionDetails
              );
            }

            if (isEmailAndAuthEnabled) {
              if (selectedOption === EMAIL_OPTION) {
                Logger.info(
                  ENTER_YOUR_CODE_2FA_CANCEL_FLOW,
                  "Location => enterMFACode Screen",
                  sessionDetails
                );
              } else if (enterYourCodeAndAuthFlow) {
                Logger.info(
                  ENTER_YOUR_CODE_AUTHENTICATOR_2FA_CANCEL_FLOW,
                  "Location => enterMFACode Screen",
                  sessionDetails
                );
              } else {
                Logger.info(
                  ADD_ACCOUNT_2FA_FLOW_CANCEL_EVENT,
                  "Location => enterMFACode Screen",
                  sessionDetails
                );
              }
            }
          }
        },
        false
      );
    }, []);
    useEffect(() => {
      window.addEventListener("beforeunload", (e) => {
        if (selectedOption === EMAIL_OPTION) {
          Logger.info(
            "Exited MFA Flow in Step 2 by closing the browser tab",
            "Location ==> /register",
            sessionDetails
          );
        }
        if (isEmailAndAuthEnabled) {
          if (selectedOption === EMAIL_OPTION) {
            Logger.info(
              START_EMAIL_2FA_FLOW,
              "Location => enterMFACode Screen",
              sessionDetails
            );
            Logger.info(
              ENTER_YOUR_CODE_2FA_FLOW,
              "Location => enterMFACode Screen",
              sessionDetails
            );
          } else if (enterYourCodeAndAuthFlow) {
            Logger.info(
              AUTHENTICATOR_ENTER_YOUR_CODE_SCREEN_EVENT,
              "Location => enterMFACode Screen",
              sessionDetails
            );
          } else {
            Logger.info(
              AUTHENTICATOR_ADD_ACCOUNT_SCREEN_EVENT,
              "Location => enterMFACode Screen",
              sessionDetails
            );
          }
        }
      });
    }, []);

    useEffect(() => {
      if (isEmailAndAuthEnabled) {
        if (selectedOption === EMAIL_OPTION) {
          Logger.info(
            START_EMAIL_2FA_FLOW,
            "Location => enterMFACode Screen",
            sessionDetails
          );
          Logger.info(
            ENTER_YOUR_CODE_2FA_FLOW,
            "Location => enterMFACode Screen",
            sessionDetails
          );
        } else if (enterYourCodeAndAuthFlow) {
          Logger.info(
            AUTHENTICATOR_ENTER_YOUR_CODE_SCREEN_EVENT,
            "Location => enterMFACode Screen",
            sessionDetails
          );
        } else {
          Logger.info(
            AUTHENTICATOR_ADD_ACCOUNT_SCREEN_EVENT,
            "Location => enterMFACode Screen",
            sessionDetails
          );
        }
      }
    }, [enterYourCodeAndAuthFlow]);

    const getInputCode = async (value: any) => {
      const isContatiansSpace = value.indexOf(" ") >= 0;
      if (value.length >= 6 && !isContatiansSpace) {
        setInputCode(value);
        setIsError(false);
        setEnableVerify(true);
      } else {
        setEnableVerify(false);
        setInputCode("");
      }
    };
    useEffect(() => {
      setInputCode("");
      setEnableVerify(false);
      function handlekeydownEvent(event: KeyboardEvent) {
        const { keyCode } = event;
        if (keyCode === 40) {
          let shouldSkip = false;
          document
            .getElementsByClassName("styles_react-code-input__CRulA")[0]
            .getElementsByTagName("input")
            .forEach(async (element: HTMLInputElement, index: number) => {
              if (shouldSkip) {
                return;
              }
              if (element.matches(":focus") && index < 5) {
                document
                  .getElementsByClassName("styles_react-code-input__CRulA")[0]
                  .getElementsByTagName("input")
                  [index + 1]?.focus();
                shouldSkip = true;
              }
            });
        }
        if (keyCode === 38) {
          document
            .getElementsByClassName("styles_react-code-input__CRulA")[0]
            .getElementsByTagName("input")
            .forEach(async (element: HTMLInputElement, index: number) => {
              if (element.matches(":focus") && index !== 0) {
                document
                  .getElementsByClassName("styles_react-code-input__CRulA")[0]
                  .getElementsByTagName("input")
                  [index - 1]?.focus();
              }
            });
        }
        if (keyCode === 46) {
          document
            .getElementsByClassName("styles_react-code-input__CRulA")[0]
            .getElementsByTagName("input")
            .forEach(async (element: HTMLInputElement, index: number) => {
              if (element.matches(":focus") && index >= 0) {
                if (
                  document
                    .getElementsByClassName("styles_react-code-input__CRulA")[0]
                    .getElementsByTagName("input")[index].value
                ) {
                  document
                    .getElementsByClassName("styles_react-code-input__CRulA")[0]
                    .getElementsByTagName("input")[index].value = "";
                  inputRef.current.state.values[index] = "";
                } else {
                  document
                    .getElementsByClassName("styles_react-code-input__CRulA")[0]
                    .getElementsByTagName("input")
                    [index - 1].focus();
                }
              }
            });
          getInputCode(inputRef.current.state.values.join(""));
        }
        if (keyCode === 13) {
          if (
            document
              .getElementsByClassName("c-link__effect")[0]
              .matches(":focus")
          ) {
            (
              document.getElementsByClassName(
                "c-link__effect"
              )[0] as HTMLElement
            ).click();
          } else if (
            document
              .getElementsByClassName("c-link__effect")[1]
              .matches(":focus")
          ) {
            (
              document.getElementsByClassName(
                "c-link__effect"
              )[1] as HTMLElement
            ).click();
          }
        }
      }
      document.addEventListener("keyup", handlekeydownEvent);
      return () => {
        document.removeEventListener("keyup", handlekeydownEvent);
      };
    }, []);

    async function onSubmit() {
      setIsError(false);
      setApiError(false);
      setAttemptError(false);
      setAccountLocked(false);
      if (!isEmailAndAuthEnabled) {
        Logger.info(
          "Clicked verify button",
          "Location ==> /mfaCode",
          sessionDetails
        );
      }

      let apiUrl = "/api/verifyMFA";
      const body = {
        mfaCode: inputCode,
        partyId,
        rememberDevice,
        sessionDetails,
        userFingerprint,
      };
      if (isEmailAndAuthEnabled && selectedOption === AUTHENTICATOR_OPTION) {
        delete body.mfaCode;
        apiUrl = "/api/verifyCustomerAuthenticatorCode";
        body.authenticatorCode = inputCode;
      }
      const { data, error } =
        isEmailAndAuthEnabled && selectedOption === AUTHENTICATOR_OPTION
          ? await verifyCustomerAuthenticatorCode(body)
          : await verifyMFA(body);
      if (data?.inMaintenance) {
        navigate(`/login`);
      }
      if (data?.isSuccess) {
        setSubmitPending(false);
        if (data?.isVerified) {
          if (isEmailAndAuthEnabled) {
            Logger.info(
              FINISH_2FA_FLOW_EVENT,
              "Location => enterMFACode Screen",
              sessionDetails
            );
          }
          if (
            isEmailAndAuthEnabled &&
            selectedOption === AUTHENTICATOR_OPTION
          ) {
            Logger.info(
              FINISH_AUTHENTICATOR_2FA_FLOW_EVENT,
              "Location => enterMFACode Screen",
              sessionDetails
            );
          }
          Logger.info(
            "Successful MFA Verification",
            "Location ==> /login",
            sessionDetails,
            data?.accountNumber,
            partyId
          );
          if (rememberDevice) {
            Logger.info(
              "Successful MFA Verification - Remember Device",
              "Location ==> /login",
              sessionDetails
            );
          } else if (!rememberDevice) {
            Logger.info(
              "Successful MFA Verification - Not Remember Device",
              "Location ==> /login",
              sessionDetails
            );
          }
          const accountNumber = data?.accountNumber;
          const token = data?.token;
          let url;
          if (
            sessionDetails?.country?.toLowerCase() === "canada" &&
            sessionDetails?.brand?.toLowerCase() === "audi"
          ) {
            url = process.env.REACT_APP_AFPORTAL_URL;
            Logger.info(
              LAUNCH_PORTAL_EVENT,
              "Location => enterMFACode Screen",
              sessionDetails
            );
            window.location.href = `${url}?token=${token}&accountNumber=${accountNumber}&language=${sessionDetails.language}`;
          } else if (
            sessionDetails?.country?.toLowerCase() === "usa" &&
            sessionDetails?.brand?.toLowerCase() === "vw"
          ) {
            url = `${process.env.REACT_APP_VCIPORTAL_URL}?brand=${sessionDetails?.brand}&`;
            Logger.info(
              LAUNCH_PORTAL_EVENT,
              "Location => enterMFACode Screen",
              sessionDetails
            );
            window.location.href = `${url}token=${token}&accountNumber=${accountNumber}&language=${sessionDetails.language}`;
          } else if (
            sessionDetails?.country?.toLowerCase() === "canada" &&
            sessionDetails?.brand?.toLowerCase() === "vw"
          ) {
            url = process.env.REACT_APP_VFPORTAL_URL;
            Logger.info(
              LAUNCH_PORTAL_EVENT,
              "Location => enterMFACode Screen",
              sessionDetails
            );
            window.location.href = `${url}?token=${token}&accountNumber=${accountNumber}&language=${sessionDetails.language}`;
          }
        }
        if (isEmailAndAuthEnabled && !data?.isVerified) {
          if (selectedOption === AUTHENTICATOR_OPTION) {
            Logger.info(
              INVALID_OR_EXPIRED_AUTH_SECURE_CODE,
              "Location => enterMFACode Screen",
              sessionDetails
            );
          } else {
            Logger.info(
              INVALID_OR_EXPIRED_SECURE_CODE,
              "Location => enterMFACode Screen",
              sessionDetails
            );
          }
        }
        if (data?.loginAttemptCount) {
          if (data?.loginAttemptCount === 1)
            if (
              isEmailAndAuthEnabled &&
              selectedOption === AUTHENTICATOR_OPTION &&
              !enterYourCodeAndAuthFlow
            ) {
              Logger.info(
                TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_ONE,
                "",
                sessionDetails,
                "",
                partyId
              );
            } else {
              Logger.info(
                selectedOption === AUTHENTICATOR_OPTION
                  ? TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_ONE
                  : TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_ONE,
                "",
                sessionDetails,
                "",
                partyId
              );
            }
          if (data?.loginAttemptCount === 2)
            if (
              isEmailAndAuthEnabled &&
              selectedOption === AUTHENTICATOR_OPTION &&
              !enterYourCodeAndAuthFlow
            ) {
              Logger.info(
                TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_TWO,
                "",
                sessionDetails,
                "",
                partyId
              );
            } else {
              Logger.info(
                selectedOption === AUTHENTICATOR_OPTION
                  ? TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_TWO
                  : TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_TWO,
                "",
                sessionDetails,
                "",
                partyId
              );
            }
          if (data?.loginAttemptCount === 3)
            if (
              isEmailAndAuthEnabled &&
              selectedOption === AUTHENTICATOR_OPTION &&
              !enterYourCodeAndAuthFlow
            ) {
              Logger.info(
                TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_THREE,
                "",
                sessionDetails,
                "",
                partyId
              );
            } else {
              Logger.info(
                selectedOption === AUTHENTICATOR_OPTION
                  ? TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_THREE
                  : TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_THREE,
                "",
                sessionDetails,
                "",
                partyId
              );
            }
          if (data?.loginAttemptCount === 4)
            if (
              isEmailAndAuthEnabled &&
              selectedOption === AUTHENTICATOR_OPTION &&
              !enterYourCodeAndAuthFlow
            ) {
              Logger.info(
                TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_FOUR,
                "",
                sessionDetails,
                "",
                partyId
              );
            } else {
              Logger.info(
                selectedOption === AUTHENTICATOR_OPTION
                  ? TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_FOUR
                  : TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_FOUR,
                "",
                sessionDetails,
                "",
                partyId
              );
            }

          const loginAttempt = data?.loginAttemptCount;
          Logger.info(
            `MFA Code Entered is incorrect. Attempt - ${loginAttempt}`,
            "Location ==> /login",
            sessionDetails,
            "",
            partyId
          );
          setIsError(true);
          setSubmitPending(false);
          setBadCount(loginAttempt);
          setAttemptError(true);
        }
        if (data?.errors?.ErrorCode === "badRequest_accountLocked") {
          if (
            isEmailAndAuthEnabled &&
            selectedOption === AUTHENTICATOR_OPTION &&
            !enterYourCodeAndAuthFlow
          ) {
            Logger.info(
              TWO_FA_ADD_ACCOUNT_ACCOUNT_LOCKED,
              "",
              sessionDetails,
              "",
              partyId
            );
          } else {
            Logger.error(
              selectedOption === AUTHENTICATOR_OPTION
                ? TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_ACCOUNT_LOCKED
                : TWO_FA_EMAIL_ENTER_YOUR_CODE_ACCOUNT_LOCKED,
              "",
              "",
              sessionDetails,
              "",
              partyId
            );
          }
          Logger.info(
            "Account Locked on MFA Verification",
            "Location ==> /login",
            sessionDetails,
            "",
            partyId
          );
          setSubmitPending(false);
          sessionStorage.setItem("isAccountLocked", true);
          if (isEmailAndAuthEnabled) {
            setIsError(true);
            setAccountLocked(true);
          } else {
            navigate("./login");
          }
        }
        return false;
      }
      setApiError(true);
      setSubmitPending(false);
      if (isEmailAndAuthEnabled) {
        if (selectedOption === AUTHENTICATOR_OPTION) {
          Logger.info(
            TWO_FA_AUTHENTICATOR_FLOW_ERROR_EVENT,
            "Location => enterMFACode Screen",
            sessionDetails
          );
        } else {
          Logger.info(
            TWO_FA_FLOW_ERROR_EVENT,
            "Location => enterMFACode Screen",
            sessionDetails
          );
        }
      }
      Logger.error(
        `${apiUrl === "/api/verifyCustomerAuthenticatorCode" ? "CustomerAuthenticatorCodeVerify" : "CustomerMFAVerify"} API Error`,
        "Location ==> /login",
        data?.errors || error || data,
        sessionDetails,
        "",
        partyId
      );
      return false;
    }
    async function sendNewCode() {
      setSubmitPending(true);
      setIsError(false);
      setApiError(false);
      const body = {
        partyId,
        sessionDetails,
      };
      const { data, error } = await generateMFA(body);
      if (data?.inMaintenance) {
        navigate(`/login`);
      }
      if (data?.isSuccess) {
        setSubmitPending(false);
        setReset(!reset);
        setIsExpired(false);
        Logger.info(
          "Customer MFA generate success",
          "Location ==> /login",
          sessionDetails,
          "",
          partyId,
          ""
        );
      }
      if (data?.errors) {
        setSubmitPending(false);
        setApiError(true);
        Logger.info(
          TWO_FA_FLOW_ERROR_EVENT,
          "Location => enterMFACode Screen",
          sessionDetails
        );
        Logger.info(
          "Customer MFA generate Failed",
          "Location ==> /login",
          sessionDetails,
          "",
          partyId,
          ""
        );
      }
      if (error || data?.error) {
        setSubmitPending(false);
        Logger.info(
          TWO_FA_FLOW_ERROR_EVENT,
          "Location => enterMFACode Screen",
          sessionDetails
        );
        Logger.error(
          "CustomerMFAGenerate API Error",
          "Location ==> /login",
          error || data?.error || "",
          sessionDetails,
          "",
          partyId
        );
        setApiError(true);
      }
    }
    async function verifyUserAction() {
      setSubmitPending(true);
      const isSuccess = await onSubmit();
      return isSuccess;
    }

    useImperativeHandle(ref, () => ({ verifyUserAction }));
    return (
      <div className={`${isAudi && "audi-enter-mfa-container"}`}>
        {isEmailAndAuthEnabled && apiError && (
          <div className={styles.bannerNotification}>
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.ERROR_API_SERVICE_DOWN)}
            </BannerNotification>
          </div>
        )}
        {selectedOption === EMAIL_OPTION &&
          (isAudi ? (
            <p className={`${styles["sub-heading-audi"]} u-mt-xlarge`}>
              {i18n.t(rk.TITLE_MFA_ENTER_CODE)}
            </p>
          ) : (
            <p className={styles["sub-heading"]}>
              {i18n.t(rk.TITLE_MFA_ENTER_CODE)}
            </p>
          ))}

        {isEmailAndAuthEnabled && selectedOption === AUTHENTICATOR_OPTION && (
          <p
            className={`${styles["sub-heading"]} ${confirmIdentityStyles["sub-heading"]}`}
          >
            {enterYourCodeAndAuthFlow
              ? i18n.t(rk.TITLE_ENTER_CODE)
              : i18n.t(rk.TITLE_ADD_ACCOUNT_AND_ENTER_CODE)}
          </p>
        )}
        {isEmailAndAuthEnabled &&
          selectedOption === AUTHENTICATOR_OPTION &&
          enterYourCodeAndAuthFlow && (
            <p
              className={`${styles["body-text"]} ${isAudi ? "body-text-audi" : ""}`}
            >
              {i18n.t(rk.TEXT_ENTER_CODE)}
            </p>
          )}
        {isEmailAndAuthEnabled &&
          selectedOption === AUTHENTICATOR_OPTION &&
          enterYourCodeAndAuthFlow && (
            <p
              className={`${styles["body-text"]} ${isAudi ? "body-text-audi" : ""}`}
              style={{ marginTop: "18px" }}
            >
              <span>{i18n.t(rk.TEXT_SETUP_OR_RESET_AUTHENTICATOR)}</span>
              <Link
                value={i18n.t(rk.LINK_ADD_YOUR_ACCOUNT)}
                ariaLabel="Add your account"
                testid="add-your-account"
                fontSize="16px"
                onClick={addYourAccountAction}
              />
            </p>
          )}
        {(!isEmailAndAuthEnabled || selectedOption === EMAIL_OPTION) && (
          <p className={`${styles["body-text"]} body-text-audi`}>
            {i18n.t(rk.TEXT_SCREEN_TWO_TEXT_ONE)}
            <span
              className={styles["masked-email"]}
            >{`${maskEmail(email)?.toLowerCase()}`}</span>
            {i18n.t(rk.TEXT_SCREEN_TWO_TEXT_TWO)}
            <CountdownTimer
              minutes={15}
              seconds={0}
              onFinish={() => {
                setIsExpired(true);
              }}
              reset={reset}
            />
            {i18n.t(rk.TEXT_SCREEN_TWO_TEXT_THREE)}
          </p>
        )}
        {(!isEmailAndAuthEnabled || selectedOption === EMAIL_OPTION) && (
          <div
            className={
              isAudi ? styles["audi-email-link"] : styles["email-link"]
            }
          >
            <Link
              value={i18n.t(rk.LINK_UPDATE_EMAIL_ADDRESS)}
              onClick={() => {
                Logger.info(
                  "Initiate Update Email Flow",
                  "Location ==> /Login",
                  sessionDetails
                );
                Logger.info(
                  START_UPDATE_EMAIL_FLOW_EVENT,
                  "Location => enterMFACode Screen",
                  sessionDetails
                );
                setSubFlow(i18n.t(rk.UPDATE_EMAIL_FLOW));
              }}
              fontSize="14px"
              ariaLabel="Update email address"
              testid="update-email-address-link"
            />
          </div>
        )}
        <Loader isLoading={submitPending} />
        {!isEmailAndAuthEnabled && apiError && (
          <div className={styles.bannerNotification}>
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.ERROR_API_SERVICE_DOWN)}
            </BannerNotification>
          </div>
        )}
        {isEmailAndAuthEnabled &&
          selectedOption === AUTHENTICATOR_OPTION &&
          !enterYourCodeAndAuthFlow && (
            <AuthenticatorAppSetupSteps secret={secret} secretURI={secretURI} />
          )}
        {attemptError && (badCount ?? 0) >= 2 && (
          <div className={styles.bannerNotification}>
            <BannerNotification
              titleClassName={`${isEmailAndAuthEnabled && enterMFACodeStyles["banner-notification-title"]}`}
              title={`${i18n.t(rk.TITLE_LOCKOUT_WARNING1)} ${5 - (badCount ?? 0)}
             ${badCount === 4 ? i18n.t(rk.TITLE_LOCKOUT_WARNING3) : i18n.t(rk.TITLE_LOCKOUT_WARNING2)}`}
              notificationStyle="warning"
            >
              {i18n.t(rk.TEXT_LOCKOUT_MFA_WARNING)}
            </BannerNotification>
          </div>
        )}

        {isEmailAndAuthEnabled && accountLocked && (
          <div className={styles.bannerNotification}>
            <BannerNotification
              notificationStyle="error"
              title={i18n.t(rk.TITLE_LOCKOUT_ERROR_EMAIL_AND_AUTHENTICATOR)}
              titleClassName={enterMFACodeStyles["banner-notification-title"]}
            >
              {i18n.t(rk.TEXT_LOCKOUT_ERROR_EMAIL_AND_AUTHENTICATOR)}
            </BannerNotification>
          </div>
        )}
        <EnterCode
          accountLocked={accountLocked}
          isError={isError || isExpired}
          values={inputCode}
          onEnterCodeChange={getInputCode}
          className={`${isError || isExpired ? "custom-styles error" : "custom-styles"} ${
            selectedOption === AUTHENTICATOR_OPTION &&
            enterYourCodeAndAuthFlow &&
            !isError &&
            !accountLocked &&
            !attemptError &&
            enterMFACodeStyles["enter-code"]
          }`}
          isEmailAndAuthEnabled={isEmailAndAuthEnabled}
          selectedOption={selectedOption}
          ref={inputRef}
        />
        {(!isEmailAndAuthEnabled || selectedOption === EMAIL_OPTION) && (
          <p
            className={
              isAudi ? styles["audi-helper-text"] : styles["helper-text"]
            }
            style={{ marginTop: "18px" }}
          >
            <span className={`${isAudi && "send_me_code_audi"} send_me_code`}>
              {i18n.t(rk.TEXT_DIDNT_RECV_CODE)}
            </span>
            <Link
              value={i18n.t(rk.LINK_SEND_NEW_CODE)}
              ariaLabel="Send a new code"
              testid="send-a new-code"
              fontSize="14px"
              onClick={sendNewCode}
            />
          </p>
        )}
        <RememberDeviceCheckBox
          rememberDevice={rememberDevice}
          onRememberDeviceChange={() => {
            setRememberDevice(!rememberDevice);
            Logger.info(
              `${!rememberDevice ? "Checked" : "Unchecked"} remember this device checkbox`,
              "Location ==> /login",
              sessionDetails
            );
          }}
          className={styles["remember-device-container"]}
          disabled={accountLocked}
        />

        <style>
          {`
          .styles_react-code-input__CRulA > input {
            border-right: none !important;
            border-left: none;
            border-top: none;
            margin-right: 10px;
            border-top-right-radius: 0px !important;
            border-bottom-right-radius: 0px !important;
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            font-family: "VWHeadWeb";
            font-weight: 300;
            color: #3C484d;
            font-size: 32px;
            border-bottom: solid 1px #dfe4e8;
            width: 4vw !important;
          }
          .styles_react-code-input__CRulA > input:focus {
            border: none;
            border-bottom: solid 1px #001e50;
            caret-color: black;
          }
          .styles_react-code-input-container__tpiKG {
            width: fit-content !important;
          }
          .c-link__effect:focus {
            outline: none;
            color: #0040c5;
          }
          @media(max-width: 959px){
            .styles_react-code-input__CRulA > input {
              width: 10vw !important;
              padding-left: 5px;
              padding-right: 5px;
            }
          }
          .send_me_code{
            marginRight: "3px";
            font-size: 12px;
          }
          .send_me_code_audi {
            color: rgb(3, 3, 3) !important;
          }
          @media (min-width: 1600px) {
            .send_me_code{
              font-size: 14px !important;
            }
          }
          .body-text-audi {
            font-size: 14px;
            margin-bottom: 15px;
          }
          .audi-enter-mfa-container .styles_react-code-input__CRulA > input {
            font-family: AudiTypeExtended;
            border-bottom: 1px solid rgb(0, 0, 0);
            color: rgb(0, 0, 0);
          }
          @media(max-width: 959px){
            .audi-enter-mfa-container .styles_react-code-input__CRulA > input {
              width: 10vw !important;
              padding-left: 4px;
              padding-right: 4px;
            }
          }
          @media(min-width: 1600px){
            .audi-enter-mfa-container .styles_react-code-input__CRulA > input {
              width: 3vw !important;
            }
          }
      `}
        </style>
      </div>
    );
  }
);
export default EnterMFACode;

EnterMFACode.propTypes = {
  setSubFlow: func,
  email: string,
  partyId: number,
  setEnableVerify: func,
  userFingerprint: string,
  selectedOption: string,
  secret: string,
  secretURI: string,
  enterYourCodeAndAuthFlow: bool,
  addYourAccountAction: func,
};

EnterMFACode.defaultProps = {
  setSubFlow: () => {},
  email: "",
  partyId: undefined,
  setEnableVerify: () => {},
  userFingerprint: "",
  selectedOption: "",
  secret: "",
  secretURI: "",
  enterYourCodeAndAuthFlow: false,
  addYourAccountAction: () => {},
};
