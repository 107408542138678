/* eslint-disable */

import React, { forwardRef, useContext, MutableRefObject } from "react";
import { string } from "prop-types";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import "./confirmation.scss";
import { SessionContext } from "../../../context/sessionContext";

interface ConfirmationProps {
  userName: string;
}

const Confirmation = forwardRef(
  ({ userName }: ConfirmationProps, ref: MutableRefObject<any>) => {
    const { sessionDetails } = useContext(SessionContext);
    const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";

    return (
      <p className={isAudi && "user-name-text"}>
        {i18n.t(rk.TEXT_USERNAME_VALUE)}
        <span className="text_value">{i18n.t(userName)}</span>
      </p>
    );
  }
);

export default Confirmation;

Confirmation.propTypes = {
  userName: string,
};
Confirmation.defaultProps = {
  userName: "",
};
