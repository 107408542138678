/* eslint-disable */
import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useContext,
  MutableRefObject,
} from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import * as validate from "@ownportal/customer-validations";
import ReCAPTCHA from "react-google-recaptcha";
import BannerNotification from "../../common/banner/bannerNotification";
import FormField from "../../common/form-field/formField";
import i18n from "../../../../localization/i18n";
import * as rk from "../../../../localization/resourceKeys";
import { SessionContext } from "../../../../context/sessionContext";
import Logger from "../../../../logger";
import { isValidCanadaEmail, isValidEmail, restrictKeyPressHandler } from "../../../../utilities";
import {
  CANCEL_EMAIL_FLOW_EVENT,
  SECTION_2_UPDATE_EMAIL_FLOW_EVENT,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_ONE,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_TWO,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_THREE,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_FOUR,
  TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_ACCOUNT_LOCKED,
} from "../../../../constants/BusinessSplunkEvent";
import updateEmail from "../../../services/updateEmail";

interface Authentication { 
  accountNumber: string;
  partyId: number;
  ssn: string;
}
interface UpdateEmailFormProps {
  setContinueEnabled: (value: any) => void;
  setNewEmail: (value: any) => void;
  setCancelEnabled: (value: any) => void;
  authenticationInfo: Authentication;
}

const UpdateEmailForm = forwardRef(
  (
    { setNewEmail, setContinueEnabled, setCancelEnabled, authenticationInfo }: UpdateEmailFormProps,
    ref: MutableRefObject<any>
  ) => {
    const { sessionDetails } = useContext(SessionContext);
    const { register, errors, clearErrors, trigger, setValue, getValues } =
      useForm({});
    const [unsuccessfullRequests, setUnsuccessfullRequests] = useState(0);
    const [invalidError, setInvalidError] = useState<string | boolean>("");
    const [formDisabled, setFormDisabled] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [captchaError, setCaptchaError] = useState(false);
    const captchaRef = useRef(null);
    const isCaptchaEnabled =
      process.env.REACT_APP_AWS_ENV !== "dev" &&
      process.env.NODE_ENV !== "test";
    const [captchaCompleted, setCaptchaCompleted] = useState(!isCaptchaEnabled);
    const siteKey = "6LcAP-0bAAAAAI2QfcaulKzivfpCv3symKFQ1S22";
    const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
    const isVW = sessionDetails?.brand?.toLowerCase() === "vw";
    const isCanada = sessionDetails?.country?.toLowerCase() === "canada";
    const isVF = isVW && isCanada;

    const handleCaptchaSuccess = () => {
      setCaptchaError(false);
      setCaptchaCompleted(true);
    };

    const handleCaptchaError = () => {
      setCaptchaCompleted(false);
    };

    const shouldContinueBeEnabled = () => {
      const { email, confirmEmail } = getValues();
      if (email && confirmEmail && isEmpty(errors) && captchaCompleted) {
        setContinueEnabled(true);
      } else {
        setContinueEnabled(false);
      }
    };

    const handleOnChange = async (e: any) => {
      clearErrors(e.target.name);
      setValue(e.target.name, e.target.value);
      await trigger(e.target.name);
      shouldContinueBeEnabled();
    };

    const handleCaptchaExpired = () => {
      setCaptchaCompleted(false);
      setCaptchaError(true);
    };
    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              "Exited Update Email Flow in Step 2 by clicking back button on the browser tab",
              "Location ==> /register",
              sessionDetails
            );
            Logger.info(
              CANCEL_EMAIL_FLOW_EVENT,
              "Location => updateEmail Screen",
              sessionDetails
            );
          }
        },
        false
      );
      // eslint-disable-next-line no-unused-vars
      window.addEventListener("beforeunload", (e) => {
        Logger.info(
          "Exited Update Email Flow in Step 2 by closing the browser tab",
          "Location ==> /register",
          sessionDetails
        );
        Logger.info(
          CANCEL_EMAIL_FLOW_EVENT,
          "Location => updateEmail Screen",
          sessionDetails
        );
      });
      Logger.info(
        SECTION_2_UPDATE_EMAIL_FLOW_EVENT,
        "Location => updateEmail Screen",
        sessionDetails
      );
      // When the user change the language, triggers the existing error.
      for (const error in errors) {
        trigger(error);
      }
    }, [sessionDetails]);

    useEffect(() => {
      if (captchaError) {
        try {
          captchaRef?.current?.reset();
        } catch (error) {
          setCaptchaError(false);
        }
      }
    }, [captchaError]);

    useEffect(() => {
      shouldContinueBeEnabled();
    }, [captchaCompleted]);

    async function onSubmit() {
      setInvalidError(false);
      setApiError(false);
      const { email } = getValues();
      const body = {
        email,
        ...authenticationInfo,
        sessionDetails,
      };
      const { data, error } = await updateEmail(body);
      if (data?.isSuccess) {
        setNewEmail(email);
        Logger.info(
          "UpdateCustomerEmail Successful",
          "Location ==> /login",
          sessionDetails,
          authenticationInfo?.accountNumber,
          authenticationInfo?.partyId
        );
        return true;
      }
      // shouldn't happen from UI - handling anyway
      if (data?.loginAttemptCount) {
        if (data?.loginAttemptCount === 1)
          Logger.info(
            TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_ONE,
            "",
            sessionDetails,
            "",
            authenticationInfo?.partyId
          );
        if (data?.loginAttemptCount === 2)
          Logger.info(
            TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_TWO,
            "",
            sessionDetails,
            "",
            authenticationInfo?.partyId
          );
        if (data?.loginAttemptCount === 3)
          Logger.info(
            TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_THREE,
            "",
            sessionDetails,
            "",
            authenticationInfo?.partyId
          );
        if (data?.loginAttemptCount === 4)
          Logger.info(
            TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_FOUR,
            "",
            sessionDetails,
            "",
            authenticationInfo?.partyId
          );

        Logger.info(
          "Customer not verified in Update Email Flow",
          "Location ==> /login",
          sessionDetails,
          authenticationInfo?.accountNumber,
          authenticationInfo?.partyId
        );
        setInvalidError(i18n.t(rk.ERROR_INVALID_INFO));
        setUnsuccessfullRequests(data?.loginAttemptCount);
        return false;
      }
      setApiError(true);
      Logger.error(
        "UpdateCustomerEmail API Error",
        "Location ==> /login",
        data || error,
        sessionDetails,
        authenticationInfo?.accountNumber,
        authenticationInfo?.partyId
      );
      if (isCaptchaEnabled) {
        captchaRef?.current?.reset();
        setCaptchaCompleted(false);
      }
      return false;
    }

    useEffect(() => {
      if (unsuccessfullRequests >= 5) {
        setContinueEnabled(false);
        setCancelEnabled(false);
        setFormDisabled(true);
        Logger.error(
          TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_ACCOUNT_LOCKED,
          "",
          "",
          sessionDetails,
          "",
          authenticationInfo?.partyId
        );
      }
    }, [unsuccessfullRequests]);

    useEffect(() => {
      document?.getElementById("email")?.focus();
    }, []);

    async function verifyUserAction() {
      await trigger("email");
      await trigger("confirmEmail");
      if (!isEmpty(errors) || !captchaCompleted) {
        return false;
      }
      const isSuccess = await onSubmit();
      if (isSuccess) {
        return true;
      }
      return false;
    }

    useImperativeHandle(ref, () => ({ verifyUserAction }));

    const restrictEmailKeyPress = restrictKeyPressHandler(/^[^ ]*$/);

    return (
      <>
        {isVF && (
          <p className="title-vf u-mb-xsmall">
            {i18n.t(rk.TITLE_UPDATE_EMAIL)}
          </p>
        )}
        {unsuccessfullRequests >= 5 && (
          <div className="banner-notification u-mb u-mt">
            <BannerNotification
              title={`${i18n.t(rk.TITLE_LOCKOUT_ERROR)}`}
              notificationStyle="error"
            >
              {i18n.t(rk.TEXT_LOCKOUT_ERROR_MFA)}
            </BannerNotification>
          </div>
        )}
        {unsuccessfullRequests >= 2 && unsuccessfullRequests < 5 && (
          <div className="banner-notification u-mb u-mt">
            <BannerNotification
              title={`${i18n.t(rk.TITLE_LOCKOUT_WARNING1)} ${5 - unsuccessfullRequests}
             ${unsuccessfullRequests === 4 ? i18n.t(rk.TITLE_LOCKOUT_WARNING3) : i18n.t(rk.TITLE_LOCKOUT_WARNING2)}`}
              notificationStyle="warning"
            >
              {i18n.t(rk.TEXT_LOCKOUT_WARNING_VERIFY)}
            </BannerNotification>
          </div>
        )}
        {apiError && (
          <div className="banner-notification u-mb u-mt">
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.ERROR_API_SERVICE_DOWN)}
            </BannerNotification>
          </div>
        )}
        <p className="required-text">{i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}</p>
        <div className={`${!isCanada && "u-pl-large"} u-pl-none@m`}>
          <FormField
            id="email"
            label={i18n.t(rk.LABEL_NEW_EMAIL_ADDRESS)}
            type="text"
            testid="email-field"
            onChange={(e) => {
              handleOnChange(e);
              if (getValues().confirmEmail) {
                trigger("confirmEmail");
              }
            }}
            onKeyPress={restrictEmailKeyPress}
            value={getValues().email}
            hint={i18n.t(rk.TEXT_EMAIL_HINT)}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_EMAIL),
              validate: {
                validateEmail: (value) =>
                  (isAudi
                    ? isValidCanadaEmail(value)
                    : isValidEmail(value)) ||
                  i18n.t(rk.ERROR_EMAIL_FORMAT),
              },
            })}
            onBlur={() => {
              if (errors.email?.message) {
                Logger.info(
                  `User entered invalid new email ==> ${getValues().email}`,
                  "Location ==> /Login",
                  sessionDetails,
                  authenticationInfo?.accountNumber,
                  "",
                  ""
                );
              }
            }}
            fieldErrors={errors.email?.message || invalidError}
            disabled={formDisabled}
            wrapperClassName={isVF && "u-pb-large"}
          />
          <FormField
            id="confirmEmail"
            label={i18n.t(rk.LABEL_CONFIRM_EMAIL_ADDRESS)}
            type="text"
            testid="confirm-email-field"
            onChange={handleOnChange}
            onKeyPress={restrictEmailKeyPress}
            value={getValues().confirmEmail}
            hint={isVF && i18n.t(rk.TEXT_EMAIL_HINT)}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_EMAIL),
              validate: {
                validateEmail: (value) =>
                  (isAudi
                    ? isValidCanadaEmail(value)
                    : isValidEmail(value)) ||
                  i18n.t(rk.ERROR_EMAIL_FORMAT),
                confirmEmailMatch: (value) =>
                  value === getValues().email || i18n.t(rk.ERROR_EMAIL_MATCH),
              },
            })}
            onBlur={() => {
              if (errors.confirmEmail?.message) {
                Logger.info(
                  `User entered invalid confirm email ==> ${getValues().confirmEmail}`,
                  "Location ==> /Login",
                  sessionDetails,
                  authenticationInfo?.accountNumber,
                  "",
                  ""
                );
              }
            }}
            fieldErrors={errors.confirmEmail?.message || invalidError}
            disabled={formDisabled}
            wrapperClassName={isVF && "u-pb-large"}
          />
          {isCaptchaEnabled && (
            <ReCAPTCHA
              className="u-mt"
              ref={captchaRef}
              sitekey={siteKey}
              onChange={handleCaptchaSuccess}
              onExpired={handleCaptchaExpired}
              onErrored={handleCaptchaError}
              disabled={formDisabled}
            />
          )}
          {captchaError && (
            <p className="c-error-message u-mt-xsmall">
              {i18n.t(rk.ERROR_CAPTCHA_EXPIRED)}
            </p>
          )}
        </div>
        <style>{`
        .required-text {
          font-size: 14px !important;
          font-weight: 700;
        }
        .title-vf {
          font-size: 32px;
        }
        @media (min-width: 1024px) {
          .content {
            ${isAudi && `min-width: 630px !important;`}
          }
        }
        @media (min-width: 1220px) {
          .content {
            ${isAudi && `min-width: 630px !important;`}
          }
        }
      `}</style>
      </>
    );
  }
);

UpdateEmailForm.propTypes = {
  setNewEmail: PropTypes.func,
  setContinueEnabled: PropTypes.func,
  setCancelEnabled: PropTypes.func,
  authenticationInfo: PropTypes.shape({
    accountNumber: PropTypes.string,
    ssn: PropTypes.string,
    partyId: PropTypes.number,
  }),
};

UpdateEmailForm.defaultProps = {
  setNewEmail: () => {},
  setContinueEnabled: () => {},
  setCancelEnabled: () => {},
  authenticationInfo: {
    accountNumber: "",
    ssn: "",
    partyId: null,
  },
};

export default UpdateEmailForm;
