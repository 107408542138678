/* eslint-disable @typescript-eslint/no-namespace */
import React, { useEffect, useState, useMemo } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SessionContextProvider } from "../context/sessionContext";
import { CacheBusterContextProvider } from "../context/cacheBusterContext";
import Login from "./pages/login";
import RetrieveUsername from "./pages/retrieveUsername";
import Register from "./pages/register";
import ResetPassword from "./pages/resetPassword";
import AFSLand from "./pages/access-my-afs-account";
import i18n from "../localization/i18n";
import * as rk from "../localization/resourceKeys";
import getMaintenanceMode from "../utilities";

const App: React.FC = () => {
  const navigate = useNavigate();
  const { pathname, origin } = window.location;

  const [brand, setBrand] = useState(process.env.REACT_APP_BRAND);
  const [country, setCountry] = useState(process.env.REACT_APP_COUNTRY);
  const [appProps, setAppProps] = useState({
    brand: process.env.REACT_APP_BRAND,
    country: process.env.REACT_APP_COUNTRY,
    source: "",
    language: "",
  });
  const { hostname } = window.location;
  const [inMaintenanceMode, setInMaintenanceMode] = useState("N");

  // BUILD AppProps
  useEffect(() => {
    // BRAND
    if (hostname.includes("vwcredit") || hostname.includes("vwfinance")) {
      setBrand("VW");
    }
    if (hostname.includes("audifs") || hostname.includes("audifinance")) {
      setBrand("Audi");
    }
    // COUNTRY
    if (hostname.includes(".com")) {
      setCountry("USA");
    }
    if (hostname.includes(".ca")) {
      setCountry("Canada");
    }
  }, [hostname]);
  useEffect(() => {
    setAppProps({
      brand,
      country,
      source: "",
      language: "",
    });
  }, [brand, country]);

  const getTitle = () => {
    if (brand?.toUpperCase() === "VW") {
      if (country?.toUpperCase() === "CANADA") {
        return i18n.t(rk.TITLE_VFPORTAL_APP);
      }
      return i18n.t(rk.TITLE_VWCPORTAL_APP);
    }
    if (brand?.toUpperCase() === "AUDI") {
      if (country?.toUpperCase() === "CANADA") {
        return i18n.t(rk.TITLE_AFPORTAL_APP);
      }
      return i18n.t(rk.TITLE_AFSPORTAL_APP);
    }
    return i18n.t(rk.TITLE);
  };

  const appTitle = getTitle();

  const favicon =
    brand?.toUpperCase() === "VW"
      ? "https://cdn.bronson.vwfs.tools/vw6/v/1.12.0/img/logo.svg"
      : "https://cdn.bronson.vwfs.tools/audi/v/10.3.0/img/logo.svg";

  const jsurl = `/@vwfs-bronson/bronson-${brand?.toUpperCase() === "VW" ? "vw6" : "audi"}/dist/js/bronson.min.js`;
  const cssurl = `/@vwfs-bronson/bronson-${brand?.toUpperCase() === "VW" ? "vw6" : "audi"}/dist/css/style.min.css`;

  // REDIRECTIONS
  useMemo(() => {
    let urlCountry = process.env.REACT_APP_COUNTRY;
    let urlBrand = process.env.REACT_APP_BRAND;
    // BRAND
    if (hostname.includes("vwcredit") || hostname.includes("vwfinance")) {
      urlBrand = "VW";
    }
    if (hostname.includes("audifs") || hostname.includes("audifinance")) {
      urlBrand = "Audi";
    }
    // COUNTRY
    if (hostname.includes(".com")) {
      urlCountry = "USA";
    }
    if (hostname.includes(".ca")) {
      urlCountry = "Canada";
    }
    if (pathname === "/" && urlCountry === "Canada") {
      if (urlBrand === "Audi") {
        window.location.href =
          "https://www.audi.ca/ca/web/en/audi-finance.html?partner_id=";
      }
      if (urlBrand === "VW") {
        window.location.href = "https://www.vw.ca/en/financial-services.html";
      }
    }
  }, []);
  useEffect(() => {
    async function getMode() {
      const response = await getMaintenanceMode();
      if (response === true) {
        setInMaintenanceMode("Y");
      } else setInMaintenanceMode("N");
    }
    getMode();
    if (!origin.includes("localhost")) {
      if (origin?.includes("auth")) {
        if (pathname === "/" && country === "Canada") {
          if (brand === "Audi") {
            window.location.href =
              "https://www.audi.ca/ca/web/en/audi-finance.html?partner_id=";
          }
          if (brand === "VW") {
            window.location.href =
              "https://www.vw.ca/en/financial-services.html";
          }
        }

        if (country === "USA" && brand === "Audi") {
          window.location.href = `${process.env.REACT_APP_AF_URL}/access-my-afs-account`;
        }
        if (country === "USA" && brand === "VW") {
          if (
            [
              "/register",
              "/login",
              "/resetPassword",
              "/retrieveUsername",
            ].includes(pathname)
          ) {
            window.location.href = `${process.env.REACT_APP_VWC_URL}${pathname}`;
          } else {
            window.location.href = `${process.env.REACT_APP_VWC_URL}/login`;
          }
        }
        if (country === "Canada" && brand === "VW") {
          if (
            [
              "/register",
              "/login",
              "/resetPassword",
              "/retrieveUsername",
            ].includes(pathname)
          ) {
            window.location.href = `${process.env.REACT_APP_VF_URL}${pathname}`;
          } else {
            window.location.href = `${process.env.REACT_APP_VF_URL}/login`;
          }
        }
        if (country === "Canada" && brand === "Audi") {
          if (
            [
              "/register",
              "/login",
              "/resetPassword",
              "/retrieveUsername",
            ].includes(pathname)
          ) {
            window.location.href = `${process.env.REACT_APP_AF_URL}${pathname}`;
          } else {
            window.location.href = `${process.env.REACT_APP_AF_URL}/login`;
          }
        }
      }
      if (country === "USA" && brand === "Audi") {
        navigate("/access-my-afs-account");
      }
    } else if (country === "USA" && brand === "Audi") {
      navigate("/access-my-afs-account");
    }
  }, [brand, country]);

  return (
    <div className="App">
      <HelmetProvider>
        <Helmet>
          <link rel="icon" href={favicon} />
          <title>{appTitle}</title>
          <script type="text/javascript" src={jsurl} />
          <link rel="stylesheet" href={cssurl} />
        </Helmet>
        <CacheBusterContextProvider appProps={appProps}>
          <SessionContextProvider appProps={appProps}>
            <div id="router-content-wrapper">
              <div id="router-content">
                <Routes>
                  <Route
                    path="/retrieveUsername"
                    element={
                      <RetrieveUsername
                        currentStep={1}
                        currentStepIsCancel={false}
                        maintenance={{ inMaintenanceMode }}
                      />
                    }
                  />
                  <Route
                    path="/register"
                    element={
                      <Register
                        currentStep={1}
                        currentStepIsCancel={false}
                        maintenance={{ inMaintenanceMode }}
                      />
                    }
                  />
                  <Route
                    path="/login"
                    element={<Login maintenance={{ inMaintenanceMode }} />}
                  />
                  <Route
                    path="/resetPassword"
                    element={
                      <ResetPassword
                        currentStep={1}
                        currentStepIsCancel={false}
                        maintenance={{ inMaintenanceMode }}
                      />
                    }
                  />
                  <Route path="/access-my-afs-account" element={<AFSLand />} />
                  <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
              </div>
            </div>
          </SessionContextProvider>
        </CacheBusterContextProvider>
      </HelmetProvider>
    </div>
  );
};

export default App;
