/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../../../context/sessionContext";
import i18n from "../../../../localization/i18n";
import * as rk from "../../../../localization/resourceKeys";
import styles from "./languageToggle.module.scss";


const LanguageToggle = () => {
  const { sessionDetails, setSessionDetails } = useContext(SessionContext);
  const language = sessionDetails?.language?.toLowerCase();

  const changeLanguage = (lang: string) => {
    sessionStorage.setItem("lang", lang);
    setSessionDetails({...sessionDetails, language:lang})
    i18n.changeLanguage(lang.replace("-", ""));
  };

  return (
    <div className={`c-footer ${styles["footer-vw"]}`}>
      <>
        <span className={`u-float-right ${styles["language-toggle"]}`}>
          <i className="c-icon  c-icon--[globe] c-icon--xxsmall u-pr-xsmall" />
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={language?.toLowerCase() === "en-ca" ? styles["active"] : styles["inactive"]}
            rel="noopener noreferrer"
            data-testid="en-CA-language-toggle"
            onClick={() => changeLanguage("en-CA")}
          >
            {i18n.t(rk.FOOTER_EN)}
          </a>
          <span className="separator u-pr-xsmall u-pl-xsmall">|</span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            className={language?.toLowerCase() === "fr-ca" ? styles["active"] : styles["inactive"]}
            rel="noopener noreferrer"
            data-testid="fr-CA-language-toggle"
            onClick={() => changeLanguage("fr-CA")}
          >
            {i18n.t(rk.FOOTER_FR)}
          </a>
        </span>
      </>
    </div>
  );
};

export default LanguageToggle;
