/* eslint-disable */
// eslint-disable-next-line react/no-unknown-property
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { func, string } from "prop-types";
import FormField from "../common/form-field/formField";
import Button from "../common/button/button";
import BannerNotification from "../common/banner/bannerNotification";
import i18n from "../../../localization/i18n";
import Loader from "../common/loader/loader";
import * as rk from "../../../localization/resourceKeys";
import Logger from "../../../logger";
import { SessionContext } from "../../../context/sessionContext";
import {
  LAUNCH_PORTAL_EVENT,
  START_2FA_FLOW_EVENT,
} from "../../../constants/BusinessSplunkEvent";
import styles from "./loginForm.module.scss";
import login from "../../services/login";

const LoginForm = ({
  setStep,
  setEmail,
  setPartyId,
  userFingerprint,
  setUserAuthInfo,
}) => {
  const {
    register,
    errors,
    handleSubmit,
    clearErrors,
    setError,
    setValue,
    getValues,
  } = useForm({});
  const { sessionDetails } = useContext(SessionContext);
  const [submitPending, setSubmitPending] = useState(false);
  const [badCount, setBadCount] = useState();
  const [accountLocked, setAccountLocked] = useState(false);
  const [noEmailAdressError, setNoEmailAdressError] = useState(false);

  const brand = sessionDetails?.brand?.toLowerCase();
  const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
  const country = sessionDetails?.country?.toLowerCase();
  const isCanada = country === "canada";
  const isCanadaVW = brand === "vw" && country === "canada";
  const isEmailAndAuthEnabled =
    process.env.REACT_APP_ENABLE_EMAIL_AND_AUTH === "true" &&
    sessionDetails?.country?.toLowerCase() === "usa" &&
    sessionDetails?.brand?.toLowerCase() === "vw";

  const navigate = useNavigate();

  useEffect(() => {
    const isAccountLocked = sessionStorage.getItem("isAccountLocked");
    if (isAccountLocked === "true") {
      setAccountLocked(true);
    }
  }, [accountLocked]);

  const onSubmit = async (payload) => {
    sessionStorage.setItem("isAccountLocked", false);
    Logger.info(
      "Attempt to Login",
      "Location ==> /login",
      sessionDetails,
      "",
      "",
      payload.username
    );
    setSubmitPending(true);
    setAccountLocked(false);
    const body = {
      ...payload,
      sessionDetails,
      userFingerprint,
    };
    const { data, error } = await login(body);
    if (data?.inMaintenance) {
      navigate(`/login`);
    }
    if (data?.isSuccess) {
      Logger.info(
        "Successful Login Attempt",
        "Location ==> /login",
        sessionDetails,
        "",
        data?.partyId,
        payload.username
      );
      setSubmitPending(false);
      if (data?.isMfaVerified) {
        const accountNumber = data?.accountNumber;
        const token = data?.token;
        let url = "";
        Logger.info(
          "Authenticate to Dashboard",
          "Location ==> /login",
          sessionDetails
        );
        if (
          sessionDetails?.country?.toLowerCase() === "canada" &&
          sessionDetails?.brand?.toLowerCase() === "audi"
        ) {
          url = process.env.REACT_APP_AFPORTAL_URL;
          if (isEmailAndAuthEnabled)
            Logger.info(
              LAUNCH_PORTAL_EVENT,
              "Location => /login",
              sessionDetails
            );
            window.location.href = `${url}?token=${token}&accountNumber=${accountNumber}&language=${sessionDetails.language}`;
        } else if (
          sessionDetails?.country?.toLowerCase() === "usa" &&
          sessionDetails?.brand?.toLowerCase() === "vw"
        ) {
          url = `${process.env.REACT_APP_VCIPORTAL_URL}?brand=${sessionDetails?.brand}&`;
          if (isEmailAndAuthEnabled)
            Logger.info(
              LAUNCH_PORTAL_EVENT,
              "Location => /login",
              sessionDetails
            );
            window.location.href = `${url}token=${token}&accountNumber=${accountNumber}&language=${sessionDetails.language}`;
        } else if (
          sessionDetails?.country?.toLowerCase() === "canada" &&
          sessionDetails?.brand?.toLowerCase() === "vw"
        ) {
          url = process.env.REACT_APP_VFPORTAL_URL;
          Logger.info(
            LAUNCH_PORTAL_EVENT,
            "Location => enterMFACode Screen",
            sessionDetails
          );
          window.location.href = `${url}?token=${token}&accountNumber=${accountNumber}&language=${sessionDetails.language}`;
        }
      } else {
        Logger.info(
          "Authenticate to MFA",
          "Location ==> /login",
          sessionDetails,
          data?.accountNumber,
          data?.partyId,
          payload.username
        );
        if (isEmailAndAuthEnabled && data?.loginAuthenticatorCode) {
          setUserAuthInfo((prevState) => ({
            ...prevState,
            loginAuthenticatorCode: data?.loginAuthenticatorCode,
          }));
        }
        setEmail(data?.email);
        setPartyId(data?.partyId);
        setStep(i18n.t(rk.ACCOUNT_VERIFICATION_FLOW));
        if (isEmailAndAuthEnabled)
          Logger.info(
            START_2FA_FLOW_EVENT,
            "Location => /login",
            sessionDetails
          );
      }
      return;
    }
    if (data?.errors?.ErrorCode === "badRequest_accountLocked") {
      Logger.info(
        "Account Locked on Login",
        "Location ==> /login",
        sessionDetails,
        "",
        "",
        payload.username
      );
      setSubmitPending(false);
      setAccountLocked(true);
      return;
    }
    if (data?.errors?.ErrorCode === "badRequest_noEmailAddress") {
      Logger.info(
        "CustomerLogin API badRequest_noEmailAddress",
        "Location ==> /login",
        sessionDetails,
        "",
        "",
        payload.username
      );
      setSubmitPending(false);
      setNoEmailAdressError(true);
      return;
    }
    if (data?.errors) {
      Logger.info(
        "CustomerLogin API returns error",
        "Location ==> /login",
        data?.errors,
        sessionDetails,
        "",
        "",
        payload.username
      );
    }
    if (data?.loginAttemptCount) {
      Logger.info(
        `Unsuccessful Login Attempt Count - ${data.loginAttemptCount}`,
        "Location ==> /login",
        sessionDetails,
        "",
        "",
        payload.username
      );
      setSubmitPending(false);
      setBadCount(data.loginAttemptCount);
    }
    if (data?.error) {
      Logger.error(
        "CustomerLogin API Error",
        "Location ==> /login",
        error,
        sessionDetails,
        "",
        "",
        payload.username
      );
    }
    if (error) {
      Logger.error(
        "CustomerLogin API Error",
        "Location ==> /login",
        error,
        sessionDetails,
        "",
        "",
        payload.username
      );
    }
    setSubmitPending(false);
    setError("username", { type: "string" });
    setError("password", { type: "string" });
  };
  return (
    <div className={`o-page-wrap ${isAudi && "u-pr@s u-pr-none"}`}>
      <Loader isLoading={submitPending} />
      {badCount >= 2 && !accountLocked && (
        <div className="banner-notification">
          <BannerNotification
            title={`${i18n.t(rk.TITLE_LOCKOUT_WARNING1)} ${5 - badCount}
             ${badCount === 4 ? i18n.t(rk.TITLE_LOCKOUT_WARNING3) : i18n.t(rk.TITLE_LOCKOUT_WARNING2)}`}
            notificationStyle="warning"
          >
            {i18n.t(rk.TEXT_LOCKOUT_WARNING)}
          </BannerNotification>
        </div>
      )}
      {noEmailAdressError === true && (
        <div className="banner-notification">
          <BannerNotification notificationStyle="error">
            {isAudi
              ? i18n.t(rk.ERROR_API_NO_EMAIL_ADRESS_AUDI)
              : i18n.t(rk.ERROR_API_NO_EMAIL_ADRESS_VW)}
          </BannerNotification>
        </div>
      )}
      {accountLocked && (
        <div className="banner-notification">
          <BannerNotification notificationStyle="error">
            {isAudi
              ? i18n.t(rk.TEXT_LOCKOUT_ERROR_AUDI)
              : i18n.t(rk.TEXT_LOCKOUT_ERROR)}
          </BannerNotification>
        </div>
      )}
      <form data-testid="form-container">
        <FormField
          id="username"
          label={i18n.t(rk.LABEL_USERNAME)}
          type="text"
          testid="username-field"
          onChange={(e) => {
            setValue("username", e.target.value);
            clearErrors("username");
            setAccountLocked(false);
            sessionStorage.setItem("isAccountLocked", false);
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onBlur={() => {
            if (errors.username) {
              Logger.info(
                `User entered invalid username on login screen ==> ${getValues().username}`,
                "Location ==> /Login",
                sessionDetails,
                "",
                "",
                ""
              );
            }
          }}
          autoCompleteOff
          value={getValues().username}
          fieldRef={register}
          fieldErrors={errors.username && i18n.t(rk.ERROR_API_INVALID_USERNAME)}
          labelClassName={isCanadaVW ? styles["label-username-vw"] : ""}
        />
        <FormField
          id="password"
          label={i18n.t(rk.LABEL_PASSWORD)}
          type="password"
          testid="password-field"
          onChange={(e) => {
            setValue("password", e.target.value);
            clearErrors("password");
            setAccountLocked(false);
            sessionStorage.setItem("isAccountLocked", false);
          }}
          onCopy={(e) => {
            e.preventDefault();
            return false;
          }}
          onPaste={(e) => {
            e.preventDefault();
            return false;
          }}
          onBlur={() => {
            if (errors.password) {
              Logger.info(
                "User entered invalid password",
                "Location ==> /Login",
                sessionDetails,
                "",
                "",
                ""
              );
            }
          }}
          autoCompleteOff
          value={getValues().password}
          fieldRef={register}
          fieldErrors={errors.password && i18n.t(rk.ERROR_API_INVALID_PASSWORD)}
          labelClassName={isCanadaVW ? styles["label-password-vw"] : ""}
        />
        <div className={isAudi || "btn-container"}>
          <Button
            type="submit"
            value={
              isCanada
                ? i18n.t(rk.BUTTON_SECURE_LOGIN)
                : i18n.t(rk.BUTTON_LOGIN)
            }
            disabled={!getValues().username || !getValues().password}
            onClick={handleSubmit(onSubmit)}
            testid="secure-login-btn"
            customClass="c-btn--full"
          />
        </div>
      </form>
      <style jsx>{`
        .btn-container {
          display: flex;
          justify-content: ${isAudi ? "flex-start" : "center"};
          padding-bottom: 4%;
          padding-top: 5%;
        }
        .banner-notification {
          margin: -15px 0 50px;
        }
      `}</style>
    </div>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  setStep: func,
  setEmail: func,
  setPartyId: func,
  userFingerprint: string,
  setUserAuthInfo: func,
};

LoginForm.defaultProps = {
  setStep: () => {},
  setEmail: () => {},
  setPartyId: () => {},
  userFingerprint: "",
  setUserAuthInfo: () => {},
};
