import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Layout from "../components/common/layout/layout";
import Logger from "../../logger";
import i18n from "../../localization/i18n";
import * as rk from "../../localization/resourceKeys";
import VerifyUser from "../components/resetPassword/verifyUser";
import VerifyChallenge from "../components/resetPassword/verifyChallenge";
import StepFlow from "../components/common/stepflow/stepFlow";
import Loader from "../components/common/loader/loader";
import SetPassword from "../components/resetPassword/setPassword";
import Maintenance from "../components/maintenance";
import { SessionContext } from "../../context/sessionContext";
import styles from "../components/resetPassword/resetPassword.module.scss";

interface MaintenanceProps {
  inMaintenanceMode?: string;
}

interface ResetPasswordProps {
  currentStep: number;
  currentStepIsCancel: boolean;
  maintenance: MaintenanceProps;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  currentStep,
  currentStepIsCancel,
  maintenance,
}) => {
  const { sessionDetails } = useContext(SessionContext);
  const navigate = useNavigate();
  const inMaintenanceMode = maintenance?.inMaintenanceMode;
  const ref = useRef(null);

  const initialState = {
    name: "ResetPasswordFlow",
    currentStep: currentStep || 1,
    previousStep: -1,
    currentStepIsCancel: currentStepIsCancel || true,
    maxSteps: 3,
    submitPending: false,
  };

  // keep track of the state of the flow
  const [state, setState] = useState(initialState);
  const [username, setUsername] = useState<string>("");
  const [ssn, setSSN] = useState<string>("");
  const [dob, setDOB] = useState<string>("");
  const [partyId, setPartyId] = useState<number>(undefined);
  const [challengeQuestion, setChallengeQuestion] = useState<string>("");
  const [challengeAnswer, setChallengeAnswer] = useState();
  const [isContinueEnabled, setIsContinueEnabled] = useState<boolean>(false);
  const isCanada = sessionDetails?.country?.toLowerCase() === "canada";
  const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
  const isVWCanada = sessionDetails?.brand?.toLowerCase() === "vw" && isCanada;

  const brand = sessionDetails?.brand?.toLowerCase();
  const country = sessionDetails?.country?.toLowerCase();

  const renderImage = () => {
    if (brand === "audi" && country === "canada") {
      return "./images/audi_canada/password.jpg";
    }
    if (brand === "vw" && country === "usa") {
      if (state.currentStep === 1) {
        return "./images/vw_usa/password_1.jpg";
      }
      if (state.currentStep === 2) {
        return "./images/vw_usa/password_2.jpg";
      }
      if (state.currentStep === 3) {
        return "./images/vw_usa/password_3.jpg";
      }
      if (state.currentStep === 4) {
        return "./images/vw_usa/password_4.jpg";
      }
    }
    if (brand === "vw" && country === "canada") {
      return "./images/vw_canada/password.png";
    }
  };

  const cancelAndReturnToLogin = (step: number) => {
    Logger.info(
      `Exited Reset Password Flow in Step ${step}`,
      "Location ==> /resetPassword",
      sessionDetails,
    );
    navigate("/login");
  };
  const continueToNextStep = (step: number) => {
    Logger.info(
      `Clicked Continue in Reset Password Flow Step ${step}`,
      "Location ==> /resetPassword",
      sessionDetails,
    );
  };

  useEffect(() => {
    if (state.currentStep !== 2)
      document.getElementsByClassName("page-content")[0]?.scrollTo(0, 0);
  }, [state.currentStep]);

  return inMaintenanceMode === "Y" ? (
    <Maintenance />
  ) : (
    <div
      className={
        state.currentStep === 3
          ? "confirmation-container"
          : "reset-pswd-container"
      }
    >
      <Layout imgSource={renderImage()}>
        <StepFlow
          step={1}
          currentStep={state.currentStep}
          title={<h2>{i18n.t(rk.TITLE_RESET_PASSWORD)}</h2>}
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          previousAction={() => {
            setState({
              ...state,
              currentStep: state.currentStep + 1,
              previousStep: 1,
            });
            continueToNextStep(1);
          }}
          nextLabel={i18n.t(rk.BUTTON_CANCEL)}
          nextAction={() => {
            cancelAndReturnToLogin(1);
          }}
          previousLabelDisabled={!isContinueEnabled}
          canTakePreviousAction={async () => {
            setState({ ...state, submitPending: true });
            const verifyUser = await ref.current.verifyUserAction();
            setState({ ...state, submitPending: false });
            const canTakeAction = verifyUser && isContinueEnabled;
            return canTakeAction;
          }}
        >
          <Loader isLoading={state.submitPending} />
          <VerifyUser
            ref={ref}
            setUsername={(value) => setUsername(value)}
            setSSN={(value) => setSSN(value)}
            setDOB={(value) => setDOB(value)}
            setPartyId={(value) => setPartyId(value)}
            setChallengeQuestion={(value) => setChallengeQuestion(value)}
            setIsContinueEnabled={(value) => setIsContinueEnabled(value)}
          />
        </StepFlow>
        <StepFlow
          step={2}
          currentStep={state.currentStep}
          title={<h2>{i18n.t(rk.TITLE_RESET_PASSWORD)}</h2>}
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          previousAction={() => {
            setState({
              ...state,
              currentStep: state.currentStep + 1,
              previousStep: 1,
            });
            continueToNextStep(2);
          }}
          nextLabel={i18n.t(rk.BUTTON_CANCEL)}
          nextAction={() => {
            cancelAndReturnToLogin(2);
          }}
          canTakePreviousAction={async () => {
            setState({ ...state, submitPending: true });
            const canTakeAction = await ref.current.verifyUserAction();
            setState({ ...state, submitPending: false });
            return canTakeAction;
          }}
          previousLabelDisabled={!isContinueEnabled}
        >
          <Loader isLoading={state.submitPending} />
          <VerifyChallenge
            ref={ref}
            username={username}
            ssn={ssn}
            dob={dob}
            challengeQuestion={challengeQuestion}
            partyId={partyId}
            setChallengeAnswer={(value) => setChallengeAnswer(value)}
            setIsContinueEnabled={(value) => setIsContinueEnabled(value)}
          />
        </StepFlow>
        <StepFlow
          step={3}
          currentStep={state.currentStep}
          title={<h2>{i18n.t(rk.TITLE_RESET_PASSWORD)}</h2>}
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          previousAction={() => {
            setState({
              ...state,
              currentStep: state.currentStep + 1,
              previousStep: 2,
            });
            continueToNextStep(3);
          }}
          nextLabel={i18n.t(rk.BUTTON_CANCEL)}
          nextAction={() => {
            cancelAndReturnToLogin(3);
          }}
          canTakePreviousAction={async () => {
            setState({ ...state, submitPending: true });
            const canTakeAction = await ref.current.verifyUserAction();
            setState({ ...state, submitPending: false });
            return canTakeAction;
          }}
          previousLabelDisabled={!isContinueEnabled}
        >
          <Loader isLoading={state.submitPending} />
          <SetPassword
            ref={ref}
            username={username}
            challengeQuestion={challengeQuestion}
            challengeAnswer={challengeAnswer}
            setIsContinueEnabled={(value) => setIsContinueEnabled(value)}
          />
        </StepFlow>
        <StepFlow
          step={4}
          currentStep={state.currentStep}
          title={<h2>{i18n.t(rk.TITLE_RESET_PASSWORD)}</h2>}
          previousLabel={i18n.t(rk.TITLE_LOGIN)}
          previousAction={() => {
            Logger.info(
              "Clicked login button",
              "Location ==> /resetPassword",
              sessionDetails,
            );
            navigate("/login");
          }}
          previousLabelDisabled={!isContinueEnabled}
        >
          <p className={`subtitle ${isAudi && styles["subtitle-suc"]}`}>
            {isVWCanada
              ? i18n.t(rk.TEXT_RESETPSWD_SUCCESS_VF)
              : i18n.t(rk.TEXT_RESETPSWD_SUCCESS)}
          </p>
        </StepFlow>
        <style>{`
          .confirmation-container .subtitle {
            font-size: 16px;
            margin-bottom: 10px;
          }
          
        `}</style>
      </Layout>
    </div>
  );
};
export async function getServerSideProps() {
  const host = process.env.VWC_URL;
  return fetch(`${host}/maintenance.json`)
    .then((response) => {
      return response ? response.json() : { inMaintenanceMode: "N" }.json();
    })
    .then((res) => {
      const mode = res.inMaintenanceMode;
      const maintenance = { inMaintenanceMode: `${mode}` };
      return { props: { maintenance } };
    })
    .catch((err) => {
      Logger.error(
        "Reset Passoword Flow => getServerSideProps",
        `Location ==> /resetPassword`,
        `${err}`,
      );
      const maintenance = { inMaintenanceMode: "N" };
      return { props: { maintenance } };
    });
}

export default ResetPassword;

ResetPassword.propTypes = {
  currentStep: PropTypes.number,
  currentStepIsCancel: PropTypes.bool,
  maintenance: PropTypes.shape({
    inMaintenanceMode: PropTypes.string,
  }),
};

ResetPassword.defaultProps = {
  currentStep: undefined,
  currentStepIsCancel: undefined,
  maintenance: {
    inMaintenanceMode: "",
  },
};
