import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}
interface VerifyCustomerPayload {
  accountNumber: string;
  ssn: string;
  dob: string;
  vin: string;
  sessionDetails: SessionDetails;
}

const gqlRequest = gql`
  query VerifyCustomerRegistration(
    $Request: VerifyCustomerRegistrationRequest!
  ) {
    verifyCustomerRegistration(Request: $Request) {
      IsSuccess
      PartyId
      IsPartyIdRegistered
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

const verifyCustomerRegistration = async (payload: VerifyCustomerPayload) => {
  const { accountNumber, ssn, dob, vin, sessionDetails } = payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request = {
      AccountNumber: accountNumber,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      SourceApp: sessionDetails.source,
      ParentFunctionName: "Verify Customer Registration",
      ProcessName: "Verify Customer Registration",
      WidgetName: sessionDetails.source,
      DoB: "",
      VINLast6: "",
      UniqueIdentifier: "",
    };
    if (sessionDetails?.country?.toLowerCase() === "canada") {
      request.DoB = dob;
      request.VINLast6 = vin;
    } else {
      request.UniqueIdentifier = ssn;
    }

    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (response?.data?.data?.verifyCustomerRegistration?.IsSuccess) {
      if (response?.data?.data?.verifyCustomerRegistration?.PartyId) {
        return {
          data: {
            isSuccess: true,
            partyId: response?.data?.data?.verifyCustomerRegistration?.PartyId,
            isPartyIdRegistered:
              response?.data?.data?.verifyCustomerRegistration
                ?.IsPartyIdRegistered,
          },
        };
      }
      return {
        data: {
          isSuccess: true,
          partyId: null,
          isPartyIdRegistered: false,
        },
      };
    }
    if (
      !response?.data?.data?.verifyCustomerRegistration?.IsSuccess ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: false,
          errors:
            response?.data?.data?.verifyCustomerRegistration?.Errors?.[0] ||
            response?.data?.errors ||
            "",
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};

export default verifyCustomerRegistration;
