import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { func, number, string } from "prop-types";
import FormField from "../common/form-field/formField";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import Logger from "../../../logger";
import BannerNotification from "../common/banner/bannerNotification";
import { SessionContext } from "../../../context/sessionContext";
import styles from "./resetPassword.module.scss";
import { resetPswdChallenge } from "../../services/resetPswdChallenge";

interface VerifyChallengeProps {
  username: string;
  ssn: string;
  dob: string;
  challengeQuestion: string;
  partyId: number;
  setChallengeAnswer: (challengeAnswer: string) => void;
  setIsContinueEnabled: (value: boolean) => void;
}

const VerifyChallenge = forwardRef<HTMLElement, VerifyChallengeProps>(
  (
    {
      username,
      ssn,
      dob,
      challengeQuestion,
      partyId,
      setChallengeAnswer,
      setIsContinueEnabled,
    },
    ref,
  ) => {
    const {
      register,
      errors,
      clearErrors,
      setError,
      setValue,
      getValues,
      trigger,
    } = useForm({});
    const { sessionDetails } = useContext(SessionContext);
    const [challengeAnswer, setAnswer] = useState("");
    const [answerError, setAnswerError] = useState("");
    const [errorState, setErrorState] = useState(false);
    const [errorMsg, setErrorMsg] = useState<boolean | string>(false);
    const [badCount, setBadCount] = useState<number>();
    const navigate = useNavigate();
    const isCanada = sessionDetails?.country?.toLowerCase() === "canada";
    const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
    const isVWCanada =
      sessionDetails?.brand?.toLowerCase() === "vw" && isCanada;
    const isVW = sessionDetails?.brand?.toLowerCase() === "vw" && !isCanada;
    const [noMatchError, setNoMatchError] = useState<boolean | string>("");
    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              "Exited Reset Password Flow in Step 2 by clicking back button on the browser tab",
              "Location ==> /resetPassword",
              sessionDetails,
            );
          }
        },
        false,
      );
      window.addEventListener("beforeunload", () => {
        Logger.info(
          "Exited Reset Password Flow in Step 2 by closing the browser tab",
          "Location ==> /resetPassword",
          sessionDetails,
        );
      });
      // When the user change the language, triggers the existing error.
      for (const error in errors) {
        trigger(error);
      }
    }, [sessionDetails]);

    async function onSubmit() {
      const body = {
        partyId,
        challengeQuestion,
        challengeAnswer,
        sessionDetails,
      };

      const { data, error } = await resetPswdChallenge(body);
      if (data?.inMaintenance) {
        navigate("/login");
      }
      if (data?.isSuccess) {
        setChallengeAnswer(challengeAnswer);
        Logger.info(
          "User passed challenge Q/A in Reset password",
          "Location ==> /resetPassword",
          sessionDetails,
          "",
          partyId,
          username,
        );
        return true;
      }
      if (data?.errors) {
        if (data?.errors?.ErrorCode === "badRequest_accountLocked") {
          // @ts-expect-error We store the account lock status in sessionStorage as a boolean
          sessionStorage.setItem("isAccountLocked", true);
          navigate("/login");
          Logger.info(
            "Account Locked in Reset Password",
            "Location ==> /resetPassword",
            sessionDetails,
            "",
            partyId,
            username,
          );
        }
        return false;
      }
      if (data?.resetCount === 1) {
        setAnswerError(i18n.t(rk.ERROR_INVALID_INFO));
        setNoMatchError(true);
        return false;
      }
      if (data?.resetCount > 1) {
        setBadCount(data?.resetCount);
        setAnswerError(i18n.t(rk.ERROR_INVALID_INFO));
        setNoMatchError(true);
        Logger.info(
          `User failed to pass Challenge Q/A in reset password. Attempt Count - ${data?.resetCount}`,
          "Location ==> /resetPassword",
          sessionDetails,
          "",
          partyId,
          username,
        );
        return false;
      }
      if (data?.errors) {
        Logger.error(
          "VerifyChallengeCustomerForgotPassword API Error",
          "Location ==> /resetPassword",
          data?.errors,
          sessionDetails,
          "",
          partyId,
          username,
        );
      }
      setErrorState(true);
      setErrorMsg(i18n.t(rk.ERROR_API_SERVICE_DOWN));
      Logger.error(
        "VerifyChallengeCustomerForgotPassword API Error",
        "Location ==> /resetPassword",
        error || data?.error,
        sessionDetails,
        "",
        partyId,
        username,
      );
      return false;
    }

    async function verifyUserAction() {
      setNoMatchError(false);
      clearErrors("challengeAnswer");
      clearErrors("challengeAnswerEmpty");
      setErrorState(false);
      // @ts-expect-error badCount is a string only when it is set to empty string
      setBadCount("");
      if (challengeAnswer === "") {
        setError("challengeAnswerEmpty", { type: "string" });
        setIsContinueEnabled(false);
        return false;
      }
      const isSuccess = await onSubmit();
      return isSuccess;
    }
    // @ts-expect-error verifyUserAction is a function
    useImperativeHandle(ref, () => ({ verifyUserAction }));
    useEffect(() => {
      setIsContinueEnabled(challengeAnswer.length > 0 && !noMatchError);
    }, [challengeAnswer, noMatchError, setIsContinueEnabled]);
    return (
      <div
        className={
          styles[
            isAudi ? "audi-verify-user-container" : "verify-user-container"
          ]
        }
      >
        <p className={styles.subtitle}>
          {i18n.t(rk.TEXT_RESETPSWD_VERIFY_CHALLENGE)}
        </p>
        <p className={styles["fields-required-text"]}>
          {i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}
        </p>
        {badCount >= 2 && (
          <div className={styles.bannerNotification}>
            <BannerNotification
              title={`${i18n.t(rk.TITLE_LOCKOUT_WARNING1)} ${5 - badCount}
             ${badCount === 4 ? i18n.t(rk.TITLE_LOCKOUT_WARNING3) : i18n.t(rk.TITLE_LOCKOUT_WARNING2)}`}
              notificationStyle="warning"
            >
              {i18n.t(rk.TEXT_LOCKOUT_WARNING_VERIFY)}
            </BannerNotification>
          </div>
        )}
        {errorState && (
          <div className={styles.bannerNotification}>
            <BannerNotification notificationStyle="error">
              {
                // @ts-expect-error errorMsg is a boolean or string
                i18n.t(errorMsg)
              }
            </BannerNotification>
          </div>
        )}
        <div className={styles["form-fields"]}>
          <FormField
            id="username"
            label={i18n.t(rk.LABEL_USERNAME)}
            type="text"
            testid="username-field"
            value={username}
            disabled
            wrapperClassName={isAudi && styles["form-field-custom"]}
          />
          {isVW && (
            <FormField
              id="ssn"
              label={i18n.t(rk.LABEL_SSN_LAST4)}
              type="text"
              testid="ssn-field"
              value={ssn}
              disabled
            />
          )}
          {(isAudi || isVWCanada) && (
            <FormField
              id="dob"
              label={i18n.t(rk.LABEL_DOB)}
              type="text"
              testid="dob-field"
              value={dob}
              disabled
              wrapperClassName={isAudi && styles["form-field-custom"]}
            />
          )}
          <FormField
            id="challengeAnswer"
            label={i18n.t(challengeQuestion)}
            type="text"
            testid="challenge-answer-field"
            onChange={(e) => {
              setValue("challengeAnswer", e.target.value);
              setAnswer(e.target.value);
              clearErrors("challengeAnswer");
              clearErrors("challengeAnswerEmpty");
              setNoMatchError(false);
              setAnswerError("");
            }}
            autoCompleteOff
            onBlur={() => {
              trigger("challengeAnswer");
              setIsContinueEnabled(!errors.challengeAnswer);
            }}
            value={getValues().challengeAnswer}
            hint={i18n.t(rk.TEXT_SECURITY_QUESTION_ANSWER_HINT)}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_CHALLENGE_ANSWER),
              validate: {
                validateAccountSecurityQuestionAnswer: () =>
                  challengeAnswer !== "" ||
                  i18n.t(rk.ERROR_EMPTY_CHALLENGE_ANSWER),
              },
            })}
            fieldErrors={errors.challengeAnswer?.message || answerError}
          />
        </div>
        <style>{`
        @media (min-width: 720px) {
          .form-fields {
            padding: 0px 30px;
          }
        }
        .fields-required-text {
          margin: -20px 0px 50px 0px;
          font-size: 14px;
        }
        .subtitle {
          font-size: 16px;
        }
        .bannerNotification{
          margin: -15px 0 50px;
        }
      `}</style>
      </div>
    );
  },
);

export default VerifyChallenge;

VerifyChallenge.propTypes = {
  username: string,
  ssn: string,
  dob: string,
  challengeQuestion: string,
  partyId: number,
  setChallengeAnswer: func,
  setIsContinueEnabled: func,
};

VerifyChallenge.defaultProps = {
  username: "",
  ssn: "",
  dob: "",
  challengeQuestion: "",
  partyId: 0,
  setChallengeAnswer: () => {},
  setIsContinueEnabled: () => {},
};
