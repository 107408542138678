import { deviceDetect } from "react-device-detect";
import { VciLogger } from "@customerapi/vci-logging-lib";

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}
const appName = "AUTH-PORTAL-FRONTEND";

// TODO: Add Details in the env files, delete hardcode
const splunkDetails = {
  SplunkToken: process.env.REACT_APP_SPLUNK_TOKEN
    ? process.env.REACT_APP_SPLUNK_TOKEN
    : "909e2756-ff75-48fd-a9ea-88f4c963ff82",
  SplunkUrl: process.env.REACT_APP_SPLUNK_URL
    ? process.env.REACT_APP_SPLUNK_URL
    : "https://hec.vwcredit.com",
};

export default class Logger {
  static info(
    actionName?: string,
    screenName?: string,
    sessionDetails?: SessionDetails,
    accountNo?: string,
    partyid?: number | string,
    userName?: string,
  ) {
    const accountInfo = {
      accountNumber: accountNo,
      partyId: partyid,
      brand: sessionDetails?.brand,
      country: sessionDetails?.country,
      username: userName,
      hosturl: `${window.location.origin}`,
      correlationId: sessionDetails?.correlationId,
      dateTime: new Date(),
      sourceApp: sessionDetails?.source,
      deviceInfo: deviceDetect(undefined),
    };
    VciLogger.SplunkLogInfo(
      appName,
      actionName,
      screenName,
      accountInfo,
      splunkDetails,
    );
  }

  static error(
    actionName?: string,
    screenName?: string,
    error?: string,
    sessionDetails?: SessionDetails,
    accountNo?: string,
    partyid?: number | string,
    userName?: string,
  ) {
    const accountInfo = {
      correlationId: sessionDetails?.correlationId,
      accountNumber: accountNo,
      partyId: partyid,
      country: sessionDetails?.country,
      brand: sessionDetails?.brand,
      sourceApp: sessionDetails?.source,
      username: userName,
    };
    VciLogger.SplunkLogError(
      appName,
      actionName,
      screenName,
      error,
      accountInfo,
      splunkDetails,
    );
  }

  static warn(actionName?: string, params?: string) {
    VciLogger.SplunkLogWarning(appName, actionName, params, splunkDetails);
  }

  static fatal(actionName?: string, params?: string) {
    VciLogger.SplunkLogFatal(appName, actionName, params, splunkDetails);
  }
}
