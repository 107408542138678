/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable */
/* eslint-disable @typescript-eslint/no-explicit-any, react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars, radix, consistent-return, jsx-a11y/label-has-associated-control */
import React, {
  ChangeEvent,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
  useEffect,
  MutableRefObject,
} from "react";
import { useNavigate } from "react-router-dom";
import * as validate from "@ownportal/customer-validations";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { func } from "prop-types";
import moment from "moment";
import FormField from "../common/form-field/formField";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import Logger from "../../../logger";
import { SessionContext } from "../../../context/sessionContext";
import BannerNotification from "../common/banner/bannerNotification";
import {
  handleOnChangeDay,
  handleOnChangeMonth,
  handleOnKeyPressYear,
  handleSpecialKeysDate,
  maskSSN,
  restrictKeyPressHandler,
} from "../../../utilities";
import styles from "./verify.module.scss";
import DoB from "../common/dob/dob";
import { retrieveUsernameVerify } from "../../services/retrieveUsernameVerify";

interface VerifyProps {
  setSSN: (value: any) => void;
  setAccountNumber: (value: any) => void;
  setChallengeQ: (value: any) => void;
  setZipcode: (value: any) => void;
  setDateOfBirth: (value: any) => void;
  setPartyId: (value: any) => void;
  setIsContinueEnabled: (value: any) => void;
}

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface PayloadProps {
  ssn?: string;
  zipCode: string;
  dateOfBirth?: string;
  accountNumber?: string;
  sessionDetails: SessionDetails;
}

const Verify = forwardRef(
  (
    {
      setSSN,
      setAccountNumber,
      setChallengeQ,
      setZipcode,
      setDateOfBirth,
      setPartyId,
      setIsContinueEnabled,
    }: VerifyProps,
    ref: MutableRefObject<any>,
  ) => {
    const { register, errors, trigger, clearErrors } = useForm();
    const { sessionDetails } = useContext(SessionContext);
    const [ssn, setSsn] = useState("");
    const [maskedSSN, setMaskedSSn] = useState("");
    const [zipCode, setZip] = useState("");
    const [accountNumber, setAccNum] = useState("");
    const [accountNumberError, setAccountNumberError] = useState("");
    const [errorZipMsg, setZipErrorMsg] = useState("");
    const [errorSSNMsg, setSSNErrorMsg] = useState("");
    const [errorDateOfBirth, setErrorDateOfBirth] = useState("");
    const [deleteSSN, setDeleteSSN] = useState(false);
    const [apiError, setApiError] = useState(false);
    const [noMatchError, setNoMatchError] = useState(false);
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [year, setYear] = useState("");
    const [shouldGoToNext, setShouldGoToNext] = useState({
      " day ": false,
      " month ": false,
      " year ": false,
    });
    const country = sessionDetails?.country?.toLowerCase();
    const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
    const isVW =
      sessionDetails?.brand?.toLowerCase() === "vw" && country === "usa";
    const isVWCanada =
      sessionDetails?.brand?.toLowerCase() === "vw" && country === "canada";
    const navigate = useNavigate();

    const instructionText = () => {
      if (isAudi) {
        return i18n.t(rk.TEXT_RETRIEVE_USERNAME_VERIFY_AUDI);
      }
      if (isVWCanada) {
        return i18n.t(rk.TEXT_RETRIEVE_USERNAME_VERIFY_VWCAN);
      }
      return i18n.t(rk.TEXT_RETRIEVE_USERNAME_VERIFY);
    };

    const zipCodeErrorMsg = i18n.t(
      isAudi || isVWCanada ? rk.ERROR_ZIP_FORMAT : rk.ERROR_POSTAL_FORMAT,
    );
    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              "Exited Retrieve Username Flow in Step 1 by clicking back button on the browser tab",
              "Location ==> /retrieveUsername",
              sessionDetails,
            );
          }
        },
        false,
      );
      window.addEventListener("beforeunload", (e) => {
        Logger.info(
          "Exited Retrieve Username Flow in Step 1 by closing the browser tab",
          "Location ==> /retrieveUsername",
          sessionDetails,
        );
      });
      // When the user change the language, triggers the existing error.
      for (const error in errors) {
        trigger(error);
      }

      if (errorDateOfBirth) {
        isValidDateOfBirth();
      }
    }, [sessionDetails]);
    async function onSubmit() {
      clearErrors("zipCode");
      if (isVW) {
        clearErrors("ssn");
        clearErrors("zipCode");
      } else if (isAudi) {
        clearErrors("dateOfBirth");
        clearErrors("accountNumber");
      }
      const dateOfBirth = `${month}/${day}/${year}`;
      const body: PayloadProps =
        isAudi || isVWCanada
          ? { accountNumber, dateOfBirth, zipCode, sessionDetails }
          : { ssn, zipCode, sessionDetails };

      const { data, error } = await retrieveUsernameVerify(body);
      if (data?.inMaintenance) {
        navigate(`/login`);
      }
      if (data?.isSuccess) {
        Logger.info(
          "User is found in the system on RetrieveUsernameVerify",
          "Location ==> /retrieveUsername",
          sessionDetails,
          accountNumber,
          data?.partyId,
        );
        setZipcode(zipCode);
        if (isVW) {
          setSSN(maskedSSN);
        } else if (isAudi || isVWCanada) {
          setDateOfBirth(dateOfBirth);
          setAccountNumber(accountNumber);
        }
        setChallengeQ(data.challengeQuestion);
        setPartyId(data.partyId);
        return true;
      }
      if (data?.errors?.ErrorCode === "badRequest_NoMatch") {
        setSSNErrorMsg(i18n.t(rk.ERROR_INVALID_INFO));
        setZipErrorMsg(i18n.t(rk.ERROR_INVALID_INFO));
        setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_INFO));
        setAccountNumberError(i18n.t(rk.ERROR_INVALID_INFO));
        setNoMatchError(true);
        Logger.info(
          "User is not found in the system on RetrieveUsernameVerify",
          "Location ==> /retrieveUsername",
          sessionDetails,
          accountNumber,
        );
        return false;
      }
      Logger.error(
        "ForgotUserNameVerify API Error",
        "Location ==> /retrieveUsername",
        data?.error || error || data,
        sessionDetails,
        accountNumber,
      );
      setApiError(true);
      return false;
    }
    const isValidDate = () => {
      const date = `${month}/${day}/${year}`;
      const parsedDate = new Date(date);
      return (
        parsedDate <= new Date() &&
        parseInt(parsedDate.getMonth()) === parseInt(month) - 1
      );
    };

    async function verifyUserAction() {
      if (!isEmpty(errors)) {
        setIsContinueEnabled(false);
        return false;
      }
      setApiError(false);
      setZipErrorMsg("");
      setNoMatchError(false);
      if (isVW) {
        setSSNErrorMsg("");
      }
      if (isAudi) {
        setErrorDateOfBirth("");
        if (!isValidDate()) {
          setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
          return false;
        }
      }
      const isSuccess = await onSubmit();
      return isSuccess;
    }

    const isValidDateOfBirth = () => {
      setErrorDateOfBirth("");
      if (!day || !month || !year) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_EMPTY_DOB));
        setIsContinueEnabled(false);
      } else if (day.length !== 2 || month.length !== 2 || year.length !== 4) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
        setIsContinueEnabled(false);
        Logger.info(
          `User entered invalid DoB ==> ${month}/${day}/${year}`,
          "Location ==> /retrieveUsername",
          sessionDetails,
          "",
          undefined,
          "",
        );
      } else if (
        new Date(`${month}/${day}/${year}`) > new Date() ||
        !moment(`${year}-${month}-${day}`, "YYYY-MM-DD").isValid()
      ) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
        setIsContinueEnabled(false);
        Logger.info(
          `User entered invalid DoB ==> ${month}/${day}/${year}`,
          "Location ==> /retrieveUsername",
          sessionDetails,
          "",
          undefined,
          "",
        );
      }
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Backspace" || event.key === "Delete") {
        setDeleteSSN(true);
        return;
      }
      setDeleteSSN(false);
    };
    const handleOnChangeZipCode = (event: ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target;
      clearErrors("zipCode");
      if (isAudi) {
        if (value.length > 7) return event.preventDefault();
        if (value.length > 3) {
          const formatted = value.split(" ").join("");
          value = `${formatted.slice(0, 3)} ${formatted.slice(3)}`;
        }
        setZip(value.toUpperCase());
      } else {
        setZip(value);
      }
    };
    const handleSSNChange = (event: ChangeEvent<HTMLInputElement>) => {
      setSSNErrorMsg("");
      const newValue = event.target.value;
      const newValueFormat = newValue.split("-").join("");
      const lastChar = newValueFormat[newValueFormat.length - 1];
      if (!deleteSSN) {
        if (newValueFormat.length === 1) {
          if (!/[0-9]/i.test(newValueFormat)) {
            return;
          }
        }
        if (newValueFormat.length > 1) {
          if (!/[0-9]/i.test(lastChar)) {
            return;
          }
        }
      }
      const ssnValue = maskSSN(newValue, ssn, maskedSSN);
      if (ssnValue) {
        setSsn(ssnValue.actualValue);
        setMaskedSSn(ssnValue.maskedValue);
      }
      clearErrors("ssn");
    };
    const restrictAccountKeyPress = restrictKeyPressHandler(
      /^[0-9]*$/i,
      undefined,
    );
    const restrictAccountKeyPressPostalCode = restrictKeyPressHandler(
      /^[a-zA-Z0-9 ]*$/i,
      undefined,
    );
    useImperativeHandle(ref, () => ({ verifyUserAction }));
    useEffect(() => {
      const isEmptyErrors = isEmpty(errors);
      if (isAudi) {
        setIsContinueEnabled(
          day !== "" &&
            month !== "" &&
            year !== "" &&
            !errorDateOfBirth &&
            zipCode !== "" &&
            accountNumber !== "" &&
            isEmptyErrors &&
            !noMatchError,
        );
      }
      if (isVW) {
        setIsContinueEnabled(
          zipCode !== "" && ssn !== "" && !noMatchError && isEmptyErrors,
        );
      }
    }, [
      isAudi,
      isVW,
      day,
      month,
      year,
      zipCode,
      ssn,
      accountNumber,
      setIsContinueEnabled,
      noMatchError,
      errors,
      errorDateOfBirth,
      country,
      isVWCanada,
    ]);
    return (
      <div
        className={`o-page-wrap new-page-wrap ${isVWCanada && styles["verify-form-wrapper"]}`}
      >
        <p
          className={`retrieve-username__infotext ${isAudi && styles["info-text-audi"]}`}
        >
          {instructionText()}
          <span
            className={`retrieve-username__fieldsrequired ${isAudi && styles["all-fields-required-audi"]}`}
          >
            {i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}
          </span>
        </p>
        {apiError && (
          <div className="bannerNotification">
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.ERROR_API_SERVICE_DOWN)}
            </BannerNotification>
          </div>
        )}
        <form
          className={`o-page-wrap  form-page-wrap ${isAudi && styles["form-page-audi"]} ${isVWCanada && styles["form-page-vwfs"]}`}
        >
          {isVW && (
            <FormField
              id="ssn"
              data-testid="test-ssn"
              label={i18n.t(rk.LABEL_SSN)}
              type="text"
              onChange={(e) => {
                handleSSNChange(e);
                clearErrors("ssn");
                if (noMatchError) {
                  setZipErrorMsg("");
                  setErrorDateOfBirth("");
                  setAccountNumberError("");
                }
                setNoMatchError(false);
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              autoCompleteOff
              onKeyDown={handleKeyDown}
              onBlur={async () => {
                await trigger("ssn");
                await setIsContinueEnabled(!errors.ssn?.message);
                if (errors?.ssn?.message) {
                  Logger.info(
                    "User entered invalid ssn",
                    "Location ==> /retrieveUsername",
                    sessionDetails,
                    "",
                    undefined,
                    "",
                  );
                }
              }}
              value={maskedSSN}
              hint={i18n.t(rk.TEXT_SSN_HINT)}
              fieldRef={register({
                required: i18n.t(rk.ERROR_EMPTY_SSN),
                validate: {
                  validateSSN: () =>
                    validate.isValidSSNOrTIN(ssn) ||
                    i18n.t(rk.ERROR_SSN_FORMAT),
                },
              })}
              fieldErrors={errors?.ssn?.message || errorSSNMsg}
            />
          )}
          {isAudi && (
            <FormField
              id="accountNumber"
              label={i18n.t(rk.LABEL_ACCOUNT_NUMBER)}
              type="text"
              testid="test-account-number-field"
              fieldRef={register({
                required: i18n.t(rk.ERROR_EMPTY_ACCOUNT_NUMBER),
                validate: {
                  validateAccountNumber: (value) =>
                    validate.isValidAccountNumber(value) ||
                    i18n.t(rk.ERROR_INVALID_ACCNUMBER),
                },
              })}
              autoCompleteOff
              hint={i18n.t(rk.TEXT_ACCOUNT_NUM_HINT)}
              onBlur={async () => {
                await trigger("accountNumber");
                await setIsContinueEnabled(!errors?.accountNumber?.message);
                if (errors?.accountNumber?.message) {
                  Logger.info(
                    `User entered invalid accountNumber ==> ${accountNumber}`,
                    "Location ==> /retrieveUsername",
                    sessionDetails,
                    "",
                    undefined,
                    "",
                  );
                }
              }}
              value={accountNumber}
              onChange={(e) => {
                setAccountNumberError("");
                clearErrors("accountNumber");
                if (noMatchError) {
                  setZipErrorMsg("");
                  setErrorDateOfBirth("");
                  setSSNErrorMsg("");
                }
                setAccNum(e.target.value);
                setNoMatchError(false);
              }}
              onKeyPress={restrictAccountKeyPress}
              fieldErrors={errors?.accountNumber?.message || accountNumberError}
              wrapperClassName="u-mb"
            />
          )}
          {isAudi && (
            <div>
              <div>
                <label
                  id={styles["audi-date-of-birth-label"]}
                  className={
                    !isEmpty(errors?.dateOfBirth?.message || errorDateOfBirth)
                      ? styles["is-error-audi-label"]
                      : undefined
                  }
                >
                  {i18n.t(rk.LABEL_DOB)}
                </label>
              </div>
              <div
                id={styles["audi-date-field-wrapper"]}
                className={
                  !isEmpty(errors?.dateOfBirth?.message || errorDateOfBirth)
                    ? styles["is-error-audi"]
                    : undefined
                }
              >
                <input
                  type="text"
                  id="month-input"
                  aria-label="month-input"
                  data-testid="test-month-dob"
                  onKeyUp={(e) =>
                    handleSpecialKeysDate(
                      e,
                      "month",
                      month,
                      day,
                      year,
                      shouldGoToNext,
                      setShouldGoToNext,
                    )
                  }
                  value={month}
                  onChange={(e) => {
                    handleOnChangeMonth(e, month, setMonth);
                    setErrorDateOfBirth("");
                    if (noMatchError) {
                      setZipErrorMsg("");
                      setAccountNumberError("");
                      setSSNErrorMsg("");
                    }
                    setNoMatchError(false);
                  }}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  maxLength={2}
                  autoComplete="off"
                />
                <label>/</label>
                <input
                  type="text"
                  id="day-input"
                  aria-label="day-input"
                  data-testid="test-day-dob"
                  onKeyUp={(e) =>
                    handleSpecialKeysDate(
                      e,
                      "day",
                      month,
                      day,
                      year,
                      shouldGoToNext,
                      setShouldGoToNext,
                    )
                  }
                  value={day}
                  onChange={(e) => {
                    setErrorDateOfBirth("");
                    handleOnChangeDay(e, day, setDay);
                    if (noMatchError) {
                      setZipErrorMsg("");
                      setAccountNumberError("");
                      setSSNErrorMsg("");
                    }
                    setNoMatchError(false);
                  }}
                  maxLength={2}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  autoComplete="off"
                />
                <label>/</label>
                <input
                  type="text"
                  id="year-input"
                  aria-label="year-input"
                  data-testid="test-year-dob"
                  className={styles["year-input"]}
                  onKeyUp={(e) => {
                    handleSpecialKeysDate(
                      e,
                      "year",
                      month,
                      day,
                      year,
                      shouldGoToNext,
                      setShouldGoToNext,
                    );
                  }}
                  onKeyPress={(e) => handleOnKeyPressYear(e)}
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                    setErrorDateOfBirth("");
                    if (noMatchError) {
                      setZipErrorMsg("");
                      setAccountNumberError("");
                      setSSNErrorMsg("");
                    }
                    setNoMatchError(false);
                  }}
                  onBlur={isValidDateOfBirth}
                  maxLength={4}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  autoComplete="off"
                />
              </div>
              {!isEmpty(errors?.dateOfBirth?.message || errorDateOfBirth) && (
                <p
                  className={`c-error-message u-text-fs-2 ${styles["c-error-message-audi"]}`}
                >
                  {errors?.dateOfBirth?.message || errorDateOfBirth}
                </p>
              )}
              <p
                className={`c-form-field__hint ${styles["c-form-field__hint"]}`}
              >
                {i18n.t(rk.TEXT_DATE_OF_BIRTH_HINT)}
              </p>
            </div>
          )}
          {isVWCanada && (
            <FormField
              id="accountNumber"
              label={i18n.t(rk.LABEL_ACCOUNT_NUMBER)}
              type="text"
              testid="test-account-number-field"
              onChange={(e) => {
                setAccountNumberError("");
                clearErrors("accountNumber");
                if (noMatchError) {
                  setZipErrorMsg("");
                  setErrorDateOfBirth("");
                  setSSNErrorMsg("");
                }
                setAccNum(e.target.value);
                setNoMatchError(false);
              }}
              autoCompleteOff
              value={accountNumber}
              hint={i18n.t(rk.TEXT_ACCOUNT_NUM_HINT_VF)}
              fieldRef={register({
                required: i18n.t(rk.ERROR_EMPTY_ACCOUNT_NUMBER),
                validate: {
                  validateAccountNumber: (value) =>
                    validate.isValidAccountNumber(value) ||
                    i18n.t(rk.ERROR_INVALID_ACCNUMBER),
                },
              })}
              onBlur={async () => {
                await trigger("accountNumber");
                await setIsContinueEnabled(!errors?.accountNumber?.message);
                if (errors?.accountNumber?.message) {
                  Logger.info(
                    `User entered invalid accountNumber ==> ${accountNumber}`,
                    "Location ==> /retrieveUsername",
                    sessionDetails,
                    "",
                    undefined,
                    "",
                  );
                }
              }}
              onKeyPress={restrictAccountKeyPress}
              fieldErrors={errors?.accountNumber?.message || accountNumberError}
              wrapperClassName="u-mb"
            />
          )}
          {isVWCanada && (
            <DoB
              isEmpty={isEmpty}
              errors={errors}
              errorDateOfBirth={errorDateOfBirth}
              styles={styles}
              setErrorDateOfBirth={setErrorDateOfBirth}
              noMatchError
              setZipErrorMsg={setZipErrorMsg}
              setAccountNumberError={setAccountNumberError}
              setSSNErrorMsg={setSSNErrorMsg}
              setNoMatchError={setNoMatchError}
              month={month}
              day={day}
              year={year}
              setMonth={setMonth}
              setDay={setDay}
              setYear={setYear}
              isValidDateOfBirth={isValidDateOfBirth}
            />
          )}
          <FormField
            id="zipCode"
            wrapperClassName={isAudi ? styles["form-field-custom"] : undefined}
            data-testid="test-zip"
            label={
              isAudi || isVWCanada
                ? i18n.t(rk.LABEL_POSTAL_CODE)
                : i18n.t(rk.LABEL_ZIP_CODE)
            }
            type="text"
            onChange={(e) => {
              setZipErrorMsg("");
              handleOnChangeZipCode(e);
              if (noMatchError) {
                setSSNErrorMsg("");
                setErrorDateOfBirth("");
                setAccountNumberError("");
              }
              setNoMatchError(false);
            }}
            autoCompleteOff
            onBlur={async () => {
              await trigger("zipCode");
              await setIsContinueEnabled(!errors.zipCode?.message);
              if (errors.zipCode?.message) {
                Logger.info(
                  `User entered invalid zipCode ==> ${zipCode}`,
                  "Location ==> /retrieveUsername",
                  sessionDetails,
                  "",
                  undefined,
                  "",
                );
              }
            }}
            value={zipCode}
            hint={
              isAudi || isVWCanada
                ? i18n.t(rk.TEXT_POSTAL_CODE_HINT)
                : i18n.t(rk.TEXT_ZIP_CODE_HINT)
            }
            fieldRef={register({
              required: i18n.t(
                isAudi ? rk.ERROR_EMPTY_POSTAL_CODE : rk.ERROR_EMPTY_ZIP_CODE,
              ),
              validate: {
                validatezipCode: () =>
                  validate.isValidPostalCode(zipCode, sessionDetails.country) ||
                  zipCodeErrorMsg,
              },
            })}
            onKeyPress={
              isVW ? restrictAccountKeyPress : restrictAccountKeyPressPostalCode
            }
            fieldErrors={errors.zipCode?.message || errorZipMsg}
          />
        </form>
        <style>{`
        .retrieve-username__infotext{
          font-size:16px;
        }
        @media (max-width: 600px) {
          .form-page-wrap {
            padding-left:0px;
            padding-right:0px;
          }
        }
        .new-page-wrap{
          padding-left:0px;
          padding-right:0px;
          height: 100%;
        }
        .bannerNotification{
          margin: -15px 0 50px;
        }
        .form-page-wrap {
          ${country === "canada" && "padding-left: 0px !important;"}
        }
        .retrieve-username__fieldsrequired{
          display:block;
          font-size: 14px;
          margin-top:10px;
          ${isVWCanada && "font-weight: 600;"}
          ${isVWCanada && "font-size: 16px;"}
        }
      `}</style>
      </div>
    );
  },
);
export default Verify;
Verify.propTypes = {
  setSSN: func,
  setAccountNumber: func,
  setChallengeQ: func,
  setZipcode: func,
  setPartyId: func,
  setDateOfBirth: func,
  setIsContinueEnabled: func,
};
Verify.defaultProps = {
  setSSN: () => {},
  setAccountNumber: () => {},
  setChallengeQ: () => {},
  setZipcode: () => {},
  setPartyId: () => {},
  setDateOfBirth: () => {},
  setIsContinueEnabled: () => {},
};
