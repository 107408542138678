import React, { useEffect, useState, createContext, ReactNode } from 'react';
import PropTypes from 'prop-types';
import packageJson from '../../auth-app/package.json';

global.appVersion = packageJson.version;

interface AppProps {
  brand: string;
  country: string;
  source: string;
  language: string;
  setSessionDetails?: () => void;
}

interface CacheBusterContextProviderProps {
  appProps: AppProps;
  children: ReactNode;
}

const CacheBusterContext = createContext({});
const CacheBusterContextProvider: React.FC<CacheBusterContextProviderProps> = (props) => {
  const { children, appProps } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(true);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(async function (names) {
        await Promise.all(names.map((name) => caches.delete(name)));
      });
      // delete browser cache and hard reload
      window.location.reload();
    }
  };

  const semverGreaterThan = (versionA: string, versionB: string): boolean => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);

    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());
      const b = Number(versionsB.shift());

      if (a === b) continue; // eslint-disable-line

      return a > b || Number.isNaN(Number(b));
    }
    return false;
  };

  useEffect(() => {
    fetch(`/meta.json`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((meta) => {
        const latestVersion = meta.version; // version from `meta.json`
        const currentVersion = global.appVersion; // version in bundle file

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        setShouldRefresh(shouldForceRefresh);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          setLoading(false);
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setShouldRefresh(false);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!loading && shouldRefresh) {
      refreshCacheAndReload();
    }
  }, [loading, shouldRefresh]);

  return (
    <CacheBusterContext.Provider value={{}}>
      {loading ? null : !shouldRefresh && children}
    </CacheBusterContext.Provider>
  );
};

CacheBusterContextProvider.propTypes = {
  appProps: PropTypes.shape({
    brand: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    setSessionDetails: PropTypes.func,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export { CacheBusterContext, CacheBusterContextProvider };
