import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useContext,
  MutableRefObject,
  ReactInstance,
} from "react";
import { useForm } from "react-hook-form";
import { func } from "prop-types";
import * as validate from "@ownportal/customer-validations";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import FormField from "../common/form-field/formField";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import Logger from "../../../logger";
import BannerNotification from "../common/banner/bannerNotification";
import { SessionContext } from "../../../context/sessionContext";
import { restrictKeyPressHandler } from "../../../utilities";
import styles from "./resetPassword.module.scss";
import DoB from "../common/dob/dob";
import { resetPswdVerify } from "../../services/resetPswdVerify";

interface VerifyUserProps {
  setSSN: (value: string) => void;
  setUsername: (value: string) => void;
  setDOB: (value: string) => void;
  setChallengeQuestion: (value: string) => void;
  setPartyId: (value: string) => void;
  setIsContinueEnabled: (value: boolean) => void;
}

const VerifyUser = forwardRef(
  (
    {
      setUsername,
      setSSN,
      setDOB,
      setChallengeQuestion,
      setPartyId,
      setIsContinueEnabled,
    }: VerifyUserProps,
    ref: MutableRefObject<ReactInstance>,
  ) => {
    const {
      register,
      errors,
      clearErrors,
      setError,
      setValue,
      getValues,
      trigger,
    } = useForm({});
    const captchaRef = useRef(null);
    const { sessionDetails } = useContext(SessionContext);
    const [isAPIError, setIsAPIError] = useState(false);
    const [errorMessage, setErrorMsg] = useState<string>("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");
    const [year, setYear] = useState("");
    const [errorDateOfBirth, setErrorDateOfBirth] = useState("");
    const [noMatchError, setNoMatchError] = useState<boolean | "">("");
    const [user, setUser] = useState("");
    const [userSSN, setUserSSN] = useState("");

    const [noEmailAdressError, setNoEmailAdressError] = useState(false);

    const isCaptchaEnabled =
      process.env.REACT_APP_AWS_ENV !== "dev" &&
      process.env.REACT_APP_AWS_ENV !== "test";
    const [captchaCompleted, setCaptchaCompleted] = useState(!isCaptchaEnabled);

    const siteKey = "6LcAP-0bAAAAAI2QfcaulKzivfpCv3symKFQ1S22";
    const navigate = useNavigate();
    const isCanada = sessionDetails?.country?.toLowerCase() === "canada";
    const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
    const isVW = sessionDetails?.brand?.toLowerCase() === "vw" && !isCanada;
    const isVWCanada =
      sessionDetails?.brand?.toLowerCase() === "vw" && isCanada;

    useEffect(() => {
      const isEmptyErrors = isEmpty(errors);
      if (isVW) {
        setIsContinueEnabled(
          user && userSSN && captchaCompleted && !noMatchError && isEmptyErrors,
        );
      }
      if (isAudi || isVWCanada) {
        setIsContinueEnabled(
          month &&
            day &&
            year &&
            captchaCompleted &&
            user &&
            !noMatchError &&
            !errorDateOfBirth &&
            isEmptyErrors,
        );
      }
    }, [
      setIsContinueEnabled,
      month,
      day,
      year,
      captchaCompleted,
      isVW,
      isAudi,
      user,
      userSSN,
      noMatchError,
      errors,
      errorDateOfBirth,
    ]);
    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              "Exited Reset Password Flow in Step 1 by clicking back button on the browser tab",
              "Location ==> /register",
              sessionDetails,
            );
          }
        },
        false,
      );
      window.addEventListener("beforeunload", () => {
        Logger.info(
          "Exited Reset Password Flow in Step 1 by closing the browser tab",
          "Location ==> /register",
          sessionDetails,
        );
      });
      // When the user change the language, triggers the existing error.
      for (const error in errors) {
        trigger(error);
      }

      if (errorDateOfBirth) {
        isValidDateOfBirth();
      }
    }, [sessionDetails]);

    const handleCaptchaSuccess = () => {
      setCaptchaCompleted(true);
    };

    const handleCaptchaError = () => {
      setCaptchaCompleted(false);
    };

    interface onSubmitProps {
      username: string;
      ssn: string;
      dateOfBirth: string;
    }

    async function onSubmit(payload: onSubmitProps) {
      const { username, ssn, dateOfBirth } = payload;
      const body = {
        username,
        ssn,
        dateOfBirth,
        sessionDetails,
      };

      const { data, error } = await resetPswdVerify(body);

      if (data?.inMaintenance) {
        navigate("/login");
      }
      if (data?.isSuccess) {
        Logger.info(
          "User found in reset password verification",
          "Location ==> /resetPassword",
          sessionDetails,
          "",
          data?.partyId,
          username,
        );
        setUsername(payload.username);
        // eslint-disable-next-line no-unused-expressions
        isAudi || isVWCanada ? setDOB(dateOfBirth) : setSSN(payload.ssn);
        setChallengeQuestion(data.challengeQuestion);
        setPartyId(data.partyId);
        return true;
      }
      if (data?.errors) {
        if (
          data?.errors?.ErrorCode === "badrequest_username" ||
          data?.errors?.ErrorCode === "badRequest_NoMatch" ||
          data?.errors?.ErrorCode === "invalid_request_x1" ||
          data?.errors?.ErrorCode === "badrequest_Credsname"
        ) {
          Logger.info(
            `Username not found in reset password verification. ErrorCode - ${data?.errors?.ErrorCode}`,
            "Location ==> /resetPassword",
            sessionDetails,
            "",
            "",
            username,
          );
          setError("username", { type: "string" });
          if (isVW) setError("ssn", { type: "string" });
          if (isAudi || isVWCanada)
            setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_INFO));
          setNoMatchError(true);
          return false;
        }
        if (data?.errors?.ErrorCode === "badRequest_accountLocked") {
          // @ts-expect-error We store the account lock status in sessionStorage as a boolean
          sessionStorage.setItem("isAccountLocked", true);
          navigate("/login");
          Logger.info(
            "Account Locked in reset password verification",
            "Location ==> /resetPassword",
            sessionDetails,
            "",
            "",
            username,
          );
          return false;
        }
        if (data?.errors?.ErrorCode === "invalid_date") {
          setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
          return false;
        }
        if (data?.errors?.ErrorCode === "badRequest_noEmailAddress") {
          setNoEmailAdressError(true);
          Logger.info(
            "Email not found for user in reset password verification",
            "Location ==> /resetPassword",
            sessionDetails,
            "",
            "",
            username,
          );
          return false;
        }
        setIsAPIError(true);
        setErrorMsg(i18n.t(rk.ERROR_API_SERVICE_DOWN));
        Logger.error(
          "VerifyUserCustomerForgotPassword API Failed",
          "Location ==> /resetPassword",
          data?.errors,
          sessionDetails,
          "",
          "",
          username,
        );
        return false;
      }
      setIsAPIError(true);
      setErrorMsg(i18n.t(rk.ERROR_API_SERVICE_DOWN));
      Logger.error(
        "VerifyUserCustomerForgotPassword API Failed",
        "Location ==> /resetPassword",
        data?.errors || error || data,
        sessionDetails,
        "",
        "",
        username,
      );
      return false;
    }

    const isValidDate = () => {
      const date = `${month}/${day}/${year}`;
      const parsedDate = new Date(date);
      return (
        parsedDate <= new Date() && parsedDate.getMonth() === Number(month) - 1
      );
    };

    async function verifyUserAction() {
      setIsAPIError(false);
      if (!isEmpty(errors)) {
        setIsContinueEnabled(false);
        return false;
      }
      if (isVW) {
        clearErrors("ssn");
        clearErrors("ssnEmpty");
      }
      if (isAudi || isVWCanada) {
        setErrorDateOfBirth("");
        clearErrors("dateOfBirth");
        if (!isValidDate()) {
          setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
          return false;
        }
      }
      clearErrors("username");
      clearErrors("usernameEmpty");
      const dob = `${month}/${day}/${year}`;
      const payload = {
        dateOfBirth: dob,
        username: user,
        ssn: userSSN,
      };

      return onSubmit(payload);
    }
    // @ts-expect-error verifyUserAction is a function
    useImperativeHandle(ref, () => ({ verifyUserAction }));
    const restrictSsnKeyPress = restrictKeyPressHandler(/^[0-9]*$/i);
    const isValidDateOfBirth = () => {
      setErrorDateOfBirth("");
      if (!day || !month || !year) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_EMPTY_DOB));
        setIsContinueEnabled(false);
      } else if (day.length !== 2 || month.length !== 2 || year.length !== 4) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
        setIsContinueEnabled(false);
        Logger.info(
          `User entered invalid DoB ==> ${month}/${day}/${year}`,
          "Location ==> /resetPassword",
          sessionDetails,
          "",
          "",
          "",
        );
      } else if (
        new Date(`${month}/${day}/${year}`) > new Date() ||
        !moment(`${year}-${month}-${day}`, "YYYY-MM-DD").isValid()
      ) {
        setErrorDateOfBirth(i18n.t(rk.ERROR_INVALID_DOB));
        setIsContinueEnabled(false);
        Logger.info(
          `User entered invalid DoB ==> ${month}/${day}/${year}`,
          "Location ==> /resetPassword",
          sessionDetails,
          "",
          "",
          "",
        );
      }
    };

    return (
      <div
        className={
          styles[
            isAudi ? "audi-verify-user-container" : "verify-user-container"
          ]
        }
        data-testid="verify-user-pass"
      >
        <p className={styles.subtitle}>
          {isAudi
            ? i18n.t(rk.TEXT_RESETPSWD_VERIFY_AUDI)
            : i18n.t(rk.TEXT_RESETPSWD_VERIFY)}
        </p>
        <p className={styles["fields-required-text"]}>
          {i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}
        </p>

        {isAPIError && (
          <div className={styles.bannerNotification}>
            <BannerNotification notificationStyle="error">
              {i18n.t(errorMessage)}
            </BannerNotification>
          </div>
        )}
        {noEmailAdressError === true && (
          <div className={styles.bannerNotification}>
            <BannerNotification notificationStyle="error">
              {isAudi
                ? i18n.t(rk.ERROR_API_NO_EMAIL_ADRESS_AUDI)
                : i18n.t(rk.ERROR_API_NO_EMAIL_ADRESS_VW)}
            </BannerNotification>
          </div>
        )}
        <div className="form-fields">
          <FormField
            id="username"
            label={i18n.t(rk.LABEL_USERNAME)}
            type="text"
            testid="username-field"
            onChange={(e) => {
              clearErrors("username");
              clearErrors("usernameEmpty");
              setValue("username", e.target.value);
              setUser(e.target.value);
              if (noMatchError && (isAudi || isVWCanada)) {
                setErrorDateOfBirth("");
              }
              if (noMatchError && isVW) {
                clearErrors("ssn");
              }
              setNoMatchError(false);
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            autoCompleteOff
            value={getValues().username}
            onBlur={async () => {
              await trigger("username");
              await setIsContinueEnabled(!errors.username);
              if (errors.username) {
                Logger.info(
                  `User entered invalid username ==> ${getValues().username}`,
                  "Location ==> /resetPassword",
                  sessionDetails,
                  "",
                  "",
                  "",
                );
              }
            }}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_USERNAME),
              validate: {
                validateUsername: (value) =>
                  validate.IsValidOldUserName(value) ||
                  i18n.t(rk.ERROR_API_INVALID_USERNAME),
              },
            })}
            fieldErrors={errors.username && i18n.t(rk.ERROR_INVALID_INFO)}
            wrapperClassName={isAudi ? styles["form-field-custom"] : ""}
          />
          {isVW && (
            <FormField
              id="ssn"
              label={i18n.t(rk.LABEL_SSN_LAST4)}
              type="text"
              testid="ssn-field"
              onChange={(e) => {
                clearErrors("ssn");
                clearErrors("ssnEmpty");
                setValue("ssn", e.target.value);
                setUserSSN(e.target.value);
                if (noMatchError) {
                  clearErrors("username");
                }
                setNoMatchError(false);
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              autoCompleteOff
              value={getValues().ssn}
              hint={i18n.t(rk.TEXT_SSN_LAST4_HINT)}
              onBlur={async () => {
                await trigger("ssn");
                await setIsContinueEnabled(!errors.ssn);
                if (errors.ssn) {
                  Logger.info(
                    "User entered invalid ssn",
                    "Location ==> /resetPassword",
                    sessionDetails,
                    "",
                    "",
                    "",
                  );
                }
              }}
              fieldRef={register({
                required: i18n.t(rk.ERROR_EMPTY_SSN),
                validate: {
                  validateSSN: (value) =>
                    validate.isValidLastFourSSN(value) ||
                    i18n.t(rk.ERROR_API_INVALID_SSN),
                },
              })}
              fieldErrors={errors.ssn && i18n.t(rk.ERROR_INVALID_INFO)}
              onKeyPress={restrictSsnKeyPress}
            />
          )}

          {(isVWCanada || isAudi) && (
            <DoB
              isEmpty={isEmpty}
              errors={errors}
              errorDateOfBirth={errorDateOfBirth}
              styles={styles}
              setErrorDateOfBirth={setErrorDateOfBirth}
              noMatchError
              setZipErrorMsg={() => {}}
              setAccountNumberError={() => {}}
              setSSNErrorMsg={() => {}}
              setNoMatchError={setNoMatchError}
              month={month}
              day={day}
              year={year}
              setMonth={setMonth}
              setDay={setDay}
              setYear={setYear}
              isValidDateOfBirth={isValidDateOfBirth}
              componentId={isVWCanada ? "vw-dob" : "audi-dob"}
            />
          )}

          {isCaptchaEnabled && (
            <ReCAPTCHA
              className="u-mt"
              ref={captchaRef}
              sitekey={siteKey}
              onChange={handleCaptchaSuccess}
              onExpired={handleCaptchaError}
              onErrored={handleCaptchaError}
            />
          )}
        </div>
      </div>
    );
  },
);

export default VerifyUser;

VerifyUser.propTypes = {
  setUsername: func,
  setSSN: func,
  setDOB: func,
  setChallengeQuestion: func,
  setPartyId: func,
  setIsContinueEnabled: func,
};

VerifyUser.defaultProps = {
  setUsername: () => {},
  setSSN: () => {},
  setDOB: () => {},
  setChallengeQuestion: () => {},
  setPartyId: () => {},
  setIsContinueEnabled: () => {},
};
