import React, { useEffect, useContext } from "react";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import Logger from "../../../logger";
import { SessionContext } from "../../../context/sessionContext";
import BannerNotification from "../common/banner/bannerNotification";

const LandingPage = () => {
  const { sessionDetails } = useContext(SessionContext);
  const brand = sessionDetails?.brand?.toUpperCase();
  const showCdkOutage =
    process.env.REACT_APP_CDK_OUTAGE_BANNER === "true" &&
    brand === "VW" &&
    sessionDetails?.country === "USA";


  useEffect(() => {
    window.addEventListener(
      "popstate",
      (event) => {
        if (event.state) {
          Logger.info(
            `Exited Registration Flow in Step 1 by clicking back button on the browser tab`,
            "Location ==> /register",
            sessionDetails,
          );
        }
      },
      false,
    );
  }, [sessionDetails]);
  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      Logger.info(
        "Exited Registration Flow in Step 1 by closing the browser tab",
        "Location ==> /register",
        sessionDetails,
      );
    });
  }, [sessionDetails]);
  return (
    <>
      <p>
        {brand === "AUDI"
          ? i18n.t(rk.TEXT_ACC_REGISTRATION_AUDI)
          : i18n.t(rk.TEXT_ACC_REGISTRATION)}
      </p>
      {showCdkOutage && (
        <BannerNotification
          title={i18n.t(rk.TITLE_CDK_OUTAGE_VW)}
          notificationStyle="custom"
        >
          <p>{i18n.t(rk.TEXT_CDK_OUTAGE_VW)}</p>
          <a
            href="https://www.vw.com/en/financial-services.html#CDK-outage-what-you-need-to-know"
            target="blank"
          >
            {i18n.t(rk.LINK_CDK_OUTAGE_VW)}
          </a>
        </BannerNotification>
      )}
    </>
  );
};

export default LandingPage;
