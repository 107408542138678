import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { isEmpty } from "lodash";
import { useForm } from "react-hook-form";
import * as validate from "@ownportal/customer-validations";
import FormField from "../common/form-field/formField";
import SelectField from "../common/select-field/selectField";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import { getChallenegeQuestionOptions } from "../../../utilities";
import styles from "./registerCustomer.module.scss";
import Logger from "../../../logger";
import { SessionContext } from "../../../context/sessionContext";
import Link from "../common/link/link";
import BannerNotification from "../common/banner/bannerNotification";
import { RegisterCustomerProps } from "./types";

// eslint-disable-next-line
const RegisterCustomer = forwardRef<any, RegisterCustomerProps>(
  ({ setAccRegInfo, setIsContinueEnabled, userConflict = false }, ref) => {
    const navigate = useNavigate();
    const { register, errors, clearErrors, trigger } = useForm({});
    const { sessionDetails } = useContext(SessionContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setconfirmPassword] = useState("");
    const [challengeQuestionOptions, setChallengeQuestionOptions] = useState(
      () => getChallenegeQuestionOptions(""),
    );
    const [challengeQuestion, setChallengeQuestion] = useState("");
    const [challengeAnswer, setChallengeAnswer] = useState("");
    const brand = sessionDetails?.brand?.toUpperCase();
    const country = sessionDetails?.country?.toUpperCase();
    const isVF = brand === "VW" && country === "CANADA";
    const [captchaError, setCaptchaError] = useState(false);
    const captchaRef = useRef(null);
    const isCaptchaEnabled =
      process.env.REACT_APP_AWS_ENV !== "dev" &&
      process.env.NODE_ENV !== "test";
    const [captchaCompleted, setCaptchaCompleted] = useState(!isCaptchaEnabled);
    const siteKey = "6LcAP-0bAAAAAI2QfcaulKzivfpCv3symKFQ1S22";

    const handleCaptchaSuccess = () => {
      setCaptchaError(false);
      setCaptchaCompleted(true);
    };

    const handleCaptchaError = () => {
      setCaptchaCompleted(false);
    };

    const handleCaptchaExpired = () => {
      setCaptchaCompleted(false);
      setCaptchaError(true);
    };

    useEffect(() => {
      setChallengeQuestionOptions(getChallenegeQuestionOptions(brand));
    }, [brand, sessionDetails?.language]);

    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              `Exited Registration Flow in Step 3 by clicking back button on the browser tab`,
              "Location ==> /register",
              sessionDetails,
            );
          }
        },
        false,
      );

      window.addEventListener("beforeunload", () => {
        Logger.info(
          "Exited Registration Flow in Step 3 by closing the browser tab",
          "Location ==> /register",
          sessionDetails,
        );
      });

      // When the user change the language, triggers the existing error.
      for (const error in errors) {
        trigger(error);
      }
    }, [sessionDetails]);

    useEffect(() => {
      const isEmptyErrors = isEmpty(errors);
      setIsContinueEnabled(
        username &&
          password &&
          challengeQuestion &&
          challengeAnswer &&
          confirmPassword &&
          isEmptyErrors &&
          captchaCompleted,
      );
    }, [
      username,
      password,
      challengeQuestion,
      challengeAnswer,
      confirmPassword,
      setIsContinueEnabled,
      errors,
      captchaCompleted,
    ]);

    async function verifyUserAction() {
      // Trigger input validations before submit call
      await trigger("username");
      await trigger("password");
      await trigger("confirmPassword");
      await trigger("challengeQuestion");
      await trigger("challengeAnswer");
      if (!isEmpty(errors)) {
        setIsContinueEnabled(false);
        return false;
      }
      const payload = {
        username,
        password,
        challengeQuestion,
        challengeAnswer,
      };
      setAccRegInfo(payload);
      return true;
    }

    useImperativeHandle(ref, () => ({ verifyUserAction }));

    return (
      <div
        className={
          styles[
            brand === "AUDI"
              ? "audi-acct-info-container"
              : "acct-info-container"
          ]
        }
      >
        {userConflict && (
          <div className={styles.bannerNotification}>
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.TEXT_USERNAME_CONFLICT)}
            </BannerNotification>
          </div>
        )}
        {brand === "AUDI" && (
          <div>
            <p className={styles["audi-login-link"]}>
              <span className={styles["audi-login-link-text"]}>
                {i18n.t(rk.TEXT_ALREADY_HAVE_AN_AUDI_ACCOUNT)}
              </span>
              <Link
                ariaLabel="Login link"
                value={i18n.t(rk.BUTTON_LOGIN)}
                icon="c-icon--[semantic-forward]"
                onClick={() => {
                  Logger.info(
                    "Clicked login link in registration flow",
                    "Location ==> /register",
                    sessionDetails,
                  );
                  navigate("/login");
                }}
                testid="login-link"
              />
            </p>
          </div>
        )}

        {isVF ? (
          <>
            <p className={styles["sub-heading-vf"]}>
              {i18n.t(rk.LABEL_ACCOUNT_INFO_VF)}
            </p>
            <div>
              <p className="u-mb-xsmall">{i18n.t(rk.TEXT_VEHICLE_INFO_VF)}</p>
              <p className={styles["vf-login-link"]}>
                <span className={styles["vf-login-link-text"]}>
                  {i18n.t(rk.TEXT_ALREADY_HAVE_AN_ACCOUNT)}
                </span>
                <Link
                  ariaLabel="Login link"
                  value={i18n.t(rk.TITLE_LOGIN)}
                  onClick={() => {
                    Logger.info(
                      "Clicked login link in registration flow",
                      "Location ==> /register",
                      sessionDetails,
                    );
                    navigate("/login");
                  }}
                  testid="login-link"
                />
              </p>
            </div>
          </>
        ) : (
          <>
            <p className={styles["sub-heading"]}>
              {i18n.t(rk.LABEL_VEHICLE_INFO)}
            </p>
            {brand === "VW" && (
              <p className={styles["first-paragraph"]}>
                {i18n.t(rk.TEXT_ACC_INFO_ONE)}
              </p>
            )}
            <p className={styles["sub-title"]}>
              {i18n.t(rk.TEXT_ACC_INFO_TWO)}
            </p>
            <p className={styles["fields-required-text"]}>
              {i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}
            </p>
          </>
        )}

        {isVF && (
          <p className={styles["fields-required-text-vf"]}>
            {i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}
          </p>
        )}

        <div className={isVF ? "" : styles["form-fields"]}>
          <FormField
            id="username"
            label={i18n.t(rk.LABEL_USERNAME)}
            type="text"
            testid="test-username-field"
            onChange={(e) => {
              setUsername(e.target.value);
              clearErrors("username");
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            autoCompleteOff
            value={username}
            hint={i18n.t(rk.TEXT_USERNAME_HINT)}
            wrapperClassName={isVF && "vf-wrapper"}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_USERNAME),
              validate: {
                validateUsername: (value) =>
                  validate.IsValidNewUserName(value) ||
                  i18n.t(rk.ERROR_API_INVALID_USERNAME),
              },
            })}
            onBlur={async () => {
              await trigger("username");
              await setIsContinueEnabled(!errors.username?.message);
              if (errors.username?.message) {
                Logger.info(
                  `User entered invalid username ==> ${username}`,
                  "Location ==> /register",
                  sessionDetails,
                  "",
                  null,
                  "",
                );
              }
            }}
            fieldErrors={errors.username?.message}
          />
          <FormField
            id="password"
            label={i18n.t(rk.LABEL_PASSWORD)}
            type="password"
            testid="test-password-field"
            onChange={(e) => {
              setPassword(e.target.value);
              clearErrors("password");
            }}
            autoCompleteOff
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onBlur={async () => {
              await trigger("password");
              await setIsContinueEnabled(!errors.password?.message);
              if (errors.passord?.message) {
                Logger.info(
                  "User entered invalid password",
                  "Location ==> /register",
                  sessionDetails,
                  "",
                  null,
                  "",
                );
              }
            }}
            value={password}
            hint={i18n.t(rk.TEXT_PASSWORD_HINT)}
            wrapperClassName={isVF && "vf-wrapper"}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_PASSWORD),
              validate: {
                validatePassword: () =>
                  validate.IsValidPassword(password, username) ||
                  i18n.t(rk.ERROR_API_INVALID_PASSWORD),
              },
            })}
            fieldErrors={errors.password?.message}
          />
          <FormField
            id="confirmPassword"
            label={
              isVF
                ? i18n.t(rk.LABEL_CONFIRM_NEW_PASSWORD_VF)
                : i18n.t(rk.LABEL_CONFIRM_NEW_PASSWORD)
            }
            type="password"
            testid="test-confirm-password-field"
            onChange={(e) => {
              setconfirmPassword(e.target.value);
              clearErrors("confirmPassword");
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            autoCompleteOff
            onBlur={async () => {
              await trigger("confirmPassword");
              await setIsContinueEnabled(!errors.confirmPassword?.message);
              if (errors.confirmPassword?.message) {
                Logger.info(
                  "User entered invalid confirmPassword",
                  "Location ==> /register",
                  sessionDetails,
                  "",
                  null,
                  "",
                );
              }
            }}
            value={confirmPassword}
            wrapperClassName={isVF && "vf-wrapper"}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_CONFIRM_PSWD),
              validate: {
                validatePassword: () =>
                  (password && password === confirmPassword) ||
                  i18n.t(rk.ERROR_PASSWORD_MATCH),
              },
            })}
            fieldErrors={errors.confirmPassword?.message}
          />
          <SelectField
            id="challengeQuestion"
            name="challengeQuestion"
            label={
              isVF
                ? i18n.t(rk.LABEL_CHALLENGE_QUESTION_VF)
                : i18n.t(rk.LABEL_CHALLENGE_QUESTION)
            }
            options={challengeQuestionOptions}
            placeholder={i18n.t(rk.LABEL_SELECT_CHALLENGE_QUESTION)}
            testid="test-challenge-question-field"
            onChange={(e) => {
              setChallengeQuestion(e.target.value);
              clearErrors("challengeQuestion");
            }}
            onBlur={() => {
              trigger("challengeQuestion");
              setIsContinueEnabled(!errors.challengeQuestion?.message);
            }}
            wrapperClassName={isVF && "vf-wrapper"}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_CHALLENGE_QUESTION),
            })}
            fieldErrors={errors.challengeQuestion?.message}
          />
          <FormField
            id="challengeAnswer"
            label={
              isVF
                ? i18n.t(rk.LABEL_CHALLENGE_ANSWER_VF)
                : i18n.t(rk.LABEL_CHALLENGE_ANSWER)
            }
            type="text"
            testid="test-challenge-answer-field"
            onChange={(e) => {
              setChallengeAnswer(e.target.value);
              clearErrors("challengeAnswer");
            }}
            autoCompleteOff
            onBlur={() => {
              trigger("challengeAnswer");
              setIsContinueEnabled(!errors.challengeAnswer?.message);
            }}
            value={challengeAnswer}
            wrapperClassName={isVF && "vf-wrapper"}
            hint={
              isVF
                ? i18n.t(rk.TEXT_CHALLENGE_ANSWER_HINT_VF)
                : i18n.t(rk.TEXT_CHALLENGE_ANSWER_HINT)
            }
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_CHALLENGE_ANSWER),
              validate: {
                validateSecurityQuestionAnswer: () =>
                  challengeAnswer !== "" ||
                  i18n.t(rk.ERROR_EMPTY_CHALLENGE_ANSWER),
              },
            })}
            fieldErrors={errors.challengeAnswer?.message}
          />
          {isCaptchaEnabled && (
            <ReCAPTCHA
              className="u-mt"
              ref={captchaRef}
              sitekey={siteKey}
              onChange={handleCaptchaSuccess}
              onExpired={handleCaptchaExpired}
              onErrored={handleCaptchaError}
            />
          )}
          {captchaError && (
            <p className="c-error-message u-mt-xsmall">
              {i18n.t(rk.ERROR_CAPTCHA_EXPIRED)}
            </p>
          )}
        </div>
      </div>
    );
  },
);

export default RegisterCustomer;
