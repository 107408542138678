import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Button from "../common/button/button";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import { SessionContext } from "../../../context/sessionContext";
import { RegisteredUserProps } from "./types";

const RegisteredUser: React.FC<RegisteredUserProps> = ({ previousAction }) => {
  const { sessionDetails } = useContext(SessionContext);
  const brand = sessionDetails?.brand?.toUpperCase();
  const navigate = useNavigate();

  const getContactUsLink = () => {
    return brand === "AUDI"
      ? "https://www.audi.ca/ca/web/en/contact-us.html"
      : "https://www.vw.com/en/contact.html";
  };

  return (
    <>
      <p className="u-pt-small text-register">
        {i18n.t(rk.TEXT_REGISTERED_USER)}
      </p>
      <div className=" o-button-container">
        <div className="flow-buttons">
          <Button
            onClick={() => {
              navigate("/login");
            }}
            value={
              brand === "Audi"
                ? i18n.t(rk.TITLE_LOGIN)
                : i18n.t(rk.BUTTON_LOGIN)
            }
            testid="login-button"
          />
        </div>
        <div className="flow-buttons">
          <Button
            onClick={() => {
              navigate("/retrieveUsername");
            }}
            value={i18n.t(rk.BUTTON_RECOVER)}
            testid="retrieve-button"
            secondary
          />
        </div>
      </div>
      <div className="separator text-register">
        <span className="u-ph-xsmall">{i18n.t(rk.TEXT_LINE_BREAK)}</span>
      </div>
      <div className="u-text-center u-pt-small">
        <button
          type="button"
          className="c-btn c-btn--link"
          data-testid="restart-button"
          onClick={() => {
            previousAction();
          }}
        >
          <span className="c-btn__text text-register">
            {i18n.t(rk.LINK_RESTART_REGISTER)}
          </span>
        </button>
        <p className="u-text-muted u-text-fs-3 u-mt-large u-text-left@s">
          {i18n.t(rk.TEXT_CONTACT_US_1)}
          <span>
            <a href={getContactUsLink()}>
              {i18n.t(rk.LINK_CONTACT_US_REGISTER)}
            </a>
          </span>
          {i18n.t(rk.TEXT_CONTACT_US_2)}
        </p>
      </div>
      <style>{`
        .text-register {
          font-size: 16px !important;
        }
        button > .text-register {
          text-decoration: underline;
        }
        .separator {
            display: flex;
            align-items: center;
            text-align: center;
        }
        .separator::before,
        .separator::after {
            content: '';
            flex: 1;
            border-bottom: 2px solid #c2cacf;
        }
        .separator:not(:empty)::before {
            margin-right: .25em;
        }
        .separator:not(:empty)::after {
            margin-left: .25em;
        }
        .page-content .stepper-flow-container .o-button-container {
          margin: auto;
          padding: 30px 0px 60px 0px;
          grid-template-columns: repeat(auto-fit, minmax(142px, 1fr)) !important;
          ${brand === "AUDI" && "width: 100% !important;"}
        }
        .flow-buttons, .flow-buttons button {
          width: 100%;
        }
        .flow-buttons button {
          font-size: 16px;
          padding: 0 5px !important;
        }
        .u-text-muted {
          padding-bottom: 30px;
        }
      `}</style>
    </>
  );
};

RegisteredUser.propTypes = {
  previousAction: PropTypes.func,
};

RegisteredUser.defaultProps = {
  previousAction: () => {},
};

export default RegisteredUser;
