/* eslint-disable import/prefer-default-export */
import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  partyId: number;
  challengeQuestion: string;
  challengeAnswer: string;
  sessionDetails: SessionDetails;
}

const gqlRequest = gql`
  query VerifyChallengeCustomerForgotPassword(
    $Request: VerifyChallengeCustomerForgotPasswordRequest!
  ) {
    verifyChallengeCustomerForgotPassword(Request: $Request) {
      IsSuccess
      IsVerified
      ResetCount
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

export const resetPswdChallenge = async (payload: Payload) => {
  const { partyId, challengeAnswer, challengeQuestion, sessionDetails } =
    payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request = {
      PartyId: partyId,
      ChallengeAnswer: challengeAnswer,
      ChallengeQuestion: challengeQuestion,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      Source: sessionDetails.source,
      SourceApp: sessionDetails.source,
      ParentFunctionName: "Reset Password",
      ProcessName: "Reset Password",
      WidgetName: sessionDetails.source,
    };
    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (
      response?.data?.data?.verifyChallengeCustomerForgotPassword?.IsSuccess &&
      response?.data?.data?.verifyChallengeCustomerForgotPassword?.IsVerified
    ) {
      return {
        data: {
          isSuccess: true,
        },
      };
    }
    if (
      !response?.data?.data?.verifyChallengeCustomerForgotPassword?.IsSuccess
    ) {
      return {
        data: {
          isSuccess: false,
          resetCount:
            response?.data?.data?.verifyChallengeCustomerForgotPassword
              ?.ResetCount,
          errors:
            response?.data?.data?.verifyChallengeCustomerForgotPassword
              ?.Errors?.[0] || "",
        },
      };
    }
    if (response?.data?.data === null) {
      return {
        data: {
          isSuccess: false,
          errors: response?.data?.errors || "",
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};
