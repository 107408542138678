import React, { useRef, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Logo from "../logo/logo";
import Button from "../button/button";
import Link from "../link/link";
import i18n from "../../../../localization/i18n";
import * as rk from "../../../../localization/resourceKeys";
import { SessionContext } from "../../../../context/sessionContext";

interface StepFlowProps {
  currentStep?: number;
  step?: number;
  title?: string | React.ReactElement;
  parent?: boolean;
  children?: string;
  nextLabel?: string;
  nextLabelDisabled?: boolean;
  canTakeNextAction?: () => Promise<boolean>;
  nextAction?: () => void;
  nextActionType?: "button" | "link";
  previousLabel?: string;
  previousLabelDisabled?: boolean;
  canTakePreviousAction?: () => void;
  previousAction?: () => void;
  renderChangeVerificationMethodLink?: () => void;
}
const StepFlow: React.FC<StepFlowProps> = (props): JSX.Element | null => {
  const { sessionDetails } = useContext(SessionContext);
  const brand = sessionDetails?.brand?.toUpperCase();
  const isAudi = brand === "AUDI";
  const country = sessionDetails?.country?.toUpperCase();
  const isVF = brand === "VW" && country === "CANADA";
  const previousButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const {
    currentStep,
    step,
    title,
    parent,
    children,
    nextLabel,
    nextLabelDisabled,
    canTakeNextAction,
    nextAction,
    nextActionType,
    previousLabel,
    previousLabelDisabled,
    canTakePreviousAction,
    previousAction,
    renderChangeVerificationMethodLink,
  } = props;

  const isSingleButton =
    (previousLabel && !nextLabel) || (!previousLabel && nextLabel);

  const setDisableStatus = (
    status: boolean,
    buttonRef: React.RefObject<HTMLButtonElement>
  ) => {
    if (buttonRef?.current) {
      // eslint-disable-next-line
      buttonRef.current.disabled = status;
    }
  };
  useEffect(() => {
    setDisabled(previousLabelDisabled);
  }, [previousLabelDisabled, setDisabled]);

  /**
   * step flow previous action or throw error
   */
  const previous = async () => {
    if (canTakePreviousAction) {
      const canTakePreviousActionResp = await canTakePreviousAction(props);
      if (!canTakePreviousActionResp) return;
    }
    if (previousAction) {
      previousAction(props);
    } else if (previousLabel) {
      throw new Error("Missing previous action");
    }
  };

  /**
   * step flow next action or throw error
   */
  const next = async () => {
    if (canTakeNextAction) {
      setDisableStatus(true, nextButtonRef);
      const canTakeNextActionResp = await canTakeNextAction(props);
      setDisableStatus(false, nextButtonRef);
      if (!canTakeNextActionResp) return;
    }
    if (nextAction) {
      nextAction(props);
    } else if (nextLabel) {
      throw new Error("Missing next action");
    }
  };

  // when the current step does not equal this step definition, do not render
  if (currentStep !== -1 && currentStep !== step) {
    return null;
  }

  if (parent) {
    return <div>{children}</div>;
  }

  const isEmailAndAuthEnabled =
    process.env.REACT_APP_ENABLE_EMAIL_AND_AUTH === "true" &&
    sessionDetails?.country?.toLowerCase() === "usa" &&
    sessionDetails?.brand?.toLowerCase() === "vw";

  return (
    <div
      className={`stepper-flow-container o-page-wrap ${isVF && "o-page-form-wrap"}`}
      id="step-page"
    >
      <div
        className={`logo-container ariaNoBorder ${isAudi ? "audi-logo-container" : undefined}`}
      >
        <Logo />
      </div>
      <div
        data-testid="test-title"
        className={`flow-title ${isAudi && "flow-title-audi"}`}
      >
        {title}
      </div>
      <div className="stepper-flow-layout-item-children u-12/12 u-1/1@s">
        {children}
      </div>
      {previousLabel || nextLabel ? (
        <div
          className={`o-button-container ${isEmailAndAuthEnabled && "grid-auto-flow-dense"} ${
            isSingleButton && "grid-single-button"
          } ${(step === 1 || step === 2) && isVF ? "btn-wrapper" : ""}`}
        >
          {previousLabel ? (
            <div
              className={`flow-buttons ${isEmailAndAuthEnabled && nextActionType === "button" ? "grid-order-2" : ""}`}
            >
              <Button
                ref={previousButtonRef}
                testid={`previous-button-step-${step}`}
                disabled={disabled}
                id="previous-button"
                onClick={previous}
                value={previousLabel}
              />
            </div>
          ) : (
            <div />
          )}
          {nextLabel ? (
            <div
              className={`flow-buttons ${isEmailAndAuthEnabled && nextActionType === "button" ? "grid-order-1" : ""} ${
                isVF && "reverse-order"
              }`}
            >
              {nextActionType === "link" ? (
                <Link
                  id="next-button"
                  ref={nextButtonRef}
                  disabled={nextLabelDisabled}
                  value={nextLabel}
                  onClick={next}
                  fontSize="16px"
                  ariaLabel={nextLabel}
                  testid={`next-button-step-${step}`}
                />
              ) : (
                <Button
                  ref={nextButtonRef}
                  disabled={nextLabelDisabled}
                  testid={`next-button-step-${step}`}
                  secondary
                  id="next-button"
                  onClick={next}
                  value={nextLabel}
                />
              )}
            </div>
          ) : (
            <div />
          )}
          {renderChangeVerificationMethodLink &&
            renderChangeVerificationMethodLink()}
        </div>
      ) : (
        <div />
      )}

      <style>
        {`
        .retrieve-username-image {
          height: 100%;
        }

        .reverse-order {
          order: -1 !important;
        }

        .grid-order-1 {
          grid-column: 1;
        }

        .grid-order-2 {
          grid-column: 2;
        }

        .flow-title-audi {
          h2{
            font-size: 32px !important;
            font-weight: normal;
          }
        }

        .audi-logo-container {
          padding-bottom: 40px !important;
        }

        .grid-auto-flow-dense {
          grid-auto-flow: dense;
        }

        .grid-single-button{
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          > div:first-child {
            width: 40%;
          }
          > div:nth-child(2) {
            width: 40%;
          }
        }

        .btn-wrapper {
          height: 100%;
          ${step === 3 && "align-items: start;"}
          margin: 0px 0px 80px 0px !important;
        }

        .ariaNoBorder {
          outline-style: none;
          box-shadow: none;
        }

        .stepper-flow-container .o-button-container {
          padding: ${brand === "AUDI" ? "60px 0px 100px 0px;" : "60px 0px 60px 0px;"}
          ${isVF && step === 3 && "align-items: start;"}
        }

        .flow-buttons, .flow-buttons button {
          width: 100%;
          ${brand === "AUDI" ? "height: 59px;" : "height: fit-content;"}
        }

        .flow-title h2 {
          font-size: 32px;
          margin-bottom: 15px;
          ${isVF && "font-weight: 500;"}
        }

        .flow-title-audi h2 {
          font-size: 32px !important;
          margin-bottom: 15px !important;
          font-weight: 400 !important;
        }

        .logo-container {
          padding-bottom: 70px;
        }
        ${
          isVF &&
          `.o-page-form-wrap {
            display: flex;
            height: 100%;
            width: 100%;
            flex-direction: column;
          }`
        }

        @media(max-width: 480px) {
          .o-page-wrap {
            padding-left: 4% !important;
            padding-right: 4% !important;
            ${isVF && step === 1 && "padding: 0px !important;"}
          }
        }

        ${
          brand === "AUDI" &&
          `.stepper-flow-container .o-button-container {
            margin: unset;
            flex-wrap: nowrap;
            width: fit-content !important;
          }
          @media(max-width: 414px) {
            .stepper-flow-container .o-button-container {
              margin: unset;
              flex-wrap: wrap;
              width: fit-content !important;
            }
          }`
        }
        ${
          step === 3 &&
          previousLabel === i18n.t(rk.BUTTON_LOGIN) &&
          `.stepper-flow-container .o-button-container {
            width: unset;
            max-width: 100%;
            gap: 0px;
          }

          .flow-buttons button{
            width: 324px !important;
          }
          
          @media(min-width: 960px){
            .flow-buttons button{
              width: 374px !important;
            }
          }`
        }

        ${
          step === 4 &&
          previousLabel === i18n.t(rk.TITLE_LOGIN) &&
          `.stepper-flow-container .o-button-container {
              width: unset;
              max-width: 100%;
              gap: 0px;
              display: inline-block;
          }
         
          .flow-buttons button{
            width: 324px !important;
          }
          
          @media(min-width: 960px){
            .flow-buttons button {
             width: 100% !important;
            }
          }`
        }`}
      </style>
    </div>
  );
};

StepFlow.propTypes = {
  step: PropTypes.number,
  currentStep: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  previousLabel: PropTypes.string,
  canTakePreviousAction: PropTypes.func,
  previousAction: PropTypes.func,
  nextLabel: PropTypes.string,
  canTakeNextAction: PropTypes.func,
  nextAction: PropTypes.func,
  nextActionType: PropTypes.oneOf(["button", "link"]),
  parent: PropTypes.bool,
  children: PropTypes.node.isRequired,
  previousLabelDisabled: PropTypes.bool,
  nextLabelDisabled: PropTypes.bool,
  renderChangeVerificationMethodLink: PropTypes.func,
};

StepFlow.defaultProps = {
  step: undefined,
  currentStep: undefined,
  title: "",
  previousLabel: undefined,
  canTakePreviousAction: undefined,
  previousAction: undefined,
  nextLabel: undefined,
  canTakeNextAction: undefined,
  nextAction: undefined,
  nextActionType: "button",
  parent: false,
  previousLabelDisabled: true,
  nextLabelDisabled: false,
  renderChangeVerificationMethodLink: () => {},
};

export default StepFlow;
