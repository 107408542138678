import React, { ChangeEvent, FocusEvent } from "react";
import _ from "lodash";

interface SelectFieldProps {
  name: string;
  id: string;
  disabled?: boolean;
  fieldErrors?: string[];
  label: string;
  fieldRef?: React.Ref<HTMLSelectElement>;
  wrapperClassName?: string;
  options: string[];
  placeholder?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
  testid?: string;
}

const SelectField = (props: SelectFieldProps) => {
  const {
    name = "",
    id = "",
    disabled = false,
    fieldErrors,
    label = "",
    fieldRef,
    wrapperClassName = "",
    options = [],
    placeholder = "",
    onChange,
    onBlur,
    testid = "",
  } = props;

  return (
    <div className={`o-fieldset__row ${wrapperClassName}`}>
      <div className="c-form-field is-active">
        <label
          htmlFor={id}
          className={`c-form-field__label ${!_.isEmpty(fieldErrors) && "is-error"}`}
        >
          {label}
        </label>
        <div className="c-input  c-input--select">
          <select
            className={`c-input__input ${!_.isEmpty(fieldErrors) && "is-error"}`}
            name={name}
            id={id}
            ref={fieldRef}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            data-testid={testid}
          >
            <option value="">{placeholder}</option>
            {options.map((option: string) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        {!_.isEmpty(fieldErrors) && (
          <p className="c-error-message state-error">{fieldErrors}</p>
        )}
      </div>
      <style>{`
        .c-input {
          outline: none !important;
        }
        .vf-wrapper {
          margin-bottom: 56px !important;
        }
      `}</style>
    </div>
  );
};

export default SelectField;
