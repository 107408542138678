/* eslint-disable import/prefer-default-export */
import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  partyId: number;
  loginAuthenticatorCode: string;
  sessionDetails: SessionDetails;
}

const gqlRequest = gql`
  query CheckIfCustomerAuthenticatorSecretExists(
    $Request: CheckIfCustomerAuthenticatorSecretExistsRequest!
  ) {
    checkIfCustomerAuthenticatorSecretExists(Request: $Request) {
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
      DoesSecretExist
    }
  }
`;

export const checkCustomerAuthenticatorSecretExists = async (
  payload: Payload,
) => {
  const { loginAuthenticatorCode, partyId, sessionDetails } = payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request = {
      LoginAuthenticatorCode: loginAuthenticatorCode,
      PartyId: partyId,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      SourceApp: sessionDetails.source,
      Country: sessionDetails.country,
      ParentFunctionName: "Generate Secret",
      ProcessName: "Generate Secret",
      WidgetName: sessionDetails.source,
    };
    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (
      response?.data?.data?.checkIfCustomerAuthenticatorSecretExists?.IsSuccess
    ) {
      return {
        data: {
          isSuccess: true,
          doesSecretExist:
            response?.data?.data?.checkIfCustomerAuthenticatorSecretExists
              ?.DoesSecretExist,
        },
      };
    }
    if (
      !response?.data?.data?.checkIfCustomerAuthenticatorSecretExists
        ?.IsSuccess ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: false,
          errors:
            response?.data?.data?.checkIfCustomerAuthenticatorSecretExists
              ?.Errors?.[0] || response?.data?.errors,
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};
