/* eslint-disable */
import React, { useContext, useEffect } from "react";
import { SessionContext } from "../../../../context/sessionContext";
import i18n from "../../../../localization/i18n";
import * as rk from "../../../../localization/resourceKeys";
import Logger from "../../../../logger";
import FooterAudi from './footerAudi';
import LanguageToggle from "../language/languageToggle";
import styles from "./footer.module.scss";

const Footer = () => {
  const { sessionDetails } = useContext(SessionContext);

  const IL_CERTIFICATE_PATH = `${process.env.REACT_APP_VCIPORTAL_URL}/assets/IL-2024-Sales-Finance-License-VCI.pdf`;
  const country = sessionDetails?.country?.toLowerCase();
  const brand = sessionDetails?.brand?.toLowerCase();
  const isCanadaVW = country === "canada" && brand === "vw" ;

  useEffect(() => {
    if (sessionDetails?.source === "VWC Portal") {
      sessionStorage.removeItem("lang");
      i18n.changeLanguage("enUS");
    }
  }, [])

  const getPrivacyNoticeLink = () => {
    if (country === "usa") {
      return process.env.REACT_APP_AWS_ENV === "prod"
        ? `https://content.ownerportal.vwcredit.io/tnc/vw/privacy`
        : `https://content.${process.env.REACT_APP_AWS_ENV}.ownerportal.vwcredit.io/tnc/vw/privacy`;
    }
    if (country === "canada") {
      const link = i18n.t(rk.FOOTER_PRIVACY_POLICY_CAN_LINK);
      const env = process.env.REACT_APP_AWS_ENV;
      const nonProd = link.slice(0, 16) + env + link.slice(15);
      return process.env.REACT_APP_AWS_ENV === "prod"
          ? i18n.t(rk.FOOTER_PRIVACY_POLICY_CAN_LINK)
          : nonProd;
    }
  };

  return sessionDetails?.brand?.toLowerCase() === "audi" ? (
    <FooterAudi />
  ) : (
    <footer className="c-footer">
      {!isCanadaVW ? (
        <>
          <ul className="c-footer-meta-nav footer-links">
            <li
              className="c-footer-meta-nav__item"
              onClick={() => Logger.info("Clicked privacy policy link", "Location ==> /login", sessionDetails)}
              data-testid="privacy-policy"
            >
              <a className="c-footer-meta-nav__link" rel="noopener noreferrer" target="_blank" href={getPrivacyNoticeLink()}>
                {i18n.t(rk.FOOTER_PRIVACY_POLICY)}
              </a>
            </li>
            <li
              className="c-footer-meta-nav__item"
              onClick={() => Logger.info("Clicked dealer locator link", "Location ==> /login", sessionDetails)}
              data-testid="dealer-locator"
            >
              <a className="c-footer-meta-nav__link" rel="noopener noreferrer" href={i18n.t(rk.LINK_DEALER_LOCATOR)}>
                {i18n.t(rk.FOOTER_DEALER_LOCATOR)}
              </a>
            </li>
            <li
              className="c-footer-meta-nav__item"
              onClick={() => Logger.info("Clicked contact us link", "Location ==> /login", sessionDetails)}
              data-testid="contact-us"
            >
              <a className="c-footer-meta-nav__link" rel="noopener noreferrer" href={i18n.t(rk.LINK_CONTACT_US)}>
                {i18n.t(rk.FOOTER_CONTACT_US)}
              </a>
            </li>
          </ul>
          <ul className="c-footer-meta-nav footer-non-links">
            <li className="c-footer-meta-nav__item">
              <span className="c-footer-copyright">
                <span className="c-footer-copyright__sign">©</span>
                <span className="c-footer-copyright__text">{i18n.t(rk.FOOTER_VW_INC_2021)}</span>
                <span className="c-footer-copyright__text">{i18n.t(rk.FOOTER_VW_INC)}</span>
              </span>
            </li>
            <li>
              <span className="footer-non-link-text">{i18n.t(rk.FOOTER_NMLS_3024)}</span>
            </li>
            <li className="c-footer-meta-nav__item">
              <span className="c-footer-copyright">
                <span className="c-footer-copyright__text">{i18n.t(rk.FOOTER_CREDIT_LEASING)}</span>
              </span>
            </li>
            <li>
              <span className="footer-non-link-text">{i18n.t(rk.FOOTER_NMLS_121740)}</span>
            </li>
          </ul>
          <ul className="c-footer-meta-nav footer-non-links">
            <li className="c-footer-meta-nav__item">
              <span className="c-footer-copyright">
                <span className="c-footer-copyright__text">{i18n.t(rk.FOOTER_LOAN_SERVICES)}</span>
              </span>
            </li>
            <li>
              <span className="footer-non-link-text">{i18n.t(rk.FOOTER_NMLS_121742)}</span>
            </li>
            <li
              className="c-footer-meta-nav__item consumer-access-link"
              onClick={() => Logger.info("Clicked consumer access link", "Location ==> /login", sessionDetails)}
              data-testid="consumer-access-link"
            >
              <a className="c-footer-meta-nav__link" rel="noopener noreferrer" href="https://www.nmlsconsumeraccess.org/">
                {i18n.t(rk.FOOTER_CONSUMER_ACCESS)}
              </a>
            </li>
            <li
            className="c-footer-meta-nav__item consumer-access-link"
            >
              <a
                className={`c-footer-meta-nav__link`}
                tabIndex={0}
                data-testid='illinois-license-link'
                rel='noopener noreferrer'
                href={IL_CERTIFICATE_PATH}
                target='_blank'
                onClick={() => console.info("USfooterLinkILCertificateClicked")}
              >
                {i18n.t(rk.FOOTER_LINK_IL_CERTIFICATE)}
              </a>
            </li>
          </ul>
        </>
      ) : (
        <>
          <div className="wrapper-div-footer">
            <div className={`o-page-wrap ${styles["footer-wrapper"]}`}>
              <div className="u-mr-large" id="footer-links-container">
                <ul className="c-footer-meta-nav footer-links">
                  <li
                    className="c-footer-meta-nav__item"
                    onClick={() => Logger.info("Clicked privacy policy link", "Location ==> /login", sessionDetails)}
                    data-testid="privacy-policy"
                  >
                    <a className="c-footer-meta-nav__link" rel="noopener noreferrer" target="_blank" href={getPrivacyNoticeLink()}>
                      {i18n.t(rk.FOOTER_PRIVACY_POLICY)}
                    </a>
                  </li>
                  <li
                    className="c-footer-meta-nav__item"
                    onClick={() => Logger.info("Clicked dealer locator link", "Location ==> /login", sessionDetails)}
                    data-testid="dealer-locator"
                  >
                    <a className="c-footer-meta-nav__link" rel="noopener noreferrer" href={i18n.t(rk.FOOTER_DEALER_LOCATOR_CAN_LINK)}>
                      {i18n.t(rk.FOOTER_DEALER_LOCATOR)}
                    </a>
                  </li>
                  <li
                    className="c-footer-meta-nav__item"
                    onClick={() => Logger.info("Clicked contact us link", "Location ==> /login", sessionDetails)}
                    data-testid="contact-us"
                  >
                    <a className="c-footer-meta-nav__link" rel="noopener noreferrer" href={i18n.t(rk.FOOTER_CONTACT_US_CAN_LINK)}>
                      {i18n.t(rk.FOOTER_CONTACT_US)}
                    </a>
                  </li>
                  <li
                    className="c-footer-meta-nav__item"
                    onClick={() => Logger.info("Clicked contact us link", "Location ==> /login", sessionDetails)}
                    data-testid="contact-us"
                  >
                    <span className="c-footer-copyright" rel="noopener noreferrer" href={i18n.t(rk.FOOTER_CONTACT_US_CAN_LINK)}>
                      <span className="c-footer-copyright__text">{i18n.t(rk.FOOTER_VW_CANADA)}</span>
                    </span>
                  </li>
                </ul>
              </div>
              <div
                className="c-footer-meta-nav__item consumer-access-link o-layout-item "
                onClick={() => Logger.info("Clicked consumer access link", "Location ==> /login", sessionDetails)}
                data-testid="consumer-access-link"
              >
                <LanguageToggle />
              </div>
            </div>
          </div>
        </>
      )}

      <style>{`
        @media (min-width: 1025px) {
          .c-footer-meta-nav {
            ${isCanadaVW ? "margin: 0px;" : "margin: -11px;"}
          }
        }
        .c-footer-meta-nav {
          flex-direction: unset;
          justify-content: center;
          padding-bottom: 0px;
        }
        .c-footer-meta-nav__link {
          font-size: 12px;
        }
        .footer-links .c-footer-meta-nav__item a {
          word-break: break-word;
        }
        .c-footer-meta-nav__item {
          width: max-content;
        }
        .c-footer-meta-nav__item:not(:last-child)::after {
          content: "|";
          margin-left: 4px;
          font-size: 14px;
        }
        .c-footer-meta-nav__item:last-child::after {
          content: "" !important;
        }
        .c-footer-meta-nav__item:not(:last-child) {
          margin-right: 4px;
        }
        .c-footer-copyright {
          font-size: 12px;
          color: rgb(60, 72, 77);
          margin-bottom: 0px;
        }
        .footer-links {
          padding-bottom: 10px;
          display: flex;
          flex-wrap: wrap;
        }
        .c-footer-copyright__text,
        .footer-non-link-text {
          white-space: pre;
        }
        .footer-non-links .c-footer-meta-nav__item::after {
          content: "|" !important;
        }
        .footer-non-links .consumer-access-link.c-footer-meta-nav__item::after {
          content: "|" !important;
        }
        .footer-non-links .consumer-access-link.c-footer-meta-nav__item::after {
          content: "|" !important;
        }
        .footer-non-links .consumer-access-link.c-footer-meta-nav__item:last-child::after{
          content: "" !important;
        }
        .footer-non-link-text {
          font-size: 12px;
          color: rgb(60, 72, 77);
          align-self: flex-end;
          margin-bottom: 1px;
        }
        ${isCanadaVW ? `
          @media (max-width: 465px) {
            #footer-links-container {
              margin-right: 0px !important;
            }
            .c-footer-meta-nav__item:nth-child(2n + 1)::after {
              content: "" !important;
            }
          }
        `:`
          @media (max-width: 376px) {
            .c-footer-meta-nav__item:nth-child(3n + 2)::after {
              content: "" !important;
            }
          }
          @media (min-width: 411px) and (max-width: 534px) {
            .c-footer-meta-nav__item:nth-child(3n + 3)::after {
              content: "" !important;
            }
          }
          @media (min-width: 960px) and (max-width: 1279px) {
            .c-footer-meta-nav__item:nth-child(3n + 3)::after {
              content: "" !important;
            }
          }
        `}
        
      `}</style>
    </footer>
  );
};

export default Footer;
