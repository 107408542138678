/* eslint-disable */
import React, { useContext } from "react";
import { bool, func, string } from "prop-types";
import _ from "lodash";
import { SessionContext } from "../../../../context/sessionContext";

const Checkbox = (props) => {
  const { onChange, checked, label, id, testid, errorMessage, disabled } =
    props;

  const { sessionDetails } = useContext(SessionContext);
  const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";

  return (
    <div>
      <div className="checkbox-wrapper">
        <label htmlFor={id} className="c-checkbox">
          <input
            className={`c-checkbox__input chkbox-input-style   ${!_.isEmpty(errorMessage) && "is-error"}`}
            type="checkbox"
            name={id}
            onChange={onChange}
            checked={checked}
            id={id}
            data-testid={testid}
            disabled={disabled}
          />
          <span className="c-checkbox__label chkbox-label-style" htmlFor={id}>
            {label}
          </span>
        </label>
        {!_.isEmpty(errorMessage) && (
          <p className="c-error-message">{errorMessage}</p>
        )}
      </div>
      <style jsx>
        {`
          .chkbox-label-style {
            ${!isAudi && "color: rgb(0, 30, 80)"};
            font-size: 16px;
          }
          .chkbox-label-style {
            ${disabled && "color: #8c9ca5!important;"};
          }
        `}
      </style>
    </div>
  );
};

Checkbox.propTypes = {
  onChange: func.isRequired,
  checked: bool,
  id: string,
  label: string.isRequired,
  testid: string,
  errorMessage: string,
  disabled: bool,
};

Checkbox.defaultProps = {
  checked: false,
  id: "",
  testid: "",
  errorMessage: "",
  disabled: false,
};

export default Checkbox;
