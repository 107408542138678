import React, { useEffect, useState } from "react";
import { arrayOf, element, func, shape, string } from "prop-types";

const RadioButtonGroup = ({ options, className, onChange, defaultOption }) => {
  const [firstOption = {}] = options;
  const { name: defaultSelected } = firstOption;
  const [selected, setSelected] = useState(defaultOption || defaultSelected);

  useEffect(() => {
    if (selected) {
      onChange(selected);
    }
  }, [onChange, selected]);

  if (options.length === 0) return null;

  return (
    <div className={`o-inline-group ${className}`}>
      {options.map((option, index) => (
        <div key={index} className="o-inline-group__item">
          <label className="c-radio" htmlFor={option.id} key={option.name}>
            <input
              id={option.id}
              value={option.value}
              className="c-radio__input"
              type="radio"
              name={option.name || `radio-button-${index}`}
              checked={selected === option.value}
              onChange={(e) => {
                setSelected(e.target.value);
              }}
            />
            <span className="c-radio__label">{option.text || ""}</span>
          </label>
          {option.link}
        </div>
      ))}
    </div>
  );
};

RadioButtonGroup.propTypes = {
  options: arrayOf(
    shape({
      id: string.isRequired,
      value: string.isRequired,
      name: string.isRequired,
      text: string.isRequired,
      link: element,
    }),
  ),
  className: string,
  onChange: func,
  defaultOption: string,
};

RadioButtonGroup.defaultProps = {
  options: [],
  className: "",
  onChange: () => {},
  defaultOption: "",
};

export default RadioButtonGroup;
