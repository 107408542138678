/* eslint-disable */
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useContext,
  useEffect,
  MutableRefObject,
} from "react";
import { func, number, string } from "prop-types";
import Link from "../common/link/link";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import { maskEmail } from "../../../utilities";
import BannerNotification from "../common/banner/bannerNotification";
import Logger from "../../../logger";
import styles from "./accountVerification.module.scss";
import confirmIdentityStyles from "./confirmIdentity.module.scss";
import { SessionContext } from "../../../context/sessionContext";
import RadioButtonGroup from "../common/radio-button-group/radioButtonGroup";
import {
  AUTHENTICATOR_CHECK_SECRET_EXISTS_ERROR,
  START_AUTHENTICATOR_2FA_FLOW,
  TWO_FA_AUTHENTICATOR_FLOW_ERROR_EVENT,
  VERIFY_2FA_FLOW_CANCEL_EVENT,
  VERIFY_2FA_FLOW_EVENT,
} from "../../../constants/BusinessSplunkEvent";
import {
  AUTHENTICATOR_OPTION,
  EMAIL_OPTION,
} from "../../../constants/EmailAndAuth";
import { generateMFA } from "../../services/generateMFA";
import { checkCustomerAuthenticatorSecretExists } from "../../services/checkCustomerAuthenticatorSecretExists";

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface ConfirmIdentityProps {
  setSubFlow: (value: any) => void;
  email: string;
  partyId: number;
  setSelectedOption: (value: any) => void;
  selectedOption: string;
  setUserAuthInfo: (value: any) => void;
  loginAuthenticatorCode: string;
  generateSecret: () => void;
}

const getUpdateEmailLink = (
  setSubFlow: (value: any) => void,
  sessionDetails: SessionDetails,
  fontSize: string,
  className: string
) => {
  return (
    <div className={className}>
      <Link
        value={i18n.t(rk.LINK_UPDATE_EMAIL_ADDRESS)}
        onClick={() => {
          Logger.info(
            "Initiate Update Email Flow",
            "Location => ConfirmIdentity Screen",
            sessionDetails
          );
          setSubFlow(i18n.t(rk.UPDATE_EMAIL_FLOW));
        }}
        fontSize={fontSize}
        ariaLabel="Update email address"
        testid="update-email-address-link"
      />
    </div>
  );
};

const getConfirmIdentityOptions = (
  onChange: (value: any) => void,
  setSubFlow: (value: any) => void,
  sessionDetails: SessionDetails,
  email: string,
  selectedOption: string
) => {
  const options = [
    {
      id: EMAIL_OPTION,
      name: EMAIL_OPTION,
      value: EMAIL_OPTION,
      text: `${i18n.t(rk.TEXT_CONFIRM_IDENTITY_EMAIL_OPTION)} ${maskEmail(email)?.toLowerCase()}`,
      link: getUpdateEmailLink(
        setSubFlow,
        sessionDetails,
        "14px",
        confirmIdentityStyles["email-link-no-bold"]
      ),
    },
    {
      id: AUTHENTICATOR_OPTION,
      name: AUTHENTICATOR_OPTION,
      value: AUTHENTICATOR_OPTION,
      text: i18n.t(rk.TEXT_CONFIRM_IDENTITY_AUTHENTICATOR_OPTION),
    },
  ];
  return (
    <RadioButtonGroup
      className={confirmIdentityStyles["confirm-identity-options"]}
      options={options}
      onChange={onChange}
      defaultOption={selectedOption}
    />
  );
};

const getDescription = (
  isAudi: boolean,
  isEmailAndAuthEnabled: boolean,
  email: string
) => {
  if (isEmailAndAuthEnabled) {
    return (
      <>
        <p
          className={`${isEmailAndAuthEnabled && confirmIdentityStyles["text-1"]}`}
        >
          {i18n.t(rk.TEXT_1_CONFIRM_IDENTITY_EMAIL_AND_AUTHENTICATOR)}
        </p>
        <p
          className={`${isEmailAndAuthEnabled && confirmIdentityStyles["text-2"]}`}
        >
          {i18n.t(rk.TEXT_2_CONFIRM_IDENTITY_EMAIL_AND_AUTHENTICATOR)}
        </p>
      </>
    );
  }
  return (
    <p className={isAudi && styles["confirm-text"]}>
      {i18n.t(rk.TEXT_CONFIRM_IDENTITY)}
      <span
        className={styles["masked-email"]}
      >{`${maskEmail(email)?.toLowerCase()}${i18n.t(rk.CHAR_DOT)}`}</span>
    </p>
  );
};

const ConfirmIdentity = React.forwardRef(
  (
    {
      setSubFlow,
      email,
      partyId,
      setSelectedOption,
      selectedOption,
      setUserAuthInfo,
      loginAuthenticatorCode,
      generateSecret,
    }: ConfirmIdentityProps,
    ref: MutableRefObject<any>
  ) => {
    const { sessionDetails } = useContext(SessionContext);
    const [apiError, setApiError] = useState(false);
    const isCanada = sessionDetails?.country?.toLowerCase() === "canada";
    const isEmailAndAuthEnabled =
      process.env.REACT_APP_ENABLE_EMAIL_AND_AUTH === "true" &&
      sessionDetails?.country?.toLowerCase() === "usa" &&
      sessionDetails?.brand?.toLowerCase() === "vw";

    useEffect(() => {
      if (isEmailAndAuthEnabled)
        Logger.info(
          VERIFY_2FA_FLOW_EVENT,
          "Location => ConfirmIdentity Screen",
          sessionDetails
        );
    }, []);
    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              "Exited MFA Flow in Step 1 by clicking back button on the browser tab",
              "Location ==> /register",
              sessionDetails
            );
          }
          if (isEmailAndAuthEnabled) {
            Logger.info(
              VERIFY_2FA_FLOW_CANCEL_EVENT,
              "Location => ConfirmIdentity Screen",
              sessionDetails
            );
          }
        },
        false
      );
    }, []);
    useEffect(() => {
      window.addEventListener("beforeunload", (e) => {
        Logger.info(
          "Exited MFA Flow in Step 1 by closing the browser tab",
          "Location ==> /register",
          sessionDetails
        );
        if (isEmailAndAuthEnabled) {
          Logger.info(
            VERIFY_2FA_FLOW_CANCEL_EVENT,
            "Location => ConfirmIdentity Screen",
            sessionDetails
          );
        }
      });
    }, []);
    const body = {
      partyId,
      sessionDetails,
    };
    async function onSubmit() {
      const { data, error } = await generateMFA(body);
      if (data?.isSuccess) {
        Logger.info(
          "MFA code generation successful",
          "Location ==> /login",
          sessionDetails,
          "",
          partyId
        );
        return true;
      }
      if (data?.errors) {
        setApiError(true);
        Logger.error(
          "CustomerMFAGenerate API Error",
          "Location ==> /login",
          data?.errors,
          sessionDetails,
          "",
          partyId
        );
        return false;
      }
      if (error) {
        Logger.error(
          "CustomerMFAGenerate API Error",
          "Location ==> /login",
          error,
          sessionDetails,
          "",
          partyId
        );
        setApiError(true);
        return false;
      }
      return false;
    }

    const checkIfCustomerAuthenticatorSecretExists = async () => {
      const body = {
        loginAuthenticatorCode,
        partyId,
        sessionDetails,
      };
      const { data, error } =
        await checkCustomerAuthenticatorSecretExists(body);
      if (data?.isSuccess) {
        Logger.info(
          "checkIfCustomerAuthenticatorSecretExists successful",
          "Location ==> /login",
          sessionDetails,
          "",
          partyId
        );
        return {
          doesSecretExist: data?.doesSecretExist,
          hasApiError: false,
        };
      }
      if (data?.errors) {
        setApiError(true);
        Logger.info(
          AUTHENTICATOR_CHECK_SECRET_EXISTS_ERROR,
          "Location => ConfirmIdentity Screen",
          sessionDetails
        );
        Logger.info(
          TWO_FA_AUTHENTICATOR_FLOW_ERROR_EVENT,
          "Location => ConfirmIdentity Screen",
          sessionDetails
        );
        Logger.error(
          "checkIfCustomerAuthenticatorSecretExists API Error",
          "Location ==> /login",
          data?.errors,
          sessionDetails,
          "",
          partyId
        );
        return {
          doesSecretExist: false,
          hasApiError: true,
        };
      }
      if (error) {
        Logger.info(
          AUTHENTICATOR_CHECK_SECRET_EXISTS_ERROR,
          "Location => ConfirmIdentity Screen",
          sessionDetails
        );
        Logger.info(
          TWO_FA_AUTHENTICATOR_FLOW_ERROR_EVENT,
          "Location => ConfirmIdentity Screen",
          sessionDetails
        );
        Logger.error(
          "checkIfCustomerAuthenticatorSecretExists API Error",
          "Location ==> /login",
          error,
          sessionDetails,
          "",
          partyId
        );
        setApiError(true);
        return {
          doesSecretExist: false,
          hasApiError: true,
        };
      }
      Logger.info(
        AUTHENTICATOR_CHECK_SECRET_EXISTS_ERROR,
        "Location => ConfirmIdentity Screen",
        sessionDetails
      );
      Logger.info(
        TWO_FA_AUTHENTICATOR_FLOW_ERROR_EVENT,
        "Location => ConfirmIdentity Screen",
        sessionDetails
      );
      setApiError(true);
      return {
        doesSecretExist: false,
        hasApiError: true,
      };
    };

    const onAuthenticatorSubmit = async () => {
      const { doesSecretExist, hasApiError } =
        await checkIfCustomerAuthenticatorSecretExists();

      if (hasApiError) {
        return false;
      }

      if (doesSecretExist) {
        Logger.info(
          TWO_FA_AUTHENTICATOR_FLOW_ERROR_EVENT,
          "Location => ConfirmIdentity Screen",
          sessionDetails
        );
        setUserAuthInfo((prevState: any) => ({
          ...prevState,
          doesSecretExist,
        }));
        setApiError(false);
        return true;
      }

      // When doesSecretExist is false generate new secret
      const isCodeGenerated = await generateSecret();

      return isCodeGenerated;
    };

    async function verifyUserAction() {
      if (isEmailAndAuthEnabled && selectedOption === AUTHENTICATOR_OPTION) {
        Logger.info(
          START_AUTHENTICATOR_2FA_FLOW,
          "Location => ConfirmIdentity Screen",
          sessionDetails
        );
        return onAuthenticatorSubmit();
      }
      return onSubmit();
    }
    useImperativeHandle(ref, () => ({ verifyUserAction }));
    const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
    const isVF =
      sessionDetails?.brand?.toLowerCase() === "vw" &&
      sessionDetails?.country?.toLowerCase() === "canada";

    const handleConfirmIdentityOptionsChange = (selectedValue: string) => {
      setSelectedOption(selectedValue);
    };

    return (
      <div
        className={
          isEmailAndAuthEnabled && confirmIdentityStyles["confirm-identity"]
        }
      >
        {isVF && (
          <p className="title-vf u-mb-xsmall">
            {i18n.t(rk.TITLE_ACCOUNT_VERIFICATION)}
          </p>
        )}
        {apiError && (
          <div className={styles.bannerNotification}>
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.ERROR_API_SERVICE_DOWN)}
            </BannerNotification>
          </div>
        )}
        {isAudi ? (
          <p className={`${styles["sub-heading-audi"]} u-mt-xlarge`}>
            {i18n.t(rk.TITLE_CONFIRM_IDENTITY)}
          </p>
        ) : (
          <p
            className={`${styles["sub-heading"]} ${isEmailAndAuthEnabled && confirmIdentityStyles["sub-heading"]}`}
          >
            {!isCanada && i18n.t(rk.TITLE_CONFIRM_IDENTITY)}
          </p>
        )}
        {getDescription(isAudi, isEmailAndAuthEnabled, email)}
        {isEmailAndAuthEnabled
          ? getConfirmIdentityOptions(
              handleConfirmIdentityOptionsChange,
              setSubFlow,
              sessionDetails,
              email,
              selectedOption
            )
          : getUpdateEmailLink(
              setSubFlow,
              sessionDetails,
              "12px",
              isAudi ? styles["audi-email-link"] : styles["email-link"]
            )}
        <style>{`
            .title-vf {
              font-size: 32px;
            }
        `}</style>
      </div>
    );
  }
);

export default ConfirmIdentity;

ConfirmIdentity.propTypes = {
  setSubFlow: func,
  email: string,
  partyId: number,
  setSelectedOption: func,
  selectedOption: string,
  loginAuthenticatorCode: string,
  setUserAuthInfo: func,
  generateSecret: func.isRequired,
};

ConfirmIdentity.defaultProps = {
  setSubFlow: () => {},
  email: "",
  partyId: undefined,
  setSelectedOption: () => {},
  selectedOption: "",
  loginAuthenticatorCode: "",
  setUserAuthInfo: () => {},
};
