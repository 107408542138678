import React, { useContext } from "react";
import Layout from "./common/layout/layout";
import Logo from "./common/logo/logo";
import Link from "./common/link/link";
import i18n from "../../localization/i18n";
import * as rk from "../../localization/resourceKeys";
import styles from "./maintenance.module.scss";
import BannerNotification from "./common/banner/bannerNotification";
import Logger from "../../logger";
import { SessionContext } from "../../context/sessionContext";

const Maintenance = () => {
  const { sessionDetails } = useContext(SessionContext);
  const country = sessionDetails?.country?.toLowerCase();
  const brand = sessionDetails?.brand?.toLowerCase();
  const renderImage = () => {
    if (brand === "audi" && country === "canada") {
      return "./images/audi_canada/login.jpg";
    } else if (brand === "vw" && country === "usa") {
      return "./images/vw_usa/login.jpg";
    } else if (brand === "vw" && country === "canada") {
      return "./images/vw_canada/login.png";
    }
  }

  return (
    <div className="maintenance-container">
      <Layout imgSource={renderImage()}>
        <div className={styles["login-container"]}>
          <div className={styles["logo-login-screen"]}>
            <Logo />
          </div>
          <h3 className={styles["c-section-heading__title"]}>
            <span className={styles["c-section-heading__title-text"]}>
              {i18n.t(rk.TITLE_WELCOME)}
            </span>
            <span className={styles["c-section-heading__title-text"]}>
              {i18n.t(rk.TITLE_VW)}
            </span>
          </h3>
          <div className={styles["maintenance-banner"]}>
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.TEXT_MAINTENANCE_MODE)}
            </BannerNotification>
          </div>
          <div className={styles["card-container"]}>
            <dl className="c-dl-split c-dl-split--responsive">
              <dt className={styles["car-net-card-container"]}>
                <article className="c-card">
                  <div className={styles["car-net-card"]}>
                    <p>{i18n.t(rk.TEXT_LOOKING_FOR)}</p>
                    <p>{i18n.t(rk.TEXT_CAR_NET_LOGIN_PAGE)}</p>
                    <div className={styles["card-link"]}>
                      <Link
                        ariaLabel="Car Net login link"
                        target="_blank"
                        href={i18n.t(rk.LINK_CAR_NET_LOGIN_PAGE)}
                        value={i18n.t(rk.LABEL_EXT_LOGIN_LINK)}
                        icon="c-icon--[semantic-arrow-right]"
                        onClick={() =>
                          Logger.info(
                            "Clicked Car-Net login or create account link",
                            "Location ==> /login",
                            sessionDetails,
                          )
                        }
                        testid="car-net-login-link"
                      />
                    </div>
                  </div>
                </article>
              </dt>
              <dd />
              <dt className={styles["id-card-container"]}>
                <article className="c-card">
                  <div className={styles["id-card"]}>
                    <p>{i18n.t(rk.TEXT_LOOKING_FOR)}</p>
                    <p>{i18n.t(rk.TEXT_ID_LOGIN_PAGE)}</p>
                    <div className={styles["card-link"]}>
                      <Link
                        ariaLabel="ID.4 login link"
                        target="_blank"
                        href={i18n.t(rk.LINK_ID_LOGIN_PAGE)}
                        value={i18n.t(rk.LABEL_EXT_LOGIN_LINK)}
                        icon="c-icon--[semantic-arrow-right]"
                        onClick={() =>
                          Logger.info(
                            "Clicked ID.4 login or create account link",
                            "Location ==> /login",
                            sessionDetails,
                          )
                        }
                        testid="id-login-link"
                      />
                    </div>
                  </div>
                </article>
              </dt>
              <dd />
            </dl>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Maintenance;
