import React from "react";

interface LoaderProps {
  isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = (
  props,
): JSX.Element | null | React.ReactElement => {
  const { isLoading } = props;
  return (
    <div
      className="spinner c-spinner c-spinner--full-page "
      aria-live="polite"
      aria-busy={isLoading}
    >
      <div className="c-spinner__loader">
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
        <span className="c-spinner__element" />
      </div>
    </div>
  );
};

export default Loader;
