/* eslint-disable import/prefer-default-export */
import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  username: string;
  ssn: string;
  dateOfBirth: string;
  challengeQuestion: string;
  challengeAnswer: string;
  sessionDetails: SessionDetails;
}

interface RequestType {
  UserName: string;
  Country: string;
  CorrelationId: string;
  Brand: string;
  SourceApp: string;
  ParentFunctionName: string;
  ProcessName: string;
  WidgetName: string;
  DoB?: string;
  Last4UniqueIdentifier?: string;
  UniqueIdentifier?: string;
}

const gqlRequest = gql`
  query VerifyUserCustomerForgotPassword(
    $Request: VerifyUserCustomerForgotPasswordRequest!
  ) {
    verifyUserCustomerForgotPassword(Request: $Request) {
      IsSuccess
      PartyId
      ChallengeQuestion
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

export const resetPswdVerify = async (payload: Payload) => {
  const { username, ssn, dateOfBirth, sessionDetails } = payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request: RequestType = {
      UserName: username,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      SourceApp: sessionDetails.source,
      ParentFunctionName: "Reset Password Verify",
      ProcessName: "Reset Password Verify",
      WidgetName: sessionDetails.source,
    };
    if (sessionDetails?.country?.toLowerCase() === "canada") {
      request.DoB = `${dateOfBirth}`;
    } else {
      request.UniqueIdentifier = ssn;
    }
    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });

    if (response?.data?.data?.verifyUserCustomerForgotPassword?.IsSuccess) {
      return {
        data: {
          isSuccess: true,
          challengeQuestion:
            response?.data?.data?.verifyUserCustomerForgotPassword
              ?.ChallengeQuestion,
          partyId:
            response?.data?.data?.verifyUserCustomerForgotPassword?.PartyId,
          errors:
            response?.data?.data?.verifyUserCustomerForgotPassword
              ?.Errors?.[0] || "",
        },
      };
    }
    if (
      !response?.data?.data?.verifyUserCustomerForgotPassword?.IsSuccess ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: false,
          errors:
            response?.data?.data?.verifyUserCustomerForgotPassword
              ?.Errors?.[0] || response?.data?.errors,
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};
