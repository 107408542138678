// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZyGe34exlK4noYuTBTHA{display:grid;grid-template-columns:6fr 1fr}@media screen and (max-width: 411px){.ZyGe34exlK4noYuTBTHA{padding-left:0px !important;padding-right:0px !important;transform:translateX(-2%)}}.zcxMwQw3SjaGayav8EXk{font-size:12px !important;color:#000;line-height:24px;margin-bottom:20px}`, "",{"version":3,"sources":["webpack://./src/components/common/footer/footer.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,6BAAA,CACA,qCAHJ,sBAIQ,2BAAA,CACA,4BAAA,CACA,yBAAA,CAAA,CAKR,sBACI,yBAAA,CACA,UAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":[".footer-wrapper {\n    display: grid;\n    grid-template-columns: 6fr 1fr;\n    @media screen and (max-width: 411px) {\n        padding-left: 0px !important;\n        padding-right: 0px !important;\n        transform: translateX(-2%);\n    }\n\n}\n\n.footer-text {\n    font-size: 12px !important;\n    color: #000;\n    line-height: 24px;\n    margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer-wrapper": `ZyGe34exlK4noYuTBTHA`,
	"footer-text": `zcxMwQw3SjaGayav8EXk`
};
export default ___CSS_LOADER_EXPORT___;
