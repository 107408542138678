// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kdVgpPGcL0KogOYNkv7G{padding-bottom:100px}.MUmhvMTcYMoSlwe3UsY7{display:grid;font-size:32px;padding-bottom:20px}.lqvyGAFxY10oX8nZihNH{font-size:16px}.mF7IJd3bp6QOVe9gPkQc{display:block;font-size:14px;margin-top:10px}.YHtfyiLeBylfHBo_NbnA{font-weight:400 !important}`, "",{"version":3,"sources":["webpack://./src/pages/retrieveUsername.module.scss"],"names":[],"mappings":"AAAA,sBACI,oBAAA,CAEJ,sBACI,YAAA,CACA,cAAA,CACA,mBAAA,CAEJ,sBACI,cAAA,CAEJ,sBACI,aAAA,CACA,cAAA,CACA,eAAA,CAGJ,sBACI,0BAAA","sourcesContent":[".logo-container{\n    padding-bottom: 100px;\n}\n.c-section-heading__title {\n    display: grid;\n    font-size: 32px;\n    padding-bottom:20px\n}\n.retrieve-username__infotext{\n    font-size:16px;\n}\n.retrieve-username__fieldsrequired{\n    display:block;\n    font-size: 14px;\n    margin-top:10px;\n}\n\n.title-vwc {\n    font-weight: 400 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo-container": `kdVgpPGcL0KogOYNkv7G`,
	"c-section-heading__title": `MUmhvMTcYMoSlwe3UsY7`,
	"retrieve-username__infotext": `lqvyGAFxY10oX8nZihNH`,
	"retrieve-username__fieldsrequired": `mF7IJd3bp6QOVe9gPkQc`,
	"title-vwc": `YHtfyiLeBylfHBo_NbnA`
};
export default ___CSS_LOADER_EXPORT___;
