import React, { useState, useRef, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Layout from "../components/common/layout/layout";
import Logger from "../../logger";
import i18n from "../../localization/i18n";
import * as rk from "../../localization/resourceKeys";
import StepFlow from "../components/common/stepflow/stepFlow";
import Loader from "../components/common/loader/loader";
import VerifyChallenge from "../components/retrieveUsername/verifyChallenge";
import Verify from "../components/retrieveUsername/verify";
import Confirmation from "../components/retrieveUsername/confirmation";
import Maintenance from "../components/maintenance";
import { SessionContext } from "../../context/sessionContext";
import "./retrieveUsername.module.scss";

interface MaintenanceProps {
  inMaintenanceMode?: string;
}

interface RetrieveUsernameProps {
  currentStep: number;
  currentStepIsCancel: boolean;
  maintenance: MaintenanceProps;
}

const RetrieveUsername: React.FC<RetrieveUsernameProps> = ({
  currentStep,
  currentStepIsCancel,
  maintenance,
}) => {
  const { sessionDetails } = useContext(SessionContext);
  const inMaintenanceMode = maintenance?.inMaintenanceMode;
  const ref = useRef(null);
  const navigate = useNavigate();
  const initialState = {
    name: "RetrieveUsernameFlow",
    currentStep: currentStep || 1,
    previousStep: -1,
    currentStepIsCancel: currentStepIsCancel || true,
    maxSteps: 3,
    submitPending: false,
  };
  const country = sessionDetails?.country?.toLowerCase();
  const brand = sessionDetails?.brand?.toLowerCase();
  const isVW = sessionDetails?.brand?.toLowerCase() === "vw";
  const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
  const isVWCanada = isVW && country === "canada";

  // keep track of the state of the flow
  const [state, setState] = useState(initialState);
  const [username, setUsername] = useState();
  const [ssn, setSSN] = useState();
  const [partyId, setPartyId] = useState();
  const [zipCode, setZipcode] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [challengeQ, setChallengeQ] = useState();
  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  useEffect(() => {
    if (state.currentStep !== 2)
      document.getElementsByClassName("page-content")[0]?.scrollTo(0, 0);
  }, [state.currentStep]);

  const renderImage = () => {
    if (brand === "audi" && country === "canada") {
      return "./images/audi_canada/username.jpg";
    }
    if (brand === "vw" && country === "usa") {
      if (state.currentStep === 1) {
        return "./images/vw_usa/username_1.jpg";
      }
      if (state.currentStep === 2) {
        return "./images/vw_usa/username_2.jpg";
      }
      if (state.currentStep === 3) {
        return "./images/vw_usa/username_3.jpg";
      }
    }
    if (brand === "vw" && country === "canada") {
      return "./images/vw_canada/username.png";
    }
  };
  return inMaintenanceMode === "Y" ? (
    <Maintenance />
  ) : (
    <div
      className={`retrieve-username-container ${state.currentStep === 3 && "retrieve-username-confirmation-container"}`}
    >
      <Layout imgSource={renderImage()} wrapperClass="retrieve-username-image">
        <StepFlow
          step={1}
          currentStep={state.currentStep}
          title={
            <h2 className={`${isVWCanada ? "title-vwc" : ""}`}>
              {i18n.t(rk.TITLE_RETRIEVE_USERNAME)}
            </h2>
          }
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          previousAction={() => {
            setState({
              ...state,
              currentStep: state.currentStep + 1,
              previousStep: 1,
            });
            Logger.info(
              "Clicked Continue in Retrieve Username Flow Step 1",
              "Location ==> /retrieveUsername",
              sessionDetails,
            );
          }}
          nextLabel={i18n.t(rk.BUTTON_CANCEL)}
          nextAction={() => {
            Logger.info(
              "Exited Retrieve Username Flow in Step 1",
              "Location ==> /retrieveUsername",
              sessionDetails,
            );
            navigate("/login");
          }}
          canTakePreviousAction={async () => {
            setState({ ...state, submitPending: true });
            const verifyUser = await ref.current.verifyUserAction();
            setState({ ...state, submitPending: false });
            const canTakeAction = verifyUser;
            return canTakeAction;
          }}
          previousLabelDisabled={!isContinueEnabled}
        >
          <Loader isLoading={state.submitPending} />
          <Verify
            ref={ref}
            setSSN={(value) => setSSN(value)}
            setAccountNumber={(value) => setAccountNumber(value)}
            setZipcode={(value) => setZipcode(value)}
            setPartyId={(value) => setPartyId(value)}
            setChallengeQ={(value) => setChallengeQ(value)}
            setDateOfBirth={(value) => setDateOfBirth(value)}
            setIsContinueEnabled={(value) => setIsContinueEnabled(value)}
          />
        </StepFlow>
        <StepFlow
          step={2}
          currentStep={state.currentStep}
          title={<h2>{i18n.t(rk.TITLE_RETRIEVE_USERNAME)}</h2>}
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          previousAction={() => {
            setState({
              ...state,
              currentStep: state.currentStep + 1,
              previousStep: 1,
            });
            Logger.info(
              "Clicked Continue in Retrieve Username Flow Step 2",
              "Location ==> /retrieveUsername",
              sessionDetails,
            );
          }}
          nextLabel={i18n.t(rk.BUTTON_CANCEL)}
          nextAction={() => {
            Logger.info(
              "Exited Retrieve Username Flow in Step 2",
              "Location ==> /retrieveUsername",
              sessionDetails,
            );
            navigate("/login");
          }}
          canTakePreviousAction={async () => {
            setState({ ...state, submitPending: true });
            const canTakeAction = await ref.current.verifyUserAction();
            setState({ ...state, submitPending: false });
            return canTakeAction;
          }}
          previousLabelDisabled={!isContinueEnabled}
        >
          <Loader isLoading={state.submitPending} />
          <VerifyChallenge
            ref={ref}
            setUsername={(value) => setUsername(value)}
            setIsContinueEnabled={(value) => setIsContinueEnabled(value)}
            ssn={ssn}
            partyId={partyId}
            zipCode={zipCode}
            challengeQuestion={challengeQ}
            dateOfBirth={dateOfBirth}
            accountNumber={accountNumber}
          />
        </StepFlow>
        <StepFlow
          step={3}
          currentStep={state.currentStep}
          title={
            <h2 className={`${isVWCanada ? "title-vwc" : ""}`}>
              {isVWCanada
                ? i18n.t(rk.TITLE_RETRIEVE_USERNAME_VF)
                : i18n.t(rk.TITLE_RETRIEVE_USERNAME)}
            </h2>
          }
          previousLabel={
            isAudi ? i18n.t(rk.BUTTON_LOGIN) : i18n.t(rk.TITLE_LOGIN)
          }
          previousAction={() => {
            navigate("/login");
          }}
          previousLabelDisabled={!isContinueEnabled}
        >
          <Confirmation userName={username} />
        </StepFlow>
      </Layout>
    </div>
  );
};

RetrieveUsername.propTypes = {
  currentStep: PropTypes.number,
  currentStepIsCancel: PropTypes.bool,
  maintenance: PropTypes.shape({
    inMaintenanceMode: PropTypes.string,
  }),
};

RetrieveUsername.defaultProps = {
  currentStep: undefined,
  currentStepIsCancel: undefined,
  maintenance: {
    inMaintenanceMode: "",
  },
};

export default RetrieveUsername;
