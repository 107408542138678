import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  email: string;
  accountNumber: string;
  ssn: string;
  dob?: string;
  partyId: number;
  sessionDetails: SessionDetails;
}
interface RequestType {
  AccountNumber: string;
  PartyId: string | number;
  Email: string;
  Country: string;
  CorrelationId: string;
  Brand: string;
  Source: string;
  SourceApp: string;
  ParentFunctionName: string;
  ProcessName: string;
  WidgetName: string;
  DoB?: string;
  Last4UniqueIdentifier?: string;
}

const gqlRequest = gql`
  mutation UpdateCustomerEmail($Request: UpdateCustomerEmailRequest!) {
    updateCustomerEmail(Request: $Request) {
      IsSuccess
      LoginAttemptCount
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

const updateEmail = async (payload: Payload) => {
  const { accountNumber, ssn, email, partyId, dob, sessionDetails } = payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request: RequestType = {
      AccountNumber: accountNumber,
      PartyId: partyId,
      Email: email,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      Source: sessionDetails.source,
      SourceApp: sessionDetails.source,
      ParentFunctionName: "UpdateCustomerEmail",
      ProcessName: "UpdateCustomerEmail",
      WidgetName: sessionDetails.source,
    };
    if (sessionDetails?.country?.toLowerCase() === "canada") {
      request.DoB = dob;
    } else {
      request.Last4UniqueIdentifier = ssn;
    }

    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (response?.data?.data?.updateCustomerEmail?.IsSuccess) {
      return {
        data: {
          isSuccess: response?.data?.data.updateCustomerEmail?.IsSuccess,
        },
      };
    }
    if (response?.data?.data?.updateCustomerEmail?.LoginAttemptCount) {
      return {
        data: {
          isSuccess: false,
          loginAttemptCount:
            response?.data?.data?.updateCustomerEmail?.LoginAttemptCount,
        },
      };
    }
    if (
      response?.data?.data?.updateCustomerEmail?.Errors ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: false,
          errors:
            response?.data?.data?.updateCustomerEmail?.Errors?.[0] ||
            response?.data?.errors,
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};

export default updateEmail;
