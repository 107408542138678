// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yuEoVeMUBl2x6NHqYGq6{max-width:unset}.LVwDs0J6Wj3lVEPJBoWh{display:flex}.m3gF_lsYbXFefRI64ost{font-weight:bold;color:#fa2b2b !important;margin-bottom:-9px;font-family:"AudiTypeExtended",sans-serif}.GTO8tgnYMxx8uvxHekdf{width:85px;margin-left:20px;padding-top:4px}.vm6oDj27kECXvtV89oQU,.HsA6WhRbW5Bw7SnGi8Xl{font-family:"AudiTypeExtended",sans-serif}.vm6oDj27kECXvtV89oQU{margin-bottom:-9px}.HsA6WhRbW5Bw7SnGi8Xl{margin-bottom:-7px}.cOeGIt7PIdX3PQIqdOqu{padding-top:50px;width:180px}.ZeZqy7VHzRzssjHKGMHQ{padding-top:50px}`, "",{"version":3,"sources":["webpack://./src/components/common/logo/logo.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAEF,sBACE,YAAA,CAEF,sBACE,gBAAA,CACA,wBAAA,CACA,kBAAA,CACA,yCAAA,CAEF,sBACE,UAAA,CACA,gBAAA,CACA,eAAA,CAGF,4CACE,yCAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,kBAAA,CAGF,sBACE,gBAAA,CACA,WAAA,CAGF,sBACE,gBAAA","sourcesContent":[".c-logo__image-audi {\n  max-width: unset;\n}\n.logo-audi-container {\n  display: flex;\n}\n.logo-audi {\n  font-weight: bold;\n  color: rgb(250, 43, 43) !important;\n  margin-bottom: -9px;\n  font-family: \"AudiTypeExtended\",sans-serif;\n}\n.logo-img-audi {\n  width: 85px;\n  margin-left: 20px;\n  padding-top: 4px;\n}\n\n.text-brand, .text-brand-fr {\n  font-family: \"AudiTypeExtended\",sans-serif;\n}\n\n.text-brand {\n  margin-bottom: -9px;\n}\n\n.text-brand-fr {\n  margin-bottom: -7px;\n}\n\n.logo_vf_wrapper {\n  padding-top: 50px;\n  width: 180px;\n}\n\n.logo_vwfs_wrapper {\n  padding-top: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"c-logo__image-audi": `yuEoVeMUBl2x6NHqYGq6`,
	"logo-audi-container": `LVwDs0J6Wj3lVEPJBoWh`,
	"logo-audi": `m3gF_lsYbXFefRI64ost`,
	"logo-img-audi": `GTO8tgnYMxx8uvxHekdf`,
	"text-brand": `vm6oDj27kECXvtV89oQU`,
	"text-brand-fr": `HsA6WhRbW5Bw7SnGi8Xl`,
	"logo_vf_wrapper": `cOeGIt7PIdX3PQIqdOqu`,
	"logo_vwfs_wrapper": `ZeZqy7VHzRzssjHKGMHQ`
};
export default ___CSS_LOADER_EXPORT___;
