/* eslint-disable import/prefer-default-export */
import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  username: string;
  accountNumber: string;
  ssn: string;
  dob: string;
  sessionDetails: SessionDetails;
}

interface RequestType {
  AccountNumber: string;
  UserName: string;
  Country: string;
  CorrelationId: string;
  Brand: string;
  SourceApp: string;
  ParentFunctionName: string;
  ProcessName: string;
  WidgetName: string;
  DoB?: string;
  Last4UniqueIdentifier?: string;
}

const gqlRequest = gql`
  query UpdateCustomerEmailVerify($Request: VerifyCustomerEmailUpdateRequest!) {
    verifyCustomerEmailUpdate(Request: $Request) {
      IsSuccess
      IsVerified
      PartyId
      LoginAttemptCount
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

export const verifyEmail = async (payload: Payload) => {
  const { accountNumber, ssn, dob, username, sessionDetails } = payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request: RequestType = {
      AccountNumber: accountNumber,
      UserName: username,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      SourceApp: sessionDetails.source,
      ParentFunctionName: "UpdateCustomerEmailVerify",
      ProcessName: "UpdateCustomerEmailVerify",
      WidgetName: sessionDetails.source,
    };
    if (sessionDetails?.country?.toLowerCase() === "canada") {
      request.DoB = dob;
    } else {
      request.Last4UniqueIdentifier = ssn;
    }

    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (response?.data?.data?.verifyCustomerEmailUpdate?.IsSuccess) {
      return {
        data: {
          isSuccess: true,
          isVerified:
            response?.data?.data.verifyCustomerEmailUpdate?.IsVerified,
          partyId: response?.data?.data?.verifyCustomerEmailUpdate?.PartyId,
        },
      };
    }
    if (response?.data?.data?.verifyCustomerEmailUpdate?.LoginAttemptCount) {
      return {
        data: {
          loginAttemptCount:
            response?.data?.data?.verifyCustomerEmailUpdate?.LoginAttemptCount,
        },
      };
    }
    if (
      response?.data?.data?.verifyCustomerEmailUpdate?.Errors ||
      response?.data?.data === null
    ) {
      return {
        data: {
          errors:
            response?.data?.data?.verifyCustomerEmailUpdate?.Errors?.[0] ||
            response?.data?.errors,
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};
