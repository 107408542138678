// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QaTpTYdied9TlhrCluUZ{margin-top:10px}.W5N72A861GsRJ0bkuj5g{margin:50px 0px 43px 0px}.qaZCuYLo_hK9BPsYu3XA{padding-top:96px}.abIYljvHlgiELd_5uzha{font-weight:500 !important}.EN0GmJauZkDoSMWz6K0V input{border-bottom:solid 1px #e4002c !important}.va4nkcLDmpoFAkbpeVvs input{background-color:#fff;color:#96a3a8 !important}`, "",{"version":3,"sources":["webpack://./src/components/accountVerification/enterMFACode.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CAGF,sBACE,wBAAA,CAGF,sBACE,gBAAA,CAGF,sBACE,0BAAA,CAIA,4BACE,0CAAA,CAKF,4BACE,qBAAA,CACA,wBAAA","sourcesContent":[".code-container {\n  margin-top: 10px;\n}\n\n.remember-device-container {\n  margin: 50px 0px 43px 0px;\n}\n\n.enter-code {\n  padding-top: 96px;\n}\n\n.banner-notification-title{\n  font-weight: 500!important;\n}\n\n.input-error {\n  input {\n    border-bottom: solid 1px #e4002c !important;\n  }\n}\n\n.input-disabled {\n  input {\n    background-color: #fff;\n    color: #96a3a8 !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"code-container": `QaTpTYdied9TlhrCluUZ`,
	"remember-device-container": `W5N72A861GsRJ0bkuj5g`,
	"enter-code": `qaZCuYLo_hK9BPsYu3XA`,
	"banner-notification-title": `abIYljvHlgiELd_5uzha`,
	"input-error": `EN0GmJauZkDoSMWz6K0V`,
	"input-disabled": `va4nkcLDmpoFAkbpeVvs`
};
export default ___CSS_LOADER_EXPORT___;
