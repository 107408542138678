/* eslint-disable */
/*
Note: Updated use of ref based on: https://reactjs.org/docs/forwarding-refs.html
*/
import React, { forwardRef, MutableRefObject } from "react";
import { string, bool, func, oneOf } from "prop-types";

interface ButtonProps {
  secondary: boolean;
  onClick: (value: any) => void;
  disabled: boolean;
  value: string;
  id: string;
  testid: string;
  customClass: string;
  wrapperCustomClass: string;
  type: "button" | "submit" | "reset";
}

const Button: React.FC<ButtonProps> = forwardRef(
  (props, ref: MutableRefObject<any>) => {
    /**
     * @type {{
     * secondary: boolean,
     * onClick: () => void,
     * disabled: boolean,
     * value: string,
     * id: string,
     * testid: string,
     * customClass: string,
     * wrapperCustomClass: string,
     * type: 'submit' | 'button'
     * }}
     */
    const {
      secondary,
      onClick,
      disabled,
      value,
      id,
      testid,
      customClass,
      wrapperCustomClass,
      type = "button",
    } = props;
    // const isDisabled: string = disabled ? "disabled" : "";
    return (
      <div className={`sg-component-variant ${wrapperCustomClass}`}>
        <button
          // eslint-disable-next-line react/button-has-type
          type={type}
          ref={ref}
          className={`c-btn  ${secondary && "c-btn--secondary"} ${customClass || ""}`}
          disabled={disabled}
          aria-disabled={disabled}
          onClick={onClick}
          id={id}
          data-testid={testid}
        >
          <span className="c-btn__text">{value}</span>
        </button>
      </div>
    );
  }
);

Button.propTypes = {
  secondary: bool,
  onClick: func,
  disabled: bool,
  value: string,
  id: string,
  testid: string,
  customClass: string,
  wrapperCustomClass: string,
  type: oneOf(["button", "submit"]),
};

Button.defaultProps = {
  secondary: false,
  onClick: () => {},
  disabled: false,
  value: "",
  id: "",
  testid: "",
  customClass: "",
  wrapperCustomClass: "",
  type: "button",
};

export default Button;
