export const START_2FA_FLOW_EVENT = "start2FAFlow";
export const FINISH_2FA_FLOW_EVENT = "finish2FAFlow";
export const VERIFY_2FA_FLOW_EVENT = "verify2FAFlow";
export const START_UPDATE_EMAIL_FLOW_EVENT = "startUpdateEmail2FAFlow";
export const SECTION_1_UPDATE_EMAIL_FLOW_EVENT = "section1UpdateEmail2FAFlow";
export const SECTION_2_UPDATE_EMAIL_FLOW_EVENT = "section2UpdateEmail2FAFlow";
export const FINISH_UPDATE_EMAIL_FLOW_EVENT = "finishUpdateEmail2FAFlow";
export const CANCEL_EMAIL_FLOW_EVENT = "cancelUpdateEmail2FAFlow";
export const BACK_EMAIL_FLOW_EVENT = "backUpdateEmail2FAFlow";
export const VERIFY_2FA_FLOW_CANCEL_EVENT = "verify2FAFlowCancel";
export const AUTHENTICATOR_ENTER_YOUR_CODE_SCREEN_EVENT =
  "authenticatorEnterYourCode2FAFlowScreenEvent";
export const AUTHENTICATOR_ADD_ACCOUNT_SCREEN_EVENT =
  "authenticatorAddAccount2FAFlowScreenEvent";
export const ADD_ACCOUNT_2FA_FLOW_CANCEL_EVENT = "addAccount2FAFlowCancel";
export const ENTER_YOUR_CODE_AUTHENTICATOR_2FA_CANCEL_FLOW =
  "enterYourCodeAuthenticator2FAFlowCancel";
export const TWO_FA_FLOW_ERROR_EVENT = "2FAFlowError";
export const LAUNCH_PORTAL_EVENT = "launchPortal2FAFlow";
export const AUTH_ENTER_YOUR_CODE_2FA_FLOW_CHANGE_VERIFICATION_EVENT =
  "authEnterYourCode2FAFlowChangeVerification";
export const START_AUTHENTICATOR_2FA_FLOW = "startAuthenticator2FAFlow";
export const AUTHENTICATOR_CHECK_SECRET_EXISTS_ERROR =
  "AuthenticatorCheckSecretExistsError";
export const FINISH_AUTHENTICATOR_2FA_FLOW_EVENT = "finishAuthenticator2FAFlow";
export const TWO_FA_AUTHENTICATOR_FLOW_ERROR_EVENT =
  "2FAAuthenticatorFlowError";
export const INVALID_OR_EXPIRED_AUTH_SECURE_CODE =
  "invalidOrExpiredAuthSecureCode";
export const INVALID_OR_EXPIRED_SECURE_CODE = "invalidOrExpiredSecureCode";
export const START_EMAIL_2FA_FLOW = "startEmail2FAFlow";
export const ENTER_YOUR_CODE_2FA_FLOW = "enterYourCode2FAFlow";
export const ENTER_YOUR_CODE_2FA_CANCEL_FLOW = "enterYourCode2FAFlowCancel";
export const AUTHENTICATOR_UPDATE_SECRET_EVENT =
  "authenticatorUpdateSecret2FAFlowEvent";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_ONE =
  "2FAUpdateYourEmailStepOneAuthFailureOne";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_TWO =
  "2FAUpdateYourEmailStepOneAuthFailureTwo";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_THREE =
  "2FAUpdateYourEmailStepOneAuthFailureThree";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_AUTH_FAILURE_FOUR =
  "2FAUpdateYourEmailStepOneAuthFailureFour";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_ONE_ACCOUNT_LOCKED =
  "2FAUpdateYourEmailStepOneAccountLocked";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_ONE =
  "2FAUpdateYourEmailStepTwoAuthFailureOne";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_TWO =
  "2FAUpdateYourEmailStepTwoAuthFailureTwo";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_THREE =
  "2FAUpdateYourEmailStepTwoAuthFailureThree";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_AUTH_FAILURE_FOUR =
  "2FAUpdateYourEmailStepTwoAuthFailureFour";
export const TWO_FA_UPDATE_YOUR_EMAIL_STEP_TWO_ACCOUNT_LOCKED =
  "2FAUpdateYourEmailStepTwoAccountLocked";
export const TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_ONE =
  "2FAEmailEnterYourCodeAuthFailureOne";
export const TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_TWO =
  "2FAEmailEnterYourCodeAuthFailureTwo";
export const TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_THREE =
  "2FAEmailEnterYourCodeAuthFailureThree";
export const TWO_FA_EMAIL_ENTER_YOUR_CODE_AUTH_FAILURE_FOUR =
  "2FAEmailEnterYourCodeAuthFailureFour";
export const TWO_FA_EMAIL_ENTER_YOUR_CODE_ACCOUNT_LOCKED =
  "2FAEmailEnterYourCodeAccountLocked";
export const TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_ONE =
  "2FAAuthenticatorEnterYourCodeAuthFailureOne";
export const TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_TWO =
  "2FAAuthenticatorEnterYourCodeAuthFailureTwo";
export const TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_THREE =
  "2FAAuthenticatorEnterYourCodeAuthFailureThree";
export const TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_AUTH_FAILURE_FOUR =
  "2FAAuthenticatorEnterYourCodeAuthFailureFour";
export const TWO_FA_AUTHENTICATOR_ENTER_YOUR_CODE_ACCOUNT_LOCKED =
  "2FAAuthenticatorEnterYourCodeAccountLocked";
export const TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_ONE =
  "2FAAddAccountAuthFailureOne";
export const TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_TWO =
  "2FAAddAccountAuthFailureTwo";
export const TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_THREE =
  "2FAAddAccountAuthFailureThree";
export const TWO_FA_ADD_ACCOUNT_AUTH_FAILURE_FOUR =
  "2FAAddAccountAuthFailureFour";
export const TWO_FA_ADD_ACCOUNT_ACCOUNT_LOCKED = "2FAAddAccountAccountLocked";
