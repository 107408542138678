/* eslint-disable import/prefer-default-export */
import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  username: string;
  newPswd: string;
  challengeQuestion: string;
  challengeAnswer: string;
  sessionDetails: SessionDetails;
}

const gqlRequest = gql`
  mutation ForgotPassword($Request: SubmitForgotPasswordRequest!) {
    submitForgotPassword(Request: $Request) {
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

export const setPassword = async (payload: Payload) => {
  const {
    username,
    newPswd,
    sessionDetails,
    challengeQuestion,
    challengeAnswer,
  } = payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const language =
      sessionDetails.language === "en-US" || sessionDetails.language === "en-CA"
        ? "English"
        : "French";
    const request = {
      UserName: username,
      Password: newPswd,
      ChallengeQuestion: challengeQuestion,
      ChallengeAnswer: challengeAnswer,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      Language: language,
      Source: sessionDetails.source,
      SourceApp: sessionDetails.source,
      ParentFunctionName: "Reset Password",
      ProcessName: "Reset Password",
      WidgetName: sessionDetails.source,
    };
    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (response?.data?.data?.submitForgotPassword?.IsSuccess) {
      return {
        data: {
          isSuccess: true,
        },
      };
    }
    if (
      !response?.data?.data?.submitForgotPassword?.IsSuccess ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: false,
          error:
            response?.data?.data?.submitForgotPassword?.Errors?.[0] ||
            response?.data?.errors ||
            "",
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};
