import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  source: string;
  country: string;
}

interface Payload {
  username: string;
  password: string;
  sessionDetails: SessionDetails;
  userFingerprint: string;
}

const gqlRequest = gql`
  mutation CustomerLogin($Request: LoginCustomerRequest!) {
    loginCustomer(Request: $Request) {
      IsSuccess
      IsMfaVerified
      DefaultAccountNumber
      Token {
        AccessToken
        ExpiresIn
        TokenType
        RefreshToken
        IdToken
      }
      PartyId
      LoginAttemptCount
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
      Email
    }
  }
`;

const gqlEmailAndAuthEnabledRequest = gql`
  mutation CustomerLogin($Request: LoginCustomerRequest!) {
    loginCustomer(Request: $Request) {
      IsSuccess
      IsMfaVerified
      DefaultAccountNumber
      Token {
        AccessToken
        ExpiresIn
        TokenType
        RefreshToken
        IdToken
      }
      PartyId
      LoginAttemptCount
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
      Email
      LoginAuthenticatorCode
    }
  }
`;

export const login = async (payload: Payload) => {
  const { username, password, sessionDetails, userFingerprint } = payload;
  try {
    const isEmailAndAuthEnabled =
      process.env.REACT_APP_ENABLE_EMAIL_AND_AUTH === "true" &&
      sessionDetails?.country?.toLowerCase() === "usa" &&
      sessionDetails?.brand?.toLowerCase() === "vw";
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request = {
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      Source: sessionDetails.source,
      Country: sessionDetails.country,
      SourceApp: sessionDetails.source,
      ProcessName: "Login",
      ParentFunctionName: "Login",
      WidgetName: sessionDetails.source,
      UserName: username,
      Password: password,
      Fingerprint: `${userFingerprint}`,
    };

    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(
          isEmailAndAuthEnabled ? gqlEmailAndAuthEnabledRequest : gqlRequest,
        ),
        variables: {
          Request: request,
        },
      },
    });
    if (response?.data?.data?.loginCustomer?.IsSuccess) {
      if (response?.data?.data?.loginCustomer?.IsMfaVerified) {
        return {
          data: {
            isSuccess: true,
            isMfaVerified: true,
            token: response?.data?.data?.loginCustomer?.Token?.IdToken,
            accountNumber:
              response?.data?.data?.loginCustomer?.DefaultAccountNumber,
            partyId: response?.data?.data?.loginCustomer?.PartyId,
          },
        };
      }
      if (isEmailAndAuthEnabled) {
        return {
          data: {
            isSuccess: true,
            isMfaVerified: false,
            partyId: response?.data?.data?.loginCustomer?.PartyId,
            email: response?.data?.data?.loginCustomer?.Email,
            loginAuthenticatorCode:
              response?.data?.data?.loginCustomer?.LoginAuthenticatorCode,
          },
        };
      }
      return {
        data: {
          isSuccess: true,
          isMfaVerified: false,
          partyId: response?.data?.data?.loginCustomer?.PartyId,
          email: response?.data?.data?.loginCustomer?.Email,
        },
      };
    }
    if (response?.data?.data?.loginCustomer?.LoginAttemptCount) {
      return {
        data: {
          isSuccess: false,
          loginAttemptCount:
            response?.data?.data?.loginCustomer?.LoginAttemptCount,
        },
      };
    }
    if (
      response?.data?.data?.loginCustomer?.Errors ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: false,
          errors:
            response?.data?.data?.loginCustomer?.Errors?.[0] ||
            response?.data?.errors,
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};

export default login;
