/* eslint-disable import/prefer-default-export */
import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  partyId: number;
  authenticatorCode: string;
  rememberDevice: boolean;
  userFingerprint: string;
  sessionDetails: SessionDetails;
}

const gqlRequest = gql`
  query VerifyCustomerAuthenticatorCode(
    $Request: VerifyCustomerAuthenticatorCodeRequest!
  ) {
    verifyCustomerAuthenticatorCode(Request: $Request) {
      IsSuccess
      IsCodeVerified
      DefaultAccountNumber
      Token {
        AccessToken
        ExpiresIn
        TokenType
        RefreshToken
        IdToken
      }
      PartyId
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
      LoginAttemptCount
    }
  }
`;

export const verifyCustomerAuthenticatorCode = async (payload: Payload) => {
  const {
    authenticatorCode,
    partyId,
    rememberDevice,
    sessionDetails,
    userFingerprint,
  } = payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request = {
      PartyId: partyId,
      AuthenticatorCode: `${authenticatorCode}`,
      Fingerprint: `${userFingerprint}`,
      RememberMe: rememberDevice,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      SourceApp: sessionDetails.source,
      ParentFunctionName: "VerifyAuthenticatorCode",
      ProcessName: "VerifyAuthenticatorCode",
      WidgetName: sessionDetails.source,
    };
    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (
      response?.data?.data?.verifyCustomerAuthenticatorCode?.IsSuccess &&
      response?.data?.data?.verifyCustomerAuthenticatorCode?.IsCodeVerified
    ) {
      return {
        data: {
          isSuccess: true,
          isVerified: true,
          token:
            response?.data?.data?.verifyCustomerAuthenticatorCode?.Token
              ?.IdToken,
          accountNumber:
            response?.data?.data?.verifyCustomerAuthenticatorCode
              ?.DefaultAccountNumber,
        },
      };
    }
    if (
      response?.data?.data?.verifyCustomerAuthenticatorCode?.LoginAttemptCount
    ) {
      return {
        data: {
          isSuccess: true,
          isVerified: false,
          loginAttemptCount:
            response?.data?.data?.verifyCustomerAuthenticatorCode
              ?.LoginAttemptCount,
        },
      };
    }
    if (
      response?.data?.data?.verifyCustomerAuthenticatorCode?.Errors ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: true,
          errors:
            response?.data?.data?.verifyCustomerAuthenticatorCode
              ?.Errors?.[0] ||
            response?.data?.errors ||
            "",
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error:
          response?.data?.errors ||
          response?.data?.data?.error ||
          response?.data ||
          response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};
