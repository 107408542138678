/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
  useEffect,
  MutableRefObject,
} from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { func, number, string } from "prop-types";
import FormField from "../common/form-field/formField";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import Logger from "../../../logger";
import { SessionContext } from "../../../context/sessionContext";
import BannerNotification from "../common/banner/bannerNotification";
import styles from "./verifyChallenge.module.scss";
import { retrieveUsername } from "../../services/retrieveUsername";

interface VerifyChallengeProps {
  setUsername: (value: string) => void;
  ssn: string;
  partyId: number;
  zipCode: string;
  dateOfBirth: string;
  challengeQuestion: string;
  accountNumber: string;
  setIsContinueEnabled: (value: boolean) => void;
}

const VerifyChallenge = forwardRef(
  (
    {
      setUsername,
      ssn,
      partyId,
      zipCode,
      dateOfBirth,
      challengeQuestion,
      setIsContinueEnabled,
      accountNumber,
    }: VerifyChallengeProps,
    ref: MutableRefObject<any>,
  ) => {
    const { register, errors, trigger, clearErrors } = useForm({});
    const { sessionDetails } = useContext(SessionContext);
    const [challengeAnswer, setChallengeAnswer] = useState("");
    const [apiError, setApiError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [noMatchError, setNoMatchError] = useState(false);
    const country = sessionDetails?.country?.toLowerCase();
    const brand = sessionDetails?.brand?.toLowerCase();
    const isAudi = brand === "audi";
    const isVW = brand === "vw" && country === "usa";
    const isVWCanada = brand === "vw" && country === "canada";
    const navigate = useNavigate();

    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              "Exited Retrieve Username Flow in Step 2 by clicking back button on the browser tab",
              "Location ==> /register",
              sessionDetails,
            );
          }
        },
        false,
      );
      window.addEventListener("beforeunload", () => {
        Logger.info(
          "Exited Retrieve Username Flow in Step 2 by closing the browser tab",
          "Location ==> /register",
          sessionDetails,
        );
      });

      // When the user change the language, triggers the existing error.
      for (const error in errors) {
        trigger(error);
      }

      if (errorMsg) {
        setErrorMsg(i18n.t(rk.ERROR_INVALID_INFO));
      }
    }, [sessionDetails]);

    async function onSubmit() {
      if (!challengeAnswer) {
        setErrorMsg(i18n.t(rk.ERROR_EMPTY_CHALLENGE_ANSWER));
        return false;
      }
      const unique = isAudi || isVWCanada ? { dateOfBirth } : { ssn };
      const body = {
        ...unique,
        zipCode,
        challengeQuestion,
        challengeAnswer,
        partyId,
        sessionDetails,
      };
      const { data, error } = await retrieveUsername(body);

      if (data?.inMaintenance) {
        navigate(`/login`);
      }
      if (data?.isSuccess) {
        Logger.info(
          "Username Retrieval Successful",
          "Location ==> /retrieveUsername",
          sessionDetails,
          accountNumber,
          partyId,
        );
        setUsername(data.username);
        return true;
      }
      if (data?.errors?.ErrorCode === "badRequest_NoMatch") {
        setErrorMsg(i18n.t(rk.ERROR_INVALID_INFO));
        Logger.error(
          "User failed to pass Challenge Q/A in retrieve username",
          "Location ==> /retrieveUsername",
          error,
          sessionDetails,
          accountNumber,
          partyId,
        );
        setIsContinueEnabled(false);
        setNoMatchError(true);
        return false;
      }
      setApiError(true);
      setIsContinueEnabled(true);
      Logger.error(
        "ForgotUserName API Error",
        "Location ==> /retrieveUsername",
        data?.errors || error || data,
        sessionDetails,
        accountNumber,
        partyId,
      );
      return false;
    }

    async function verifyUserAction() {
      setApiError(false);
      setErrorMsg("");
      if (!isEmpty(errors)) {
        setIsContinueEnabled(false);
        return false;
      }
      const isSuccess = await onSubmit();
      return isSuccess;
    }

    useImperativeHandle(ref, () => ({ verifyUserAction }));

    useEffect(() => {
      setIsContinueEnabled(challengeAnswer.length > 0 && !noMatchError);
    }, [challengeAnswer, noMatchError, setIsContinueEnabled]);

    return (
      <div className="o-page-wrap new-page-wrap">
        <p
          className={`retrieve-username__infotext ${isAudi && styles["info-text-audi"]}`}
        >
          {i18n.t(rk.TEXT_RETRIEVE_USERNAME_CHALLENGE)}
          <span
            className={`retrieve-username__fieldsrequired ${isAudi && styles["all-fields-required-audi"]}`}
          >
            {i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}
          </span>
        </p>
        {apiError && (
          <div className="bannerNotification">
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.ERROR_API_SERVICE_DOWN)}
            </BannerNotification>
          </div>
        )}

        <form
          className={`o-page-wrap  form-page-wrap ${isVWCanada && "form-page-wrap-vf"}`}
        >
          {isVW && (
            <FormField
              id="ssn"
              data-testid="test-ssn"
              label={i18n.t(rk.LABEL_SSN)}
              type="text"
              value={ssn}
              disabled
            />
          )}

          {isAudi ||
            (isVWCanada && (
              <FormField
                id="accountNumber"
                label={i18n.t(rk.LABEL_ACCOUNT_NUMBER)}
                type="text"
                value={accountNumber}
                disabled
                wrapperClassName={
                  isAudi ? styles["form-field-custom"] : undefined
                }
              />
            ))}

          {isAudi ||
            (isVWCanada && (
              <FormField
                id="dateOfBirth"
                wrapperClassName={
                  isAudi ? styles["form-field-custom"] : undefined
                }
                data-testid="test-date-of-birth"
                label={i18n.t(rk.LABEL_DOB)}
                type="text"
                value={dateOfBirth}
                disabled
              />
            ))}
          <FormField
            id="zipCode"
            wrapperClassName={isAudi ? styles["form-field-custom"] : undefined}
            data-testid="test-zipcode"
            label={
              isAudi || isVWCanada
                ? i18n.t(rk.LABEL_POSTAL_CODE)
                : i18n.t(rk.LABEL_ZIP_CODE)
            }
            type="text"
            value={zipCode}
            disabled
          />
          <FormField
            id="challengeAnswer"
            wrapperClassName={isAudi ? styles["form-field-custom"] : undefined}
            label={i18n.t(challengeQuestion)}
            type="text"
            data-testid="challenge-answer-field"
            onChange={(e) => {
              setChallengeAnswer(e.target.value);
              clearErrors("challengeAnswer");
              setErrorMsg("");
              setNoMatchError(false);
            }}
            autoCompleteOff
            onBlur={() => {
              trigger("challengeAnswer");
              setIsContinueEnabled(!errors.challengeAnswer?.message);
            }}
            value={challengeAnswer}
            hint={i18n.t(rk.TEXT_SECURITY_QUESTION_ANSWER_HINT)}
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_CHALLENGE_ANSWER),
              validate: {
                validateAccountSecurityQuestionAnswer: () =>
                  challengeAnswer !== "" ||
                  i18n.t(rk.ERROR_EMPTY_CHALLENGE_ANSWER),
              },
            })}
            fieldErrors={errors.challengeAnswer?.message || errorMsg}
          />
        </form>
        <style>{`
        .retrieve-username__infotext{
            font-size:16px;
        }
        .form-page-wrap {
          ${country === "canada" && "padding-left: 0px !important;"}
        }
        @media (max-width: 600px) {
          .form-page-wrap{
            padding-left:0px;
            padding-right:0px;
          }
        }
        .new-page-wrap{
          padding-left:0px;
          padding-right:0px;
        }
        .bannerNotification{
          margin: -15px 0 50px;
        }
        .retrieve-username__fieldsrequired{
          display:block;
          font-size: 14px;
          margin-top:10px;
        }
        .form-page-wrap-vf {
          padding: 0px;
        }
      `}</style>
      </div>
    );
  },
);
export default VerifyChallenge;

VerifyChallenge.propTypes = {
  setUsername: func,
  setIsContinueEnabled: func,
  ssn: string,
  zipCode: string,
  partyId: number,
  challengeQuestion: string,
  dateOfBirth: string,
  accountNumber: string,
};
VerifyChallenge.defaultProps = {
  setUsername: () => {},
  setIsContinueEnabled: () => {},
  ssn: "",
  zipCode: "",
  partyId: 0,
  challengeQuestion: "",
  dateOfBirth: "",
  accountNumber: "",
};
