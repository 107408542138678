import React, { useState, useRef, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Layout from "../common/layout/layout";
import Logger from "../../../logger";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import StepFlow from "../common/stepflow/stepFlow";
import UpdateEmailVerify from "./updateEmailFlow/updateEmailVerify";
import UpdateEmailForm from "./updateEmailFlow/updateEmailForm";
import UpdateEmailSuccess from "./updateEmailFlow/updateEmailSuccess";
import Loader from "../common/loader/loader";
import { SessionContext } from "../../../context/sessionContext";
import {
  CANCEL_EMAIL_FLOW_EVENT,
  START_UPDATE_EMAIL_FLOW_EVENT,
  BACK_EMAIL_FLOW_EVENT,
} from "../../../constants/BusinessSplunkEvent";

const UpdateEmailStepFlow = ({
  setNewEmail,
  setSubFlow,
  setNewStep,
  currentStep,
  currentStepIsCancel,
}) => {
  const { sessionDetails } = useContext(SessionContext);
  const ref = useRef(null);
  const [continueEnabled, setContinueEnabled] = useState(false);
  const [cancelEnabled, setCancelEnabled] = useState(true);
  const [authenticationInfo, setAuthenticationInfo] = useState({
    accountNumber: null,
    ssn: null,
    partyId: null,
    dob: null,
  });
  const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
  const isVF =
    sessionDetails?.brand?.toLowerCase() === "vw" &&
    sessionDetails?.country?.toLowerCase() === "canada";

  const country = sessionDetails?.country?.toLowerCase();
  const brand = sessionDetails?.brand?.toLowerCase();

  const initialState = {
    name: "UpdateEmailFlow",
    currentStep: currentStep || 1,
    previousStep: -1,
    currentStepIsCancel: currentStepIsCancel || true,
    maxSteps: 3,
    submitPending: false,
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    document.getElementsByClassName("page-content")[0]?.scrollTo(0, 0);
  }, [state.currentStep]);

  const renderImage = () => {
    if (brand === "audi" && country === "canada") {
      return "./images/audi_canada/update_email.jpg";
    }
    if (brand === "vw" && country === "usa") {
      return "./images/vw_usa/update_email.jpg";
    }
    if (brand === "vw" && country === "canada") {
      return "./images/vw_canada/update_email.png";
    }
  };

  return (
    <div
      className={`intro-container ${isAudi && "update-email-container-audi"}`}
    >
      <Layout imgSource={renderImage()}>
        <StepFlow
          step={1}
          currentStep={state.currentStep}
          title={isVF ? "" : <h2>{i18n.t(rk.TITLE_UPDATE_EMAIL)}</h2>}
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          previousAction={() => {
            setState({
              ...state,
              currentStep: state.currentStep + 1,
              previousStep: 1,
            });
            Logger.info(
              "Clicked Continue in Update Email Flow in Step 1",
              "Location ==> /login",
              sessionDetails,
            );
            Logger.info(
              START_UPDATE_EMAIL_FLOW_EVENT,
              "Location => updateEmail Screen",
              sessionDetails,
            );
          }}
          nextLabel={i18n.t(rk.BUTTON_CANCEL)}
          nextAction={() => {
            setSubFlow("");
            Logger.info(
              BACK_EMAIL_FLOW_EVENT,
              "Location => updateEmail Screen",
              sessionDetails,
            );
            Logger.info(
              "Exited Update Email Flow in Step 1",
              "Location ==> /login",
              sessionDetails,
            );
          }}
          canTakePreviousAction={async () => {
            setState({ ...state, submitPending: true });
            const canTakeAction = await ref.current.verifyUserAction();
            setState({ ...state, submitPending: false });
            return canTakeAction;
          }}
          previousLabelDisabled={!continueEnabled}
          canTakeNextAction={() => cancelEnabled}
          nextLabelDisabled={!cancelEnabled}
        >
          <Loader isLoading={state.submitPending} />
          <UpdateEmailVerify
            ref={ref}
            setCancelEnabled={setCancelEnabled}
            setContinueEnabled={setContinueEnabled}
            setAuthenticationInfo={setAuthenticationInfo}
          />
        </StepFlow>
        <StepFlow
          step={2}
          currentStep={state.currentStep}
          title={isVF ? "" : <h2>{i18n.t(rk.TITLE_UPDATE_EMAIL)}</h2>}
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          previousAction={() => {
            setState({
              ...state,
              currentStep: state.currentStep + 1,
              previousStep: 2,
            });
            Logger.info(
              "Clicked Continue in Update Email Flow in Step 2",
              "Location ==> /login",
              sessionDetails,
            );
          }}
          nextLabel={i18n.t(rk.BUTTON_CANCEL)}
          nextAction={() => {
            setSubFlow("");
            Logger.info(
              CANCEL_EMAIL_FLOW_EVENT,
              "Location => updateEmail Screen",
              sessionDetails,
            );
            Logger.info(
              "Exited Update Email Flow in Step 2",
              "Location ==> /login",
              sessionDetails,
            );
          }}
          canTakePreviousAction={async () => {
            setState({ ...state, submitPending: true });
            const canTakeAction = await ref.current.verifyUserAction();
            setState({ ...state, submitPending: false });
            return canTakeAction;
          }}
          previousLabelDisabled={!continueEnabled}
          nextLabelDisabled={!cancelEnabled}
        >
          <Loader isLoading={state.submitPending} />
          <UpdateEmailForm
            ref={ref}
            setCancelEnabled={setCancelEnabled}
            setContinueEnabled={setContinueEnabled}
            authenticationInfo={authenticationInfo}
            setNewEmail={setNewEmail}
          />
        </StepFlow>
        <StepFlow
          step={3}
          currentStep={state.currentStep}
          previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
          previousAction={() => {
            setNewStep(1);
            setSubFlow("");
            Logger.info(
              "Clicked Continue in Update Email Flow in Step 3",
              "Location ==> /login",
              sessionDetails,
            );
          }}
          previousLabelDisabled={false}
        >
          <UpdateEmailSuccess />
        </StepFlow>
      </Layout>
    </div>
  );
};

UpdateEmailStepFlow.propTypes = {
  setNewEmail: PropTypes.func,
  setSubFlow: PropTypes.func,
  currentStep: PropTypes.number,
  currentStepIsCancel: PropTypes.bool,
  setNewStep: PropTypes.func,
};

UpdateEmailStepFlow.defaultProps = {
  setNewEmail: () => {},
  setSubFlow: () => {},
  currentStep: undefined,
  currentStepIsCancel: undefined,
  setNewStep: () => {},
};

export default UpdateEmailStepFlow;
