/* eslint-disable */
import React from "react";
import { func, number, bool } from "prop-types";

const CountdownTimer = ({
  hours,
  minutes = 0,
  seconds = 60,
  onFinish,
  reset,
}) => {
  const [time, setTime] = React.useState({
    hours: hours || 0,
    minutes,
    seconds,
  });
  const [finished, setFinished] = React.useState(false);

  const tick = () => {
    if (!finished) {
      if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
        onFinish?.();
        setFinished(true);
      } else if (time.minutes === 0 && time.seconds === 0) {
        setTime({ hours: time.hours - 1, minutes: 59, seconds: 59 });
      } else if (time.seconds === 0) {
        setTime({ hours: time.hours, minutes: time.minutes - 1, seconds: 59 });
      } else {
        setTime({
          hours: time.hours,
          minutes: time.minutes,
          seconds: time.seconds - 1,
        });
      }
    }
  };

  const resetTimer = () => {
    setFinished(false);
    setTime({ hours: hours || 0, minutes, seconds });
  };

  React.useEffect(() => {
    const timerId = setInterval(() => tick(), 1000);
    return () => clearInterval(timerId);
  });

  React.useEffect(() => {
    resetTimer();
  }, [reset]);

  const formattedHours =
    hours !== undefined ? `${time.hours.toString().padStart(2, "0")}: ` : "";
  const formattedMinutes = time.minutes.toString().padStart(2, "0");
  const formattedSeconds = time.seconds.toString().padStart(2, "0");
  return (
    <span>{`${formattedHours}${formattedMinutes}:${formattedSeconds}`}</span>
  );
};

export default CountdownTimer;

CountdownTimer.propTypes = {
  hours: number,
  minutes: number,
  seconds: number,
  onFinish: func,
  reset: bool,
};

CountdownTimer.defaultProps = {
  hours: undefined,
  minutes: 0,
  seconds: 60,
  onFinish: () => {},
  reset: false,
};
