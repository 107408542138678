/* eslint-disable */
import React, { useContext } from "react";
import { string, func } from "prop-types";
import { SessionContext } from "../../../../context/sessionContext";

interface LinkProps {
  href?: string;
  onClick?: (value: any) => void;
  icon?: string;
  ariaLabel?: string;
  target?: string;
  testid?: string;
  value?: string;
  fontSize?: string;
  customClass?: string;
}
const Link: React.FC<LinkProps> = (props): JSX.Element | null => {
  const {
    href,
    value,
    testid,
    icon,
    ariaLabel,
    target,
    onClick,
    fontSize,
    customClass,
  } = props;
  const { sessionDetails } = useContext(SessionContext);
  const brand = sessionDetails?.brand?.toUpperCase();

  return (
    <a
      className={`c-link c-link--reversed ${customClass || ""}`}
      rel="noopener noreferrer"
      href={href || null}
      onClick={onClick}
      target={target}
      data-testid={testid}
      aria-label={ariaLabel}
    >
      <span className="c-link__body !important">
        <span className="c-link__effect" role="link" tabIndex={0}>
          {value}
        </span>
      </span>
      {icon && (
        <i
          className={`c-link__icon  c-icon  ${icon}`}
          aria-hidden="true"
          role="img"
        />
      )}
      <style>{`
        .c-link__body {
          font-size: ${fontSize || "14px"};
          ${brand === "AUDI" && "border-bottom: 1px solid #4c4c4c;"}
          ${brand === "AUDI" && "color: rgb(3, 3, 3);"}
        }
        .c-link__icon {
          margin-left: 0px !important;
        }
        @media (min-width: 1600px) {
          .c-link__body {
            font-size: ${fontSize || "14px"} !important;
          }
        }
      `}</style>
    </a>
  );
};

Link.propTypes = {
  href: string,
  value: string,
  testid: string,
  icon: string,
  ariaLabel: string,
  target: string,
  onClick: func,
  fontSize: string,
  customClass: string,
};

Link.defaultProps = {
  href: "",
  value: "",
  testid: "",
  icon: "",
  ariaLabel: "",
  target: "",
  onClick: () => {},
  fontSize: "",
  customClass: "",
};

export default Link;
