/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Footer from "../footer/footer";
import { SessionContext } from "../../../../context/sessionContext";

const Layout = (props: any) => {
  const { children, imgSource, customBackgroundStyles, wrapperClass } = props;
  const { sessionDetails } = useContext(SessionContext);
  const isVW = sessionDetails?.brand?.toLowerCase() === "vw";
  const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
  const [isFrench, setIsFrench] = useState(sessionDetails?.language || false);
  const country = sessionDetails?.country?.toLowerCase();
  const isVWCanada = isVW && country === "canada";

  useEffect(() => {
    setIsFrench(sessionDetails?.language === "fr-CA");
  }, [sessionDetails?.language]);
  return (
    <div className="container">
      <div className="page-cont-background" />
      <div className="page-container">
        <div
          className={`${isAudi ? "page-content-audi" : ""} page-content`}
        >
          <div className="content">
            <div className={`wrapper-div ${wrapperClass} `}>{children}</div>
            {isVW && (
              <div className="footer">
                <Footer />
              </div>
            )}
          </div>
          {isAudi && (
            <div className="footer-audi">
              <Footer />
            </div>
          )}
        </div>
      </div>
      <style>{`
        .page-cont-background {
          background-image: url(${imgSource});
          width: ${isAudi ? "50vw" : "59vw"};
          height: 100%;
          ${isVWCanada ? "background-size: auto;" : "background-size: cover;"};
          ${isVWCanada ? "background-position: 34% 68%;" : "background-position: 40% 0%;"};
          position: absolute;
          z-index: -1;
          ${isAudi ? "left: 0;" : "right: 0"};
          ${customBackgroundStyles}
        }
        .page-container .page-content {
          background-color: #ffffff;
          box-shadow: 0 0.125rem 1.5rem 0 rgb(0 0 0 / 20%);
          position: absolute;
          top: 0;
          width: 100vw;
          height: 100%;
          flex-direction: column;
          overflow-x: hidden;
          display: flex;
          ${isAudi ? "right: 0;" : "left: 0;"}
          ${isAudi && "align-items: end;"}
        }
        .content {
          padding: 4%;
          height: 100%;
          padding-bottom: 10px !important;
          display: inline-table;
          width: 100%
        }
        .wrapper-div {
          padding-bottom: 86px;
          ${!isAudi && "min-height: 95vh;"}
        }
        .footer {
          height: 100px;
          margin-top: -100px;
        }
        .footer-audi {
          width: 100%;
          min-height: ${isFrench ? "130px" : "90px"};
          margin-top: ${isFrench ? "-70px" : "-90px"};
          background-color: black;
          padding: 0px 4% 0px 4%;
          height: 120px;
        }
        @media (min-width: 960px) {
          .wrapper-div {
            padding-bottom: 150px;
          }
          .footer {
            height: 150px;
            margin-top: -165px;
          }
        }
        @media (min-width: 976px) {
          .wrapper-div {
            padding-bottom: 130px;
          }
          .footer {
            height: 130px;
            margin-top: -145px;
          }
        }
        @media (min-width: 1025px) {
          .wrapper-div {
            padding-bottom: 108px;
          }
          .footer {
            height: 108px;
            margin-top: -123px;
          }
        }
        @media (min-width: 1210px) {
          .wrapper-div {
            padding-bottom: 84px;
          }
          .footer {
            height: 84px;
            margin-top: -99px;
          }
        }
        @media (min-width: 1235px) {
          .wrapper-div {
            padding-bottom: 60px;
          }
          .footer {
            height: 60px;
            margin-top: -75px;
          }
          .content {
            ${
              isAudi &&
              `
            max-width: 700px;
            min-width: 600px;
            align-self: center;
            `
            }
          }
        }
        @media (min-width: 960px) {
          .footer-audi {
            min-height: ${isFrench ? "160px" : "120px"};
            margin-top: ${isFrench ? "360px" : "-120px"};
          }
        }
        @media (min-width: 360px) {
          .footer-audi {
            min-height: ${isFrench ? "150px" : "120px"};
            margin-top: ${isFrench ? "-120px" : "-120px"};
          }
        }
        @media (max-width: 359px) {
          .footer-audi {
            min-height: ${isFrench ? "150px" : "120px"};
            margin-top: ${isFrench ? "-67px" : "-120px"};
          }
        }
        @media (max-height: 800px) {
          .page-content-audi {
            display: grid !important;
          }
        }
        @media screen and (min-width: 961px) {
          .page-container .page-content {
            width: ${isAudi ? "50vw" : "41.66vw"} !important;
          }
        }
        @media screen and (min-width: 1800px), (min-height: 1070px) {
          .page-cont-background {
            background-size: cover;
          }
        }
        @media (max-width: 465px) {
          .content {
            padding: 0px
          }
        }

      `}</style>
    </div>
  );
};
Layout.propTypes = {
  children: PropTypes.node.isRequired,
  imgSource: PropTypes.string,
  wrapperClass: PropTypes.string,
  customBackgroundStyles: PropTypes.string,
};
Layout.defaultProps = {
  imgSource: "",
  customBackgroundStyles: "",
  wrapperClass: "",
};
export default Layout;
