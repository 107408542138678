import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import * as validate from "@ownportal/customer-validations";
import { useForm } from "react-hook-form";
import { string, func } from "prop-types";
import { isEmpty } from "lodash";
import FormField from "../common/form-field/formField";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import Logger from "../../../logger";
import { SessionContext } from "../../../context/sessionContext";
import BannerNotification from "../common/banner/bannerNotification";
import { setPassword } from "../../services/setPassword";

interface SetPasswordProps {
  username: string;
  challengeQuestion: string;
  challengeAnswer: string;
  setIsContinueEnabled: (enabled: boolean) => void;
}

const SetPassword = forwardRef(
  (
    {
      username,
      challengeQuestion,
      challengeAnswer,
      setIsContinueEnabled,
    }: SetPasswordProps,
    ref,
  ) => {
    const navigate = useNavigate();
    const { register, errors, clearErrors, setValue, getValues, trigger } =
      useForm({});
    const { sessionDetails } = useContext(SessionContext);
    const [apiError, setApiError] = useState<boolean | "">("");
    const [newPswd, setNewPswd] = useState("");
    const [confirmPswd, setConfirmPswd] = useState("");

    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              "Exited Reset Password Flow in Step 3 by clicking back button on the browser tab",
              "Location ==> /reset",
              sessionDetails,
            );
          }
        },
        false,
      );

      window.addEventListener("beforeunload", () => {
        Logger.info(
          "Exited Reset Password Flow in Step 3 by closing the browser tab",
          "Location ==> /reset",
          sessionDetails,
        );
      });

      for (const error in errors) {
        trigger(error);
      }
    }, [sessionDetails]);

    async function onSubmit() {
      const body = {
        username,
        challengeQuestion,
        challengeAnswer,
        newPswd,
        sessionDetails,
      };

      const { data, error } = await setPassword(body);
      if (data?.inMaintenance) {
        navigate(`/login`);
      }
      if (data?.isSuccess) {
        Logger.info(
          "Successful New Password Submission",
          "Location ==> /reset",
          sessionDetails,
          "",
          "",
          username,
        );
        return true;
      }
      setApiError(true);
      Logger.error(
        "ForgotPassword API Error",
        "Location ==> /reset",
        data?.error || data || error,
        sessionDetails,
        "",
        "",
        username,
      );
      return false;
    }

    async function verifyUserAction() {
      setApiError(false);
      await trigger("newPassword");
      await trigger("confirmPassword");

      if (!isEmpty(errors)) {
        setIsContinueEnabled(false);
        return false;
      }
      const isSuccess = await onSubmit();
      return isSuccess;
    }
    useImperativeHandle(ref, () => ({ verifyUserAction }));

    useEffect(() => {
      const isEmptyErrors = isEmpty(errors);
      setIsContinueEnabled(
        newPswd.length > 0 && confirmPswd.length > 0 && isEmptyErrors,
      );
    }, [newPswd, confirmPswd, setIsContinueEnabled, errors]);

    return (
      <div className="new-password-container">
        <p className="subtitle">{i18n.t(rk.TEXT_RESETPSWD_INFO)}</p>
        <p className="fields-required-text">
          {i18n.t(rk.TEXT_ALL_FIELDS_REQUIRED)}
        </p>
        {apiError && (
          <div className="bannerNotification">
            <BannerNotification notificationStyle="error">
              {i18n.t(rk.ERROR_API_SERVICE_DOWN)}
            </BannerNotification>
          </div>
        )}
        <div className="form-fields">
          <FormField
            id="newPassword"
            label={i18n.t(rk.LABEL_NEW_PASSWORD)}
            type="password"
            testid="new-password-field"
            onChange={async (e) => {
              setValue("newPassword", e.target.value);
              clearErrors("newPassword");
              setApiError(false);
              setNewPswd(e.target.value);

              if (errors.confirmPassword?.message === i18n.t(rk.ERROR_PASSWORD_MATCH) || confirmPswd.length) {
                await clearErrors("confirmPassword");
                await trigger("confirmPassword");
              }
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            autoCompleteOff
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_PASSWORD),
              validate: {
                validatePassword: () =>
                  validate.IsValidPassword(getValues().newPassword, username) ||
                  i18n.t(rk.ERROR_API_INVALID_PASSWORD),
              },
            })}
            onBlur={async () => {
              await trigger("newPassword");
              if (confirmPswd) await trigger("confirmPassword");
              if (errors.newPassword?.message) {
                Logger.info(
                  "User entered invalid newPassword",
                  "Location ==> /reset",
                  sessionDetails,
                  "",
                  "",
                  username,
                );
              }
            }}
            value={getValues().newPassword}
            hint={i18n.t(rk.TEXT_PASSWORD_HINT)}
            fieldErrors={errors.newPassword?.message}
          />

          <FormField
            id="confirmPassword"
            label={i18n.t(rk.LABEL_CONFIRM_NEW_PASSWORD)}
            type="password"
            testid="confirm-password-field"
            onChange={(e) => {
              setValue("confirmPassword", e.target.value);
              clearErrors("confirmPassword");
              setApiError(false);
              setConfirmPswd(e.target.value);
            }}
            onCopy={(e) => {
              e.preventDefault();
              return false;
            }}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            autoCompleteOff
            fieldRef={register({
              required: i18n.t(rk.ERROR_EMPTY_CONFIRM_PSWD),
              validate: {
                validateConfirmPassword: () =>
                  getValues().newPassword === getValues().confirmPassword ||
                  i18n.t(rk.ERROR_PASSWORD_MATCH),
              },
            })}
            onBlur={async () => {
              await trigger("confirmPassword");
              await setIsContinueEnabled(!errors.confirmPassword?.message);
              if (errors.confirmPassword?.message) {
                Logger.info(
                  "User entered invalid confirmPassword",
                  "Location ==> /reset",
                  sessionDetails,
                  "",
                  "",
                  username,
                );
              }
            }}
            value={getValues().confirmPassword}
            fieldErrors={errors.confirmPassword?.message}
          />
        </div>
        <style>{`
        .fields-required-text {
          margin: 0px 0px 50px 0px;
          font-size: 14px;
        }
        .subtitle {
          font-size: 16px;
        }
        .bannerNotification{
          margin: -15px 0 50px;
        }
      `}</style>
      </div>
    );
  },
);

export default SetPassword;

SetPassword.propTypes = {
  username: string,
  challengeQuestion: string,
  challengeAnswer: string,
  setIsContinueEnabled: func,
};
SetPassword.defaultProps = {
  username: "",
  challengeQuestion: "",
  challengeAnswer: "",
  setIsContinueEnabled: () => {},
};
