import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}
interface RegisterPayload {
  // paperlessEnroll might/should be only true only in VW USA so far COOLKIDS-5087
  paperlessEnroll?: boolean;
  accountNumber: string;
  partyId: number;
  email: string;
  username: string;
  password: string;
  challengeQuestion: string;
  challengeAnswer: string;
  sessionDetails: SessionDetails;
}

const gqlRequest = gql`
  mutation RegisterCustomer($Request: RegisterCustomerRequest!) {
    registerCustomer(Request: $Request) {
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

const register = async (payload: RegisterPayload) => {
  const {
    paperlessEnroll = false,
    accountNumber,
    partyId,
    email,
    username,
    password,
    challengeQuestion,
    challengeAnswer,
    sessionDetails,
  } = payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request = {
      PartyId: partyId,
      Email: email,
      UserName: username,
      Password: password,
      ChallengeQuestion: challengeQuestion,
      ChallengeAnswer: challengeAnswer,
      AccessAgreement: true,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      Source: sessionDetails.source,
      SourceApp: sessionDetails.source,
      AccountNumber: accountNumber,
      ParentFunctionName: "Customer Register",
      ProcessName: "Customer Register",
      WidgetName: sessionDetails.source,
      PaperlessEnroll: paperlessEnroll,
    };
    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (response?.data?.data?.registerCustomer?.IsSuccess) {
      return {
        data: {
          isSuccess: true,
        },
      };
    }
    if (
      !response?.data?.data?.registerCustomer?.IsSuccess ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: false,
          errors:
            response?.data?.data?.registerCustomer?.Errors?.[0] ||
            response?.data?.errors,
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};

export default register;
