// Logo
export const LOGO_TEXT_AUDI = "Text.LogoAudi";
export const LOGO_TEXT_VW = "Text.LogoVW";
export const TITLE_VWCPORTAL_APP = "Title.VWCPortalApp";
export const TITLE_AFPORTAL_APP = "Title.AFPortalApp";
export const TITLE_AFSPORTAL_APP = "Title.AFSPortalApp";
export const TITLE_VFPORTAL_APP = "Title.VFPortalApp";
export const TITLE = "Title";

// Login
export const TITLE_WELCOME = "Title.Welcome";
export const TITLE_VW = "Title.VW";
export const TITLE_WELCOME_VW_1 = "Title.Welcome.VW.1";
export const TITLE_WELCOME_VW_2 = "Title.Welcome.VW.2";
export const TITLE_LOGIN = "Title.Login";
export const TEXT_FORGOT = "Text.Forgot";
export const TEXT_OR = "Text.Or";
export const TEXT_NEW_TO_VW = "Text.NewToVW";
export const TEXT_NEW_TO_VW_CAN = "Text.NewToVWCanada";
export const TEXT_NEW_TO_AUDI = "Text.NewToAudi";
export const TEXT_LOOKING_FOR = "Text.LookingFor";
export const TEXT_CAR_NET_LOGIN_PAGE = "Text.CarNetLoginPage";
export const TEXT_MYAUDI_LOGIN_PAGE = "Text.MyAudiLoginPage";
export const TEXT_ID_LOGIN_PAGE = "Text.IdLoginPage";

// MFA
export const TITLE_ACCOUNT_VERIFICATION = "Title.AccountVerification";
export const TITLE_CONFIRM_IDENTITY = "Title.ConfirmIdentity";
export const TEXT_CONFIRM_IDENTITY = "Text.ConfirmIdentity";
export const LINK_UPDATE_EMAIL_ADDRESS = "Link.UpdateEmailAddess";

// EMAIL & AUTHENTICATOR
export const TEXT_1_CONFIRM_IDENTITY_EMAIL_AND_AUTHENTICATOR =
  "Text.1.ConfirmIdentity.Email.And.Authenticator";
export const TEXT_2_CONFIRM_IDENTITY_EMAIL_AND_AUTHENTICATOR =
  "Text.2.ConfirmIdentity.Email.And.Authenticator";
export const TEXT_CONFIRM_IDENTITY_EMAIL_OPTION =
  "Text.ConfirmIdentity.Email.Option";
export const TEXT_CONFIRM_IDENTITY_AUTHENTICATOR_OPTION =
  "Text.ConfirmIdentity.Authenticator.Option";
export const TITLE_ADD_ACCOUNT_AND_ENTER_CODE = "Title.AddAccountAndEnterCode";
export const TITLE_ENTER_CODE = "Title.EnterCode";
export const TEXT_STEP_1_ADD_ACCOUNT_AND_ENTER_CODE =
  "Text.Step.1.AddAccountAndEnterCode";
export const TEXT_STEP_2_ADD_ACCOUNT_AND_ENTER_CODE =
  "Text.Step.2.AddAccountAndEnterCode";
export const TEXT_STEP_3_ADD_ACCOUNT_AND_ENTER_CODE =
  "Text.Step.3.AddAccountAndEnterCode";
export const TEXT_STEP_4_ADD_ACCOUNT_AND_ENTER_CODE =
  "Text.Step.4.AddAccountAndEnterCode";
export const TEXT_ENTER_CODE = "Text.EnterCode";
export const TEXT_ACCOUNT_NAME_ADD_ACCOUNT_AND_ENTER_CODE =
  "Text.Account.Name.AddAccountAndEnterCode";
export const TEXT_SECRET_KEY_ADD_ACCOUNT_AND_ENTER_CODE =
  "Text.Secret.Key.AddAccountAndEnterCode";
export const TEXT_VW_ACCOUNT_NAME_ADD_ACCOUNT_AND_ENTER_CODE =
  "Text.VW.Account.Name.AddAccountAndEnterCode";
export const LINK_CHANGE_VERIFICATION_METHOD = "Link.ChangeVerificationMethod";
export const TITLE_LOCKOUT_ERROR_EMAIL_AND_AUTHENTICATOR =
  "Title.LockoutError.Email.And.Authenticator";
export const TEXT_LOCKOUT_ERROR_EMAIL_AND_AUTHENTICATOR =
  "Text.LockoutError.Email.And.Authenticator";
export const ERROR_INVALID_CODE_EMAIL_AND_AUTHENTICATOR =
  "Error.InvalidCode.Email.And.Authenticator";
export const TEXT_SETUP_OR_RESET_AUTHENTICATOR =
  "Text.SetupOrResetAuthenticator";

// LINK LABELS
export const LABEL_RETRIEVE_USERNAME_LINK = "Label.UsernameLink";
export const LABEL_RESET_PSWD_LINK = "Label.PasswordLink";
export const LABEL_CREATE_ACC_LINK = "Label.CreateAccountLink";
export const LABEL_EXT_LOGIN_LINK = "Label.Ext.LoginLink";

// LINKS
export const LINK_RETRIEVE_USERNAME = "Link.RetrieveUsername";
export const LINK_RESET_PASSWORD = "Link.ResetPassword";
export const LINK_CREATE_ACCOUNT = "Link.CreateAccount";
export const LINK_CAR_NET_LOGIN_PAGE = "Link.CarNetLoginPage";
export const LINK_MYAUDI_LOGIN_PAGE = "Link.MyAudiLoginPage";
export const LINK_ID_LOGIN_PAGE = "Link.IdLoginPage";
// export const LINK_PRIVACY_POLICY = "Link.PrivacyPolicy";
export const LINK_DEALER_LOCATOR = "Link.DealerLocator";
export const LINK_CONTACT_US = "Link.ContactUs";
export const LINK_DO_NOT_SELL_INFO = "Link.DoNotSellInfo";
export const LINK_CONSENT = "Link.Consent";
export const LINK_DEALER_LOCATOR_AUDI = "Link.DealerLocator.Audi";
export const LINK_CONTACT_US_AUDI = "Link.ContactUs.Audi";

// Registration
export const TITLE_ACC_REGISTRATION = "Title.AccRegistration";
export const TEXT_ACC_REGISTRATION = "Text.AccRegistration";
export const TEXT_ACC_REGISTRATION_AUDI = "Text.AccRegistration.Audi";
export const LABEL_VEHICLE_INFO = "Label.VehicleInfo";
export const LABEL_VEHICLE_INFO_VF = "Label.VehicleInfoVF";
export const LABEL_ACCOUNT_INFO = "Label.AccountInfo";
export const LABEL_ACCOUNT_INFO_VF = "Label.AccountInfoVF";
export const TEXT_VEHICLE_INFO = "Text.VehicleInfo";
export const TEXT_VEHICLE_INFO_VF = "Text.VehicleInfoVF";
export const TEXT_ACC_INFO_ONE = "Text.AccInfoOne";
export const TEXT_ACC_INFO_TWO = "Text.AccInfoTwo";
export const LABEL_CONSENT_ONLINE_ACCESS = "Label.ConsentOnlineAccess";
export const TEXT_CONSENT_REVIEW = "Text.ConsentReview";
export const TEXT_CONSENT_DESCRIPTION_TEXT = "Text.ConsentDescriptionText";
export const LABEL_HINT_CONSENT_FORM = "LabelHint.ConsentForm";
export const LINK_ESIGN_CONSENT_AGREE = "Link.ESign.ConsentAgreee";
export const LINK_TERMS_CONSENT_AGREE = "Link.Terms.ConsentAgreee";
export const TEXT_CONSENT_AGREE = "Text.ConsentAgree";
export const TEXT_CONSENT_PAPERLESS_AGREE = "Text.ConsentPaperlessAgree";
export const TITLE_AF_CONSENT = "Title.ConsentAF";
export const TEXT_AF_CONSENT_1 = "Text.ConsentAF1";
export const TEXT_AF_CONSENT_2 = "Text.ConsentAF2";
export const TEXT_VF_CONSENT_1 = "Text.ConsentVF1";
export const TEXT_CHECKBOX_AF_CONSENT = "Text.CheckboxConsentAF";
export const TITLE_REGISTERED_USER = "Title.RegisteredUser";
export const TEXT_REGISTERED_USER = "Text.RegisteredUser";
export const TEXT_CONTACT_US_1 = "Text.ContactUs1";
export const TEXT_CONTACT_US_2 = "Text.ContactUs2";
export const LINK_RESTART_REGISTER = "Link.RestartRegister";
export const LINK_CONTACT_US_REGISTER = "Link.ContactUsRegister";
export const TEXT_LINE_BREAK = "Text.LineBreak";
export const TEXT_USERNAME_CONFLICT = "Text.Username.Conflict";

export const TEXT_ALREADY_HAVE_AN_AUDI_ACCOUNT =
  "Text.AlreadyHaveAnAudiAccount";
export const TEXT_ALREADY_HAVE_AN_ACCOUNT = "Text.AlreadyHaveAnAccount";
export const TEXT_VEHICLE_INFO_AUDI = "Text.VehicleInfo.Audi";
export const LABEL_DOB = "Label.Dob";
export const LABEL_DOB_VF = "Label.DobVF";
export const TEXT_DOB_HINT = "Text.DobHint";
export const LABEL_VIN = "Label.Vin";
export const LABEL_VIN_VF = "Label.VinVF";
export const TEXT_VIN_HINT = "Text.VinHint";
export const TEXT_VIN_HINT_VF = "Text.VinHint_VF";

// Reset Password
export const TITLE_RESET_PASSWORD = "Title.ResetPassword";
export const TEXT_RESETPSWD_VERIFY = "Text.ResetPswdVerify";
export const TEXT_RESETPSWD_VERIFY_AUDI = "Text.ResetPswdVerifyForAudi";
export const TEXT_RESETPSWD_VERIFY_CHALLENGE = "Text.ResetPswdVerifyChallenge";
export const TEXT_RESETPSWD_INFO = "Text.ResetPswdInfo";
export const TEXT_RESETPSWD_SUCCESS = "Text.ResetPswdSuccess";
export const TEXT_RESETPSWD_SUCCESS_VF = "Text.ResetPswdSuccessVF";

// Update Email
export const TITLE_UPDATE_EMAIL = "Title.UpdateEmail";
export const TITLE_UPDATE_EMAIL_SUCCESS = "Title.UpdateEmailSuccess";
export const TEXT_UPDATE_EMAIL_SUCCESS = "Text.UpdateEmailSuccess";

// Retrieve Username
export const TITLE_RETRIEVE_USERNAME = "Title.RetrieveUserName";
export const TITLE_RETRIEVE_USERNAME_VF = "Title.RetrieveUserName.VF";
export const TEXT_RETRIEVE_USERNAME_VERIFY = "Text.RetrieveUsernameVerify";
export const TEXT_RETRIEVE_USERNAME_CHALLENGE =
  "Text.RetrieveUsernameChallenge";
export const TEXT_USERNAME_VALUE = "Text.Username.Value";
export const TEXT_RETRIEVE_USERNAME_VERIFY_AUDI =
  "Text.RetrieveUsernameVerifyAudi";
export const TEXT_RETRIEVE_USERNAME_VERIFY_VWCAN =
  "Text.RetrieveUsernameVerifyVWCanada";

// FORM LABELS & HINTS
export const TEXT_ALL_FIELDS_REQUIRED = "Text.AllFieldsRequired";
export const LABEL_USERNAME = "Label.Username";
export const TEXT_USERNAME_HINT = "Text.UsernameHint";
export const LABEL_PASSWORD = "Label.Password";
export const LABEL_NEW_PASSWORD = "Label.NewPswd";
export const LABEL_CONFIRM_NEW_PASSWORD = "Label.ConfirmNewPswd";
export const LABEL_CONFIRM_NEW_PASSWORD_VF = "Label.ConfirmNewPswdVF";
export const TEXT_PASSWORD_HINT = "Text.PasswordHint";
export const LABEL_SSN = "Label.SSN";
export const TEXT_SSN_HINT = "Text.SSNHint";
export const TEXT_DATE_OF_BIRTH_HINT = "Text.DateOfBirthHint";
export const LABEL_SSN_LAST4 = "Label.SsnLast4";
export const TEXT_SSN_LAST4_HINT = "Text.SsnLast4Hint";
export const LABEL_ZIP_CODE = "Label.ZipCode";
export const LABEL_POSTAL_CODE = "Label.PostalCode";
export const TEXT_ZIP_CODE_HINT = "Text.ZipCodeHint";
export const TEXT_POSTAL_CODE_HINT = "Text.PostalCodeHint";
export const LABEL_ACCOUNT_NUMBER = "Label.AccNumber";
export const LABEL_ACCOUNT_NUMBER_VF = "Label.AccNumberVF";
export const TEXT_ACCOUNT_NUM_HINT = "Text.AccNumberHint";
export const TEXT_ACCOUNT_NUM_HINT_VF = "Text.AccNumberHint_VF";
export const LABEL_EMAIL_ADDRESS = "Label.EmailAddress";
export const LABEL_EMAIL_ADDRESS_VF = "Label.EmailAddressVF";
export const LABEL_NEW_EMAIL_ADDRESS = "Label.NewEmailAddress";
export const TEXT_EMAIL_HINT = "Text.EmailHint";
export const LABEL_CONFIRM_EMAIL_ADDRESS = "Label.ConfirmEmailAddress";
export const LABEL_CONFIRM_EMAIL_ADDRESS_VF = "Label.ConfirmEmailAddressVF";
export const LABEL_CHALLENGE_QUESTION = "Label.ChallengeQuestion";
export const LABEL_CHALLENGE_QUESTION_VF = "Label.ChallengeQuestionVF";
export const LABEL_SELECT_CHALLENGE_QUESTION = "Label.SelectChallengeQuestion";
export const LABEL_CHALLENGE_ANSWER = "Label.ChallengeAnswer";
export const LABEL_CHALLENGE_ANSWER_VF = "Label.ChallengeAnswerVF";
export const TEXT_CHALLENGE_ANSWER_HINT = "Text.ChallengeAnswerHint";
export const TEXT_CHALLENGE_ANSWER_HINT_VF = "Text.ChallengeAnswerHint_VF";
export const TEXT_SECURITY_QUESTION_ANSWER_HINT =
  "Text.SecurityQuestionAnswerHint";

// Buttons
export const BUTTON_SECURE_LOGIN = "Button.SecureLogin";
export const BUTTON_CONTINUE = "Button.Continue";
export const BUTTON_CANCEL = "Button.Cancel";
export const BUTTON_LOGIN = "Button.Login";
export const BUTTON_RECOVER = "Button.Recover";
export const BUTTON_VERIFY = "Button.Verify";
export const BUTTON_SUBMIT = "Button.Submit";
export const BUTTON_CREATE_ACCOUNT = "Button.CreateAccount";

// Errors
export const ERROR_INVALID_INFO = "Error.InvalidInfo";
export const ERROR_CAPTCHA_EXPIRED = "Error.CaptchaExpired";
export const ERROR_USERNAME_FORMAT = "Error.UsernameFormat";
export const ERROR_PASSWORD_FORMAT = "Error.PasswordFormat";
export const ERROR_PASSWORD_MATCH = "Error.PasswordMatch";
export const ERROR_EMAIL_FORMAT = "Error.EmailFormat";
export const ERROR_EMAIL_MATCH = "Error.EmailMatch";
export const ERROR_SSN_FORMAT = "Error.SSNFormat";
export const ERROR_ZIP_FORMAT = "Error.ZipFormat";
export const ERROR_POSTAL_FORMAT = "Error.PostalFormat";
export const ERROR_INVALID_VIN = "Error.InvalidVin";
export const ERROR_INVALID_ACCNUMBER = "Error.InvalidAccountNumber";
export const ERROR_INVALID_DOB = "Error.InvalidDob";

export const ERROR_API_INVALID_USERNAME = "Error.API.InvalidUsername";
export const ERROR_API_INVALID_PASSWORD = "Error.API.InvalidPassword";
export const ERROR_API_INVALID_SSN = "Error.API.InvalidSSN";
export const ERROR_API_SERVICE_DOWN = "Error.API.ServiceDown";
export const ERROR_API_NO_EMAIL_ADRESS_AUDI = "Error.API.NoEmailAdressAudi";
export const ERROR_API_NO_EMAIL_ADRESS_VW = "Error.API.NoEmailAdressVW";

export const ERROR_EMPTY_USERNAME = "Error.EmptyUsername";
export const ERROR_EMPTY_PASSWORD = "Error.EmptyPassword";
export const ERROR_EMPTY_ACCOUNT_NUMBER = "Error.EmptyAccountNumber";
export const ERROR_EMPTY_CONFIRM_PSWD = "Error.EmptyConfirmPswd";
export const ERROR_EMPTY_SSN = "Error.EmptySSN";
export const ERROR_EMPTY_ZIP_CODE = "Error.EmptyZipCode";
export const ERROR_EMPTY_POSTAL_CODE = "Error.EmptyPostalCode";
export const ERROR_EMPTY_EMAIL = "Error.EmptyEmail";
export const ERROR_EMPTY_CHALLENGE_QUESTION = "Error.EmptyChallengeQuestion";
export const ERROR_EMPTY_CHALLENGE_ANSWER = "Error.EmptyChallengeAnswer";
export const ERROR_CHECK_ACCEPT_AGREEMENT = "Error.CheckAcceptAgreement";
export const ERROR_INVALID_MFA_CODE = "Error.InvalidMFACode";
export const ERROR_EMPTY_DOB = "Error.EmptyDob";
export const ERROR_EMPTY_VIN = "Error.EmptyVin";

// LOCKOUT
export const TITLE_API_DOWN = "Title.ApiDown";
export const TEXT_API_DOWN = "Text.ApiDown";
export const TITLE_LOCKOUT_WARNING1 = "Title.LockoutWarning1";
export const TITLE_LOCKOUT_WARNING2 = "Title.LockoutWarning2";
export const TITLE_LOCKOUT_WARNING3 = "Title.LockoutWarning3";
export const TITLE_LOCKOUT_ERROR = "Title.LockoutError";
export const TEXT_LOCKOUT_WARNING = "Text.LockoutWarning";
export const TEXT_LOCKOUT_MFA_WARNING = "Text.LockoutMFAWarning";
export const TEXT_LOCKOUT_WARNING_VERIFY = "Text.LockoutWarningVerify";
export const TEXT_LOCKOUT_ERROR = "Text.LockoutError";
export const TEXT_LOCKOUT_ERROR_MFA = "Text.LockoutErrorMFA";
export const TEXT_LOCKOUT_ERROR_AUDI = "Text.LockoutErrorAudi";

// Footer
export const FOOTER_PRIVACY_POLICY = "Footer.PrivacyPolicy";
export const FOOTER_DEALER_LOCATOR = "Footer.DealerLocator";
export const FOOTER_CONTACT_US = "Footer.ContactUs";
export const FOOTER_PRIVACY_POLICY_CAN_LINK = "Footer.PrivacyPolicyCanada.link";
export const FOOTER_DEALER_LOCATOR_CAN_LINK = "Footer.DealerLocatorCanada.link";
export const FOOTER_CONTACT_US_CAN_LINK = "Footer.ContactUsCanada.link";
export const FOOTER_VW_INC_2021 = "Footer.VWInc2021";
export const FOOTER_VW_INC = "Footer.VWInc";
export const FOOTER_DNS_INFORMATION = "Footer.DoNotSellMyInformation";
export const FOOTER_CREDIT_LEASING = "Footer.CreditLeasing";
export const FOOTER_LOAN_SERVICES = "Footer.LoanServices";
export const FOOTER_CONSUMER_ACCESS = "Footer.ConsumerAccess";
export const FOOTER_NMLS_3024 = "Footer.Nmls3024";
export const FOOTER_NMLS_121740 = "Footer.Nmls121740";
export const FOOTER_NMLS_121742 = "Footer.Nmls121742";
export const FOOTER_ENGLISH_LANGUAGE = "Footer.English.Language";
export const FOOTER_FRANCAIS_LANGUAGE = "Footer.Francais.Language";
export const FOOTER_AUDI_FINANCE = "Footer.Audi.Finance";
export const FOOTER_VW_CANADA = "Footer.VW.Canada";
export const FOOTER_EN = "Footer.EN";
export const FOOTER_FR = "Footer.FR";
export const FOOTER_LINK_IL_CERTIFICATE = "Footer.Link.IL.Certificate";

// MFA
export const TITLE_MFA_ENTER_CODE = "Title.MfaEnterCode";
export const TEXT_SCREEN_TWO_TEXT_ONE = "Text.ScreenTowTextOne";
export const TEXT_SCREEN_TWO_TEXT_TWO = "Text.ScreenTowTextTwo";
export const TEXT_SCREEN_TWO_TEXT_THREE = "Text.ScreenTowTextThree";
export const TEXT_DIDNT_RECV_CODE = "Text.DidntReceiveCode";
export const LINK_SEND_NEW_CODE = "Link.SendNewCode";
export const LINK_ADD_YOUR_ACCOUNT = "Link.AddYourAccount";

export const REMEMBER_DEVICE = "Label.RememberDevice";
export const AGREE_REMEMBER_DEVICE = "Text.AgreeRememberDevice";
export const NO_REMEMBER_DEVICE = "Text.NoRememberDevice";

// Flows
export const LOGIN_FLOW = "Flow.LoginFlow";
export const UPDATE_EMAIL_FLOW = "Flow.UpdateEmailFlow";
export const ACCOUNT_VERIFICATION_FLOW = "Flow.AccountVerificationFlow";

export const CHAR_DOT = ".";
export const TEXT_MAINTENANCE_MODE = "Text.MaintenanceMode";

// Terms and conditions
export const TITLE_TERMS = "Title.Terms";
export const TITLE_TERMS_LAWS = "Title.Terms.Laws";
export const TITLE_TERMS_ACCESS = "Title.Terms.Access";
export const TITLE_TERMS_PRIVACY = "Title.Terms.Privacy";
export const TITLE_TERMS_SERVICES = "Title.Terms.Services";
export const TITLE_TERMS_RESOLUTION = "Title.Terms.Resolution";
export const SUBTITLE_TERMS_PROTECT = "Subtitle.Terms.Protect";
export const SUBTITLE_TERMS_INFORMATION = "Subtitle.Terms.Information";
export const SUBTITLE_TERMS_CONFIDENTIALITY = "Subtitle.Terms.Confidentiality";
export const SUBTITLE_TERMS_COMMITMENT = "Subtitle.Terms.Commitment";
export const SUBTITLE_TERMS_CHILDREN = "Subtitle.Terms.Children";
export const TEXT_TERMS_HREF_URL = "Text.Terms.Href.Url";
export const TEXT_TERMS_HREF_TITLE = "Text.Terms.Href.Title";
export const TEXT_TERMS_1_1 = "Text.Terms_1_1";
export const TEXT_TERMS_1_2 = "Text.Terms_1_2";
export const TEXT_TERMS_1_3 = "Text.Terms_1_3";
export const TEXT_TERMS_2 = "Text.Terms_2";
export const TEXT_TERMS_3_1 = "Text.Terms_3_1";
export const TEXT_TERMS_3_2 = "Text.Terms_3_2";
export const TEXT_TERMS_FRENCH = "Text.Terms_French";
export const TEXT_TERMS_4 = "Text.Terms_4";
export const TEXT_TERMS_5 = "Text.Terms_5";
export const TEXT_TERMS_6 = "Text.Terms_6";
export const TEXT_TERMS_7 = "Text.Terms_7";
export const TEXT_TERMS_8 = "Text.Terms_8";
export const TEXT_TERMS_9 = "Text.Terms_9";
export const TEXT_TERMS_10 = "Text.Terms_10";
export const TEXT_TERMS_11 = "Text.Terms_11";
export const TEXT_TERMS_12 = "Text.Terms_12";
export const TEXT_TERMS_13 = "Text.Terms_13";
export const TEXT_TERMS_14 = "Text.Terms_14";
export const TEXT_TERMS_15 = "Text.Terms_15";
export const TEXT_TERMS_16 = "Text.Terms_16";
export const TEXT_TERMS_17 = "Text.Terms_17";
export const LIST_TERMS_1 = "List.Terms_1";
export const LIST_TERMS_2 = "List.Terms_2";
export const LIST_TERMS_3 = "List.Terms_3";
export const LIST_TERMS_4 = "List.Terms_4";
export const LIST_TERMS_5 = "List.Terms_5";

// Terms and conditions VF
export const TITLE_TERMS_VF = "Title.Terms_VF";
export const TITLE_TERMS_LAWS_VF = "Title.Terms.Laws_VF";
export const TITLE_TERMS_ACCESS_VF = "Title.Terms.Access_VF";
export const TITLE_TERMS_PRIVACY_VF = "Title.Terms.Privacy_VF";
export const TITLE_TERMS_SERVICES_VF = "Title.Terms.Services_VF";
export const TITLE_TERMS_RESOLUTION_VF = "Title.Terms.Resolution_VF";
export const SUBTITLE_TERMS_PROTECT_VF = "Subtitle.Terms.Protect_VF";
export const SUBTITLE_TERMS_INFORMATION_VF = "Subtitle.Terms.Information_VF";
export const SUBTITLE_TERMS_CONFIDENTIALITY_VF =
  "Subtitle.Terms.Confidentiality_VF";
export const SUBTITLE_TERMS_COMMITMENT_VF = "Subtitle.Terms.Commitment_VF";
export const SUBTITLE_TERMS_CHILDREN_VF = "Subtitle.Terms.Children_VF";
export const TEXT_TERMS_1_VF = "Text.Terms_1_VF";
export const TEXT_TERMS_2_VF = "Text.Terms_2_VF";
export const TEXT_TERMS_3_VF = "Text.Terms_3_VF";
export const TEXT_TERMS_FRENCH_VF = "Text.Terms_French_VF";
export const TEXT_TERMS_4_VF = "Text.Terms_4_VF";
export const TEXT_TERMS_5_VF = "Text.Terms_5_VF";
export const TEXT_TERMS_6_VF = "Text.Terms_6_VF";
export const TEXT_TERMS_7_VF = "Text.Terms_7_VF";
export const TEXT_TERMS_8_VF = "Text.Terms_8_VF";
export const TEXT_TERMS_9_VF = "Text.Terms_9_VF";
export const TEXT_TERMS_10_VF = "Text.Terms_10_VF";
export const TEXT_TERMS_11_VF = "Text.Terms_11_VF";
export const TEXT_TERMS_12_VF = "Text.Terms_12_VF";
export const TEXT_TERMS_13_VF = "Text.Terms_13_VF";
export const TEXT_TERMS_14_VF = "Text.Terms_14_VF";
export const TEXT_TERMS_15_VF = "Text.Terms_15_VF";
export const TEXT_TERMS_16_VF = "Text.Terms_16_VF";
export const TEXT_TERMS_17_VF = "Text.Terms_17_VF";
export const LIST_TERMS_1_VF = "List.Terms_1_VF";
export const LIST_TERMS_2_VF = "List.Terms_2_VF";
export const LIST_TERMS_3_VF = "List.Terms_3_VF";
export const LIST_TERMS_4_VF = "List.Terms_4_VF";
export const LIST_TERMS_5_VF = "List.Terms_5_VF";

// SECURITY QUESTION OPTIONS FOR VW
export const TEXT_SECURITY_QUESTION_OPTION1 = "Text.SecurityQuestion.Option1";
export const TEXT_SECURITY_QUESTION_OPTION2 = "Text.SecurityQuestion.Option2";
export const TEXT_SECURITY_QUESTION_OPTION3 = "Text.SecurityQuestion.Option3";
export const TEXT_SECURITY_QUESTION_OPTION4 = "Text.SecurityQuestion.Option4";
export const TEXT_SECURITY_QUESTION_OPTION5 = "Text.SecurityQuestion.Option5";
export const TEXT_SECURITY_QUESTION_OPTION6 = "Text.SecurityQuestion.Option6";
export const TEXT_SECURITY_QUESTION_OPTION7 = "Text.SecurityQuestion.Option7";
export const TEXT_SECURITY_QUESTION_OPTION8 = "Text.SecurityQuestion.Option8";
export const TEXT_SECURITY_QUESTION_OPTION9 = "Text.SecurityQuestion.Option9";

// SECURITY QUESTION OPTIONS FOR AUDI
export const TEXT_SECURITY_QUESTION_OPTION1_AUDI =
  "Text.SecurityQuestion.Option1.Audi";
export const TEXT_SECURITY_QUESTION_OPTION2_AUDI =
  "Text.SecurityQuestion.Option2.Audi";
export const TEXT_SECURITY_QUESTION_OPTION3_AUDI =
  "Text.SecurityQuestion.Option3.Audi";
export const TEXT_SECURITY_QUESTION_OPTION4_AUDI =
  "Text.SecurityQuestion.Option4.Audi";
export const TEXT_SECURITY_QUESTION_OPTION5_AUDI =
  "Text.SecurityQuestion.Option5.Audi";
export const TEXT_SECURITY_QUESTION_OPTION6_AUDI =
  "Text.SecurityQuestion.Option6.Audi";
export const TEXT_SECURITY_QUESTION_OPTION7_AUDI =
  "Text.SecurityQuestion.Option7.Audi";
export const TEXT_SECURITY_QUESTION_OPTION8_AUDI =
  "Text.SecurityQuestion.Option8.Audi";
export const TEXT_SECURITY_QUESTION_OPTION9_AUDI =
  "Text.SecurityQuestion.Option9.Audi";

// VF Landing Page
export const TITLE_LOGO_VF_1 = "Title.Logo.VF_1";
export const TITLE_LOGO_VF_2 = "Title.Logo.VF_2";
export const LOGO_VFS_1 = "Title.Logo.VFS_1";
export const LOGO_VFS_2 = "Title.Logo.VFS_2";
export const TITLE_HEADER_VF_1 = "Title.Header.VF_1";
export const TITLE_HEADER_VF_2 = "Title.Header.VF_2";
export const TEXT_BODY_VF_1 = "Text.Body.VF_1";
export const TEXT_BODY_VF_2 = "Text.Body.VF_2";
export const TEXT_LIST_VF_1 = "Text.List.VF_1";
export const TEXT_LIST_VF_2 = "Text.List.VF_2";
export const TEXT_LIST_VF_3 = "Text.List.VF_3";
export const TEXT_LIST_VF_4 = "Text.List.VF_4";
export const TEXT_BODY_VF_3 = "Text.Body.VF_3";
export const TEXT_BUTTON_VF = "Text.Button.VF";
export const TEXT_FOOTER_VF = "Text.Footer.VF";

// AFS Landing Page
export const TITLE_LOGO_AFS_1 = "Title.Logo.AFS_1";
export const TITLE_LOGO_AFS_2 = "Title.Logo.AFS_2";
export const TITLE_HEADER_AFS_1 = "Title.Header.AFS_1";
export const TITLE_HEADER_AFS_2 = "Title.Header.AFS_2";
export const TEXT_BODY_AFS_1 = "Text.Body.AFS_1";
export const TEXT_BODY_AFS_2 = "Text.Body.AFS_2";
export const TEXT_LIST_AFS_1 = "Text.List.AFS_1";
export const TEXT_LIST_AFS_2 = "Text.List.AFS_2";
export const TEXT_LIST_AFS_3 = "Text.List.AFS_3";
export const TEXT_LIST_AFS_4 = "Text.List.AFS_4";
export const TEXT_BODY_AFS_3 = "Text.Body.AFS_3";
export const TEXT_BUTTON_AFS = "Text.Button.AFS";
export const TEXT_FOOTER_AFS_1 = "Text.Footer.AFS_1";
export const TEXT_FOOTER_AFS_2 = "Text.Footer.AFS_2";
export const TEXT_FOOTER_AFS_3 = "Text.Footer.AFS_3";
export const TEXT_FOOTER_AFS_4 = "Text.Footer.AFS_4";
export const TEXT_FOOTER_AFS_5 = "Text.Footer.AFS_5";
export const TEXT_FOOTER_AFS_6 = "Text.Footer.AFS_6";
export const TEXT_FOOTER_AFS_7 = "Text.Footer.AFS_7";

// CDK outage
export const TITLE_CDK_OUTAGE_VW = "Title.CDK.Outage.VW";
export const TEXT_CDK_OUTAGE_VW = "Text.CDK.Outage.VW";
export const LINK_CDK_OUTAGE_VW = "Link.CDK.Outage.VW";
