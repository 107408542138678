/* eslint-disable */
import React, { useContext, useEffect, useRef } from "react";
import { SessionContext } from "../../../../context/sessionContext";

interface BannerNotificationProps {
  notificationStyle: string;
  children?: Element;
  title?: string;
  titleClassName?: string;
  onClose?: () => void;
  showClose?: boolean;
}

const BannerNotification: React.FC<BannerNotificationProps> = (
  props
): JSX.Element | null => {
  const {
    notificationStyle,
    children,
    title,
    onClose,
    showClose,
    titleClassName,
  } = props;
  const targetRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    targetRef.current && targetRef.current.focus();
  }, []);
  const { sessionDetails } = useContext(SessionContext);
  const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";

  return (
    <section
      id="js-notifications-group"
      className="c-notifications-group js-panelgroup"
    >
      <div className="c-notification__container  js-notification-container">
        <div
          className={`c-notification c-notification--${notificationStyle} ariaNoBorder ${isAudi && "u-pt-none"}`}
          tabIndex={-1}
          ref={targetRef}
        >
          <div className="c-notification__outer-wrap">
            <div className="c-notification__inner-wrap">
              <i
                className="c-notification__status-icon"
                aria-hidden="true"
                role="img"
              />
              <div
                className={`c-notification__content ${isAudi && !title && "u-pl-large"}`}
              >
                 {title && (
                  <p className={`c-notification__title ${notificationStyle !== "custom" && "u-mb-none"} ${isAudi && "u-mt"} ${titleClassName}`}>
                    {title}
                  </p>
                )}
                <p
                  className={`c-notification__text ${isAudi && !title && "u-mt"} ${isAudi && title && "u-mt-small"}`}
                >
                  {children}
                </p>
              </div>
              {showClose && (
                <button
                  className="js-notification-close c-notification__close"
                  aria-label="Close"
                  type="submit"
                  onClick={onClose}
                  data-testid="notification-close"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
           ${notificationStyle === "custom" &&
           `
           .c-notification__title { 
             font-size: 20px !important;
             margin-bottom: 12px !important;
           }
           `}
           ${notificationStyle !== "custom" && ".c-notification__title,"}
          .c-notification__text {
            font-size: 14px !important;
          }
          .c-notification__title: {
            font-weight: "bold";
          }
          .c-notifications-group {
            visibility: visible !important;
          }
          .c-notification__inner-wrap {
            padding-right: 0px;
            ${isAudi && "display: inline;"}
          }
          .ariaNoBorder {
            outline-style: none;
            box-shadow: none;
          }
          .c-notification--custom {
            background-color: #3c484d;
          }
        `}
      </style>
    </section>
  );
};

export default BannerNotification;
