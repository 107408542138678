/* eslint-disable import/prefer-default-export */
import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  partyId: number;
  sessionDetails: SessionDetails;
}

const gqlRequest = gql`
  mutation CustomerMFAGenerate($Request: GenerateCustomerMFARequest!) {
    generateCustomerMFA(Request: $Request) {
      IsSuccess
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

export const generateMFA = async (payload: Payload) => {
  const { partyId, sessionDetails } = payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const language =
      sessionDetails.language === "en-US" || sessionDetails.language === "en-CA"
        ? "English"
        : "French";
    const request = {
      PartyId: partyId,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      Language: language,
      SourceApp: sessionDetails.source,
      Country: sessionDetails.country,
      ParentFunctionName: "Generate MFA",
      ProcessName: "Generate MFA",
      WidgetName: sessionDetails.source,
    };
    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (response?.data?.data?.generateCustomerMFA?.IsSuccess) {
      return {
        data: {
          isSuccess: true,
        },
      };
    }
    if (
      !response?.data?.data?.generateCustomerMFA?.IsSuccess ||
      response?.data?.data === null
    ) {
      return {
        data: {
          isSuccess: false,
          errors:
            response?.data?.data?.generateCustomerMFA?.Errors?.[0] ||
            response?.data?.errors,
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { error: error.message || error.toString() };
  }
};
