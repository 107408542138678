// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hxXCM_waekOzbHC5MLtx input{font-size:16px;font-family:"AudiTypeScreen",Verdana,sans-serif}.JVWuPUhrJ3946lX1hW4s{font-family:"AudiType-Bold",Verdana,sans-serif;font-size:14px}.y0zbmLF1kK0Gzqp5VauW{font-family:"AudiTypeScreen",Verdana,sans-serif;font-size:16px !important}`, "",{"version":3,"sources":["webpack://./src/components/retrieveUsername/verifyChallenge.module.scss","webpack://./styles/_audi-variables.scss"],"names":[],"mappings":"AAGE,4BACE,cAAA,CACA,+CCJ2B,CDQ/B,sBACE,8CCR2B,CDS3B,cAAA,CAGF,sBACE,+CCd6B,CDe7B,yBAAA","sourcesContent":["@import \"../../../styles/audi-variables\";\n\n.form-field-custom {\n  input {\n    font-size: 16px;\n    font-family: $font-family-audi-type-normal;\n  }\n}\n\n.all-fields-required-audi {\n  font-family: $font-family-audi-type-bold;\n  font-size: 14px;\n}\n\n.info-text-audi {\n  font-family: $font-family-audi-type-normal;\n  font-size: 16px !important;\n}","$font-family-audi-type-extended-normal: 'AudiType-ExtendedNormal', Verdana, sans-serif;\n$font-family-audi-type-normal: 'AudiTypeScreen', Verdana, sans-serif;\n$font-family-audi-type-bold: 'AudiType-Bold', Verdana, sans-serif;\n$font-family-audi-type-extended-bold: 'AudiType-ExtendedBold', Verdana, sans-serif;\n$font-family-audi-type-var-normal: 'AudiTypeVar-Normal', Verdana, sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-field-custom": `hxXCM_waekOzbHC5MLtx`,
	"all-fields-required-audi": `JVWuPUhrJ3946lX1hW4s`,
	"info-text-audi": `y0zbmLF1kK0Gzqp5VauW`
};
export default ___CSS_LOADER_EXPORT___;
