/* eslint-disable */
import React, { createContext, useState, useEffect, useContext } from "react";
import PropTypes, { element } from "prop-types";
import * as validate from "@ownportal/customer-validations";
import { v4 as uuidv4 } from "uuid";
import i18n from "../localization/i18n";

const guid = uuidv4();

interface SessionContextType {
  sessionDetails: {
    correlationId: string;
    brand: string;
    country: string;
    source: string;
    language: string;
  };
  setSessionDetails: (details: any) => void;
  setUserSessionDetails: (details: any) => void;
}

export const SessionContext = createContext<SessionContextType | null>(null);
export const SessionContextProvider = ({ appProps, children }) => {
  const [sessionDetails, setSessionDetails] = useState({
    correlationId: guid,
    brand: validate.ldapEscapeDN`${appProps.brand}`,
    country: validate.ldapEscapeDN`${appProps.country}`,
    source: validate.ldapEscapeDN`${appProps.source}`,
    language: appProps.language,
  });

  const detectBrowserlanguage = () => {
    const navLang = navigator.language || (navigator as any).userLanguage;
    if (navLang === "fr" || navLang === "fr-CA" || navLang === "fr-ca") {
      return "fr-CA";
    }
    return "en-CA";
  };

  const traceOrigin = () => {
    return window.origin;
  };

  useEffect(() => {
    const origin = traceOrigin();

    let country = origin.toLowerCase().includes(".ca") ? "Canada" : "USA";
    let brand = origin.toLowerCase().includes("audi") ? "Audi" : "VW";

    if (origin.toLowerCase().includes("localhost")) {
      country = process.env.REACT_APP_COUNTRY;
      brand = process.env.REACT_APP_BRAND;
    }

    let lang;
    if (country?.toUpperCase() === "USA") {
      lang = "en-US";
      i18n.changeLanguage(lang.replace("-", ""));
    } else {
      lang = sessionStorage.getItem("lang") || detectBrowserlanguage();
      i18n.changeLanguage(lang.replace("-", ""));
    }

    let source;

    if (country === "Canada") {
      if (brand === "Audi") {
        source = "AF Portal";
      } else {
        source = "VF Portal";
      }
    } else if (brand === "Audi") {
      source = "myAudi";
    } else {
      source = "VWC Portal";
    }

    setSessionDetails({
      ...sessionDetails,
      country,
      brand,
      source,
      language: lang,
    });
  }, []);

  const setUserSessionDetails = (value) => {
    setSessionDetails({ ...sessionDetails, ...value });
  };
  return (
    <SessionContext.Provider
      value={{
        sessionDetails,
        setUserSessionDetails,
        setSessionDetails,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

SessionContextProvider.propTypes = {
  appProps: PropTypes.shape({
    brand: PropTypes.string,
    country: PropTypes.string,
    source: PropTypes.string,
    language: PropTypes.string,
    setSessionDetails: PropTypes.func,
  }),
  children: element.isRequired,
};
SessionContextProvider.defaultProps = {
  appProps: {
    brand: "",
    country: "",
    source: "",
    language: "",
  },
};
