import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  useContext,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../../../localization/i18n";
import * as rk from "../../../localization/resourceKeys";
import Checkbox from "../common/checkbox/checkbox";
import Logger from "../../../logger";
import BannerNotification from "../common/banner/bannerNotification";
import styles from "./consent.module.scss";
import { SessionContext } from "../../../context/sessionContext";
import TermsAndConditions from "./termsAndConditions";
import { AccountDetails } from "./types";
import register from "../../services/register";

const defaultAccountDetails: AccountDetails = {
  username: "",
  password: "",
  challengeQuestion: "",
  challengeAnswer: "",
  email: "",
  ssn: "",
  partyId: undefined,
  accountNumber: "",
};

interface ConsentProps {
  accountDetails: AccountDetails;
  setIsContinueEnabled: (enabled: boolean) => void;
  usernameConflict: (enabled: boolean) => void;
}

interface ConsentHandles {
  verifyUserAction: () => Promise<boolean>;
}

interface EventTargetWithChecked extends EventTarget {
  checked: boolean;
  id: string;
}

interface CustomEvent {
  target: EventTargetWithChecked;
}

const Consent = forwardRef<ConsentHandles, ConsentProps>(
  (
    {
      accountDetails = defaultAccountDetails,
      setIsContinueEnabled = () => {},
      usernameConflict = () => {},
    },
    ref,
  ) => {
    const navigate = useNavigate();
    const { sessionDetails } = useContext(SessionContext);
    const [formData, setFormData] = useState({
      agreeConsent: false,
      agreePaperless: false,
    });
    const [fieldError, setError] = useState("");
    const [registerError, setRegisterError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
    const isCanada = sessionDetails?.country?.toLowerCase() === "canada";
    const isVW = sessionDetails?.brand?.toUpperCase() === "VW";

    const OnlineAccessLink = (() => {
      return process.env.REACT_APP_AWS_ENV === "prod"
        ? `https://content.ownerportal.vwcredit.io/tnc/vw/OnlineAccess`
        : `https://content.${process.env.REACT_APP_AWS_ENV}.ownerportal.vwcredit.io/tnc/vw/OnlineAccess`;
    })();
    const ESIgnAccessLink = (() => {
      return process.env.REACT_APP_AWS_ENV === "prod"
        ? `https://vcidashboard.ownerportal.vwcredit.io/assets/pdfs/eSignDisclosureandConsent-Paperless-VW.pdf`
        : `https://vcidashboard.${process.env.REACT_APP_AWS_ENV}.ownerportal.vwcredit.io/assets/pdfs/eSignDisclosureandConsent-Paperless-VW.pdf`;
    })();

    useEffect(() => {
      window.addEventListener(
        "popstate",
        (event) => {
          if (event.state) {
            Logger.info(
              "Exited Registration Flow in Step 4 by clicking back button on the browser tab",
              "Location ==> /register",
              sessionDetails,
            );
          }
        },
        false,
      );
    }, [sessionDetails]);
    useEffect(() => {
      window.addEventListener("beforeunload", () => {
        Logger.info(
          "Exited Registration Flow in Step 4 by closing the browser tab",
          "Location ==> /register",
          sessionDetails,
        );
      });
    }, [sessionDetails]);

    useEffect(() => {
      setIsContinueEnabled(formData.agreeConsent);
    }, [formData.agreeConsent, setIsContinueEnabled]);

    async function onSubmit() {
      usernameConflict(false);
      // paperless enroll should changeable only when is VW-USA, otherwise it will be false
      const paperlessEnroll =
        isVW && !isCanada ? formData.agreePaperless : false;
      const body = {
        ...accountDetails,
        sessionDetails,
        paperlessEnroll,
      };
      const { data, error } = await register(body);
      if (data?.inMaintenance) {
        navigate(`/login`);
      }
      if (data?.isSuccess) {
        Logger.info(
          "Successful User Registration",
          "Location ==> /register",
          sessionDetails,
          accountDetails?.accountNumber,
          accountDetails?.partyId,
          accountDetails?.username,
        );
        return true;
      }
      if (data?.errors) {
        if (data?.errors?.ErrorCode === "conflict_Credsname") {
          Logger.info(
            "Username Conflict",
            "Location ==> /register",
            sessionDetails,
            accountDetails?.accountNumber,
            accountDetails?.partyId,
            accountDetails?.username,
          );
          usernameConflict(true);
          return false;
        }
      }
      Logger.error(
        "RegisterCustomer API Error",
        "Location ==> /register",
        data?.errors || error || data,
        sessionDetails,
        accountDetails?.accountNumber,
        accountDetails?.partyId,
        accountDetails?.username,
      );
      setRegisterError(true);
      setErrorMsg(i18n.t(rk.ERROR_API_SERVICE_DOWN));
      return false;
    }

    async function verifyUserAction(): Promise<boolean> {
      if (formData.agreeConsent) {
        setError("");
        setRegisterError(false);
        const isSuccess = await onSubmit();
        return isSuccess;
      }
      setError(i18n.t(rk.ERROR_CHECK_ACCEPT_AGREEMENT));
      return false;
    }

    useImperativeHandle(ref, () => ({ verifyUserAction }));

    const onChange = async (e: CustomEvent) => {
      setFormData((payload) => ({
        ...payload,
        [e.target.id]: e.target.checked,
      }));

      if (e.target.id !== "agreeConsent") return;

      if (e.target.checked) {
        setError("");
        setIsContinueEnabled(true);
        Logger.info(
          "Checked Consent",
          "Location ==> /register",
          sessionDetails,
        );
      } else {
        setIsContinueEnabled(false);
      }
    };

    const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(true);
    // eslint-disable-next-line
    const handleScroll = (event: any) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      if (scrollHeight - scrollTop - 10 < clientHeight) {
        setIsCheckboxDisabled(false);
      }
    };
    return (
      <div>
        {registerError && (
          <div className={styles.bannerNotification}>
            <BannerNotification notificationStyle="error">
              {i18n.t(errorMsg)}
            </BannerNotification>
          </div>
        )}
        {isCanada ? (
          <>
            {isVW && (
              <p className={styles["sub-heading-vf"]}>
                {i18n.t(rk.TITLE_AF_CONSENT)}
              </p>
            )}
            {isVW && (
              <p className={`${styles["terms-text-vf"]}`}>
                {i18n.t(rk.TEXT_VF_CONSENT_1)}
              </p>
            )}
            {isAudi && (
              <h3 className={`${styles["terms-title"]}`}>
                {i18n.t(rk.TITLE_AF_CONSENT)}
              </h3>
            )}
            {isAudi && (
              <p className={`${styles["terms-text"]}`}>
                {i18n.t(rk.TEXT_AF_CONSENT_1)}
              </p>
            )}
            {isAudi && (
              <p className={`${isAudi && styles["terms-text"]}`}>
                {i18n.t(rk.TEXT_AF_CONSENT_2)}
              </p>
            )}
            <div
              className={`${styles["terms-container"]} u-border u-p-small`}
              onScroll={handleScroll}
            >
              <TermsAndConditions />
            </div>
            <div className="u-mv-large">
              <Checkbox
                label={i18n.t(rk.TEXT_CHECKBOX_AF_CONSENT)}
                id="agreeConsent"
                onChange={onChange}
                checked={formData.agreeConsent}
                testid="test-consent-checkbox"
                errorMessage={fieldError}
                disabled={isCheckboxDisabled}
              />
            </div>
          </>
        ) : (
          <>
            <p className={styles["consent-paragraph"]}>
              {i18n.t(rk.TEXT_CONSENT_DESCRIPTION_TEXT)}
            </p>
            <p className={styles["sub-heading"]}>
              {accountDetails.email ?? ""}
            </p>
            <div className={styles["form-fields"]}>
              <Checkbox
                label={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: i18n.t(rk.TEXT_CONSENT_AGREE, {
                        consentLink: `<a class='e-sign-link' href="${ESIgnAccessLink}" target='_blank'>${i18n.t(rk.LINK_ESIGN_CONSENT_AGREE)}</a>`,
                        termsLink: `<a class='terms-link' href="${OnlineAccessLink}" target='_blank'>${i18n.t(rk.LINK_TERMS_CONSENT_AGREE)}</a>`,
                      }),
                    }}
                  />
                }
                id="agreeConsent"
                onChange={onChange}
                checked={formData.agreeConsent}
                testid="test-consent-checkbox"
                errorMessage={fieldError}
              />
              <Checkbox
                label={i18n.t(rk.TEXT_CONSENT_PAPERLESS_AGREE)}
                id="agreePaperless"
                onChange={onChange}
                checked={formData.agreePaperless}
                testid="test-paperless-checkbox"
                errorMessage={fieldError}
              />
              <p className="c-form-field__hint">
                {i18n.t(rk.LABEL_HINT_CONSENT_FORM)}
              </p>
            </div>
          </>
        )}
      </div>
    );
  },
);

export default Consent;
