// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media(max-width: 480px){.J0hiwlDVcvkr09zgy1sZ ol{margin-left:15px}}.J0hiwlDVcvkr09zgy1sZ p{margin-bottom:0}.J0hiwlDVcvkr09zgy1sZ li{padding-left:8px;font-size:16px}.J0hiwlDVcvkr09zgy1sZ span{display:block}.J0hiwlDVcvkr09zgy1sZ span::before{content:attr(data-text);font-weight:700}.J0hiwlDVcvkr09zgy1sZ span:first-child{padding-top:15px}.J0hiwlDVcvkr09zgy1sZ svg{width:175px;height:175px;margin-top:22px;margin-bottom:22px}`, "",{"version":3,"sources":["webpack://./src/components/accountVerification/authenticatorAppSetupSteps.module.scss"],"names":[],"mappings":"AAEI,yBADF,yBAEI,gBAAA,CAAA,CAGJ,wBACE,eAAA,CAEF,yBACE,gBAAA,CACA,cAAA,CAEF,2BACE,aAAA,CACA,mCACE,uBAAA,CACA,eAAA,CAEF,uCACE,gBAAA,CAIJ,0BACE,WAAA,CACA,YAAA,CACA,eAAA,CACA,kBAAA","sourcesContent":[".authenticatorAppSetupSteps {\n  ol {\n    @media(max-width: 480px) {\n      margin-left: 15px;\n    }\n  }\n  p {\n    margin-bottom: 0;\n  }\n  li {\n    padding-left: 8px;\n    font-size: 16px;;\n  }\n  span {\n    display: block;\n    &::before {\n      content: attr(data-text);\n      font-weight: 700;      \n    }\n    &:first-child {\n      padding-top: 15px;\n    }\n  }\n\n  svg {\n    width: 175px;\n    height: 175px;\n    margin-top: 22px;\n    margin-bottom: 22px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"authenticatorAppSetupSteps": `J0hiwlDVcvkr09zgy1sZ`
};
export default ___CSS_LOADER_EXPORT___;
