import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../components/common/layout/layout";
import Logger from "../../logger";
import i18n from "../../localization/i18n";
import * as rk from "../../localization/resourceKeys";
import StepFlow from "../components/common/stepflow/stepFlow";
import LandingPage from "../components/register/landingPage";
import RegisteredUser from "../components/register/registeredUser";
import VerifyCustomer from "../components/register/verifyCustomer";
import CustomerRegister from "../components/register/registerCustomer";
import Loader from "../components/common/loader/loader";
import ConsentPage from "../components/register/consent";
import Maintenance from "../components/maintenance";
import { SessionContext } from "../../context/sessionContext";
import Login from "./login";
import AccountVerificationStepFlow from "../components/accountVerification/accountVerificationStepFlow";
import {
  RegisterProps,
  State,
  AccountInfo,
  AccRegInfo,
  AccountDetails,
} from "../components/register/types";

const Register: React.FC<RegisterProps> = ({
  currentStep = undefined,
  currentStepIsCancel = undefined,
  maintenance = {
    inMaintenanceMode: "",
  },
}) => {
  const { sessionDetails } = useContext(SessionContext);
  const navigate = useNavigate();
  const inMaintenanceMode = maintenance?.inMaintenanceMode;
  const [step, setStep] = useState("REGISTRATION_FLOW");
  const ref = useRef(null);
  const brand = sessionDetails?.brand?.toUpperCase();
  const country = sessionDetails?.country?.toUpperCase();
  const isVF = brand === "VW" && country === "CANADA";
  const [isContinueEnabled, setIsContinueEnabled] = useState<boolean>(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState<boolean>(false);
  const [userConflict, setUserConflict] = useState<boolean>(false);

  const initialState: State = {
    name: "AccountRegisterFlow",
    currentStep: isVF ? 2 : (currentStep ?? 1),
    previousStep: -1,
    currentStepIsCancel: currentStepIsCancel ?? true,
    maxSteps: 4,
    submitPending: false,
  };

  const [state, setState] = useState<State>(initialState);
  const [accountInfo, setAccountInfo] = useState<AccountInfo | undefined>(
    undefined,
  );
  const [accRegInfo, setAccRegInfo] = useState<AccRegInfo | undefined>(
    undefined,
  );
  const accountDetails: AccountDetails = {
    ...accountInfo,
    ...accRegInfo,
  };

  useEffect(() => {
    if (alreadyRegistered) {
      setAlreadyRegistered(false);
      setState({
        ...state,
        currentStep: 0,
        previousStep: -1,
      });
    }
  }, [alreadyRegistered, state]);

  useEffect(() => {
    if (userConflict) {
      setState({
        ...state,
        currentStep: 3,
        previousStep: 2,
        submitPending: false,
      });
    }
  }, [userConflict]);

  useEffect(() => {
    document.getElementsByClassName("page-content")[0]?.scrollTo(0, 0);
  }, [state.currentStep]);

  const renderImage = () => {
    if (brand === "AUDI" && country === "CANADA") {
      return "./images/audi_canada/registration.jpg";
    }
    if (brand === "VW" && country === "USA") {
      if (state.currentStep === 1 || state.currentStep === 0) {
        return "./images/vw_usa/registration_1.jpg";
      }
      if (state.currentStep === 2) {
        return "./images/vw_usa/registration_2.jpg";
      }
      if (state.currentStep === 3) {
        return "./images/vw_usa/registration_3.jpg";
      }
      if (state.currentStep === 4) {
        return "./images/vw_usa/registration_4.jpg";
      }
    }
    if (brand === "VW" && country === "CANADA") {
      return "./images/vw_canada/registration.jpeg";
    }
  };

  const getClassName = () => {
    let className = "";
    if (state.currentStep === 1) {
      className = "intro-container";
    } else if (state.currentStep === 4) {
      className = "consent-container";
    }
    return className;
  };

  const cancelAndReturnToLogin = (exitStep: number) => {
    Logger.info(
      `Exited Registration Flow in Step ${exitStep}`,
      "Location ==> /register",
      sessionDetails,
    );
    navigate("/login");
  };

  const continueToNextStep = (contStep: number) => {
    Logger.info(
      `Clicked Continue in Registration Flow Step ${contStep}`,
      "Location ==> /register",
      sessionDetails,
    );
  };

  const renderComponent = () => {
    if (step === i18n.t(rk.LOGIN_FLOW)) {
      return <Login userFingerprint={null} />;
    }
    if (step === i18n.t(rk.ACCOUNT_VERIFICATION_FLOW)) {
      return (
        <AccountVerificationStepFlow
          setStep={setStep}
          email={accountDetails?.email}
          partyId={accountDetails?.partyId}
        />
      );
    }
    return (
      <div className={getClassName()}>
        <Layout imgSource={renderImage()}>
          <StepFlow
            step={0}
            currentStep={state.currentStep}
            title={<h2>{i18n.t(rk.TITLE_REGISTERED_USER)}</h2>}
            previousLabel=""
            nextLabel=""
          >
            <RegisteredUser
              previousAction={() => {
                setState({
                  ...state,
                  currentStep: 2,
                  previousStep: 1,
                  submitPending: false,
                });
              }}
            />
          </StepFlow>
          <StepFlow
            step={1}
            currentStep={state.currentStep}
            title={<h2>{i18n.t(rk.TITLE_ACC_REGISTRATION)}</h2>}
            previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
            previousAction={() => {
              setState({
                ...state,
                currentStep: state.currentStep + 1,
                previousStep: 1,
              });
              continueToNextStep(1);
            }}
            nextLabel={i18n.t(rk.BUTTON_CANCEL)}
            nextAction={() => {
              cancelAndReturnToLogin(1);
            }}
            previousLabelDisabled={false}
          >
            <LandingPage />
          </StepFlow>
          <StepFlow
            step={2}
            currentStep={state.currentStep}
            title={<h2>{isVF ? "" : i18n.t(rk.TITLE_ACC_REGISTRATION)}</h2>}
            previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
            previousAction={() => {
              setState({
                ...state,
                currentStep: state.currentStep + 1,
                previousStep: 2,
              });
              continueToNextStep(2);
            }}
            nextLabel={i18n.t(rk.BUTTON_CANCEL)}
            nextAction={() => {
              cancelAndReturnToLogin(2);
            }}
            canTakePreviousAction={async () => {
              setState({ ...state, submitPending: true });
              const canTakeAction = await ref.current.verifyUserAction();
              setState({ ...state, submitPending: false });
              return canTakeAction;
            }}
            previousLabelDisabled={!isContinueEnabled}
          >
            <Loader isLoading={state.submitPending} />
            <VerifyCustomer
              ref={ref}
              setAlreadyRegistered={setAlreadyRegistered}
              setAccountInfo={(value: AccountInfo) => setAccountInfo(value)}
              setIsContinueEnabled={(value: boolean) =>
                setIsContinueEnabled(value)
              }
            />
          </StepFlow>
          <StepFlow
            step={3}
            currentStep={state.currentStep}
            title={<h2>{isVF ? "" : i18n.t(rk.TITLE_ACC_REGISTRATION)}</h2>}
            previousLabel={i18n.t(rk.BUTTON_CONTINUE)}
            previousAction={() => {
              setState({
                ...state,
                currentStep: state.currentStep + 1,
                previousStep: 3,
              });
              continueToNextStep(3);
            }}
            nextLabel={i18n.t(rk.BUTTON_CANCEL)}
            nextAction={() => {
              cancelAndReturnToLogin(3);
            }}
            canTakePreviousAction={async () => {
              setState({ ...state, submitPending: true });
              const canTakeAction = await ref.current.verifyUserAction();
              setState({ ...state, submitPending: false });

              return canTakeAction;
            }}
            previousLabelDisabled={!isContinueEnabled}
          >
            <Loader isLoading={state.submitPending} />
            <CustomerRegister
              ref={ref}
              setAccRegInfo={(value: AccRegInfo) => setAccRegInfo(value)}
              setIsContinueEnabled={(value: boolean) =>
                setIsContinueEnabled(value)
              }
              userConflict={userConflict}
            />
          </StepFlow>
          <StepFlow
            step={4}
            currentStep={state.currentStep}
            title={<h2>{isVF ? "" : i18n.t(rk.TITLE_ACC_REGISTRATION)}</h2>}
            previousLabel={
              isVF
                ? i18n.t(rk.BUTTON_CREATE_ACCOUNT)
                : i18n.t(rk.BUTTON_CONTINUE)
            }
            previousAction={() => {
              setStep(i18n.t(rk.ACCOUNT_VERIFICATION_FLOW));
            }}
            previousLabelDisabled={!isContinueEnabled}
            canTakePreviousAction={async () => {
              setState({ ...state, submitPending: true });
              const canTakeAction = await ref.current.verifyUserAction();
              setState({ ...state, submitPending: false });
              continueToNextStep(4);
              return canTakeAction;
            }}
            nextLabel={i18n.t(rk.BUTTON_CANCEL)}
            nextAction={() => {
              cancelAndReturnToLogin(4);
            }}
          >
            <Loader isLoading={state.submitPending} />
            <ConsentPage
              ref={ref}
              accountDetails={accountDetails}
              setIsContinueEnabled={setIsContinueEnabled}
              usernameConflict={setUserConflict}
            />
          </StepFlow>
        </Layout>
        <style>{`
          .bannerNotification{
            padding-bottom: ${brand === "AUDI" ? "35px" : "0px"};
          }
      `}</style>
      </div>
    );
  };

  return inMaintenanceMode === "Y" ? <Maintenance /> : renderComponent();
};

export async function getServerSideProps() {
  const host = process.env.VWC_URL;
  return fetch(`${host}/maintenance.json`)
    .then((response) => {
      return response ? response.json() : { inMaintenanceMode: "N" };
    })
    .then((res) => {
      const mode = res.inMaintenanceMode;
      const maintenance = { inMaintenanceMode: `${mode}` };
      return { props: { maintenance } };
    })
    .catch((err) => {
      Logger.error(
        "Registration Flow => getServerSideProps",
        `Location ==> /register`,
        `${err}`,
      );
      const maintenance = { inMaintenanceMode: "N" };
      return { props: { maintenance } };
    });
}

export default Register;
