/* eslint-disable */
import React, { useEffect, useContext } from "react";
import i18n from "../../../../localization/i18n";
import * as rk from "../../../../localization/resourceKeys";
import Logger from "../../../../logger";
import { SessionContext } from "../../../../context/sessionContext";
import { FINISH_UPDATE_EMAIL_FLOW_EVENT } from "../../../../constants/BusinessSplunkEvent";

const UpdateEmailSuccess = () => {
  const { sessionDetails } = useContext(SessionContext);
  const isVF =
    sessionDetails?.brand?.toLowerCase() === "vw" &&
    sessionDetails?.country?.toLowerCase() === "canada";

  useEffect(() => {
    window.addEventListener(
      "popstate",
      (event) => {
        if (event.state) {
          Logger.info(
            "Exited Update Email Flow in Step 2 by clicking back button on the browser tab",
            "Location ==> /register",
            sessionDetails
          );
        }
      },
      false
    );
  }, []);
  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    window.addEventListener("beforeunload", (e) => {
      Logger.info(
        "Exited Update Email Flow in Step 2 by closing the browser tab",
        "Location ==> /register",
        sessionDetails
      );
    });
    Logger.info(
      FINISH_UPDATE_EMAIL_FLOW_EVENT,
      "Location => updateEmail Screen",
      sessionDetails
    );
  }, []);
  return (
    <>
      {isVF ? (
        <p className="title-vf u-mb-xsmall">
          {i18n.t(rk.TITLE_UPDATE_EMAIL_SUCCESS)}
        </p>
      ) : (
        <h2 className="email-success-heading">
          {i18n.t(rk.TITLE_UPDATE_EMAIL_SUCCESS)}
        </h2>
      )}
      <p className="u-text-fs-1 email-confirmation-text">
        {i18n.t(rk.TEXT_UPDATE_EMAIL_SUCCESS)}
      </p>
      <style>{`
        .title-vf {
          font-size: 32px;
        }
        .email-success-heading {
          font-size:32px;
          margin-bottom: 15px !important;
        }
        .email-confirmation-text {
          font-size:16px !important;
          margin-bottom:2px !important;            
        }
      `}</style>
    </>
  );
};

export default UpdateEmailSuccess;
