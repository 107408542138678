import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishUSA from "./en-us.json";
import frenchCAN from "./fr-ca.json";
import englishCA from "./en-ca.json";

// the translations
const resourceContent = {
  enUS: {
    translation: englishUSA,
  },
  enCA: {
    translation: englishCA,
  },
  frCA: {
    translation: frenchCAN,
  },
};
i18n
  // passes i18n down to react-i18next
  .use(initReactI18next)
  .init(
    {
      interpolation: {
        // React already does escaping
        escapeValue: false,
      },
      // "en" | "es"
      lng: i18n.language,
      fallbackLng: "enUS",
      resources: resourceContent,
      keySeparator: ".",
    },
    () => {},
  );

export default i18n;
