/* eslint-disable import/prefer-default-export */
import axios from "axios";
import gql from "graphql-tag";
import awsconfig from "../../awsconfig";
import getMaintenanceMode from "../../utilities";

const graphql = require("graphql");

const { print } = graphql;

interface SessionDetails {
  correlationId: string;
  brand: string;
  country: string;
  source: string;
  language: string;
}

interface Payload {
  partyId: number;
  challengeQuestion: string;
  challengeAnswer: string;
  sessionDetails: SessionDetails;
}

const gqlRequest = gql`
  mutation ForgotUserName($Request: SubmitForgotUserNameRequest!) {
    submitForgotUserName(Request: $Request) {
      IsSuccess
      UserName
      Errors {
        ErrorCode
        ErrorMessage
        ErrorStack
      }
    }
  }
`;

export const retrieveUsername = async (payload: Payload) => {
  const { partyId, challengeQuestion, challengeAnswer, sessionDetails } =
    payload;
  try {
    const getMode = await getMaintenanceMode();
    if (getMode === true) {
      return {
        data: {
          inMaintenance: "Y",
        },
      };
    }
    const request = {
      PartyId: partyId,
      ChallengeQuestion: challengeQuestion,
      ChallengeAnswer: challengeAnswer,
      Country: sessionDetails.country,
      CorrelationId: sessionDetails.correlationId,
      Brand: sessionDetails.brand,
      Source: sessionDetails.source,
      SourceApp: sessionDetails.source,
      ParentFunctionName: "retrieve username",
      ProcessName: "Retrieve Username",
      WidgetName: sessionDetails.source,
    };

    const response = await axios({
      url: awsconfig.aws_appsync_graphqlEndpoint,
      method: "post",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      data: {
        query: print(gqlRequest),
        variables: {
          Request: request,
        },
      },
    });
    if (response?.data?.data?.submitForgotUserName?.IsSuccess) {
      return {
        data: {
          isSuccess: true,
          username: response?.data?.data?.submitForgotUserName?.UserName,
        },
      };
    }
    if (
      !response?.data?.data?.submitForgotUserName?.IsSuccess &&
      response?.data?.data?.submitForgotUserName?.UserName === null
    ) {
      return {
        data: {
          isSuccess: false,
          errors: {
            ErrorCode: "badRequest_NoMatch",
          },
        },
      };
    }
    if (response?.data?.data === null) {
      return {
        data: {
          isSuccess: false,
          errors: response?.data?.errors || "",
        },
      };
    }
    return {
      data: {
        isSuccess: false,
        error: response?.data?.errors || response?.data || response,
      },
    };
  } catch (error) {
    return { isSuccess: false, error: error.message || error.toString() };
  }
};
